import { observer } from 'mobx-react';
import { useEffect, useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import message from 'antd-message';
import _ from 'lodash';
import { useStores } from 'stores/Context';
import AdminLayout from 'components/templates/AdminLayout';
import PatientDetailTab from 'components/UI/organisms/patient/detail/PatientDetailTab';

const PatientDetailPage = () => {
  const { patientStore, treatmentStore, historyStore, clientStore, clinicStore } = useStores();
  const [isGlobalLoading, setIsGlobalLoading] = useState(false);
  const navigate = useNavigate();

  const init = useCallback(async () => {
    try {
      await setIsGlobalLoading(true);
      clinicStore.getClinic(clientStore.metaData?.userRole.clinicID!, false);
      await patientStore.getPatient(id);
      if (_.isEmpty(patientStore.patient)) {
        message.error(`환자 정보를 찾을 수 없습니다.`, 6000);
        navigate('/patient');
      }
    } catch (error) {
      message.error(error, 10000);
      navigate('/patient');
    } finally {
      setIsGlobalLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id } = useParams();
  useEffect(() => {
    init();
    return () => {
      patientStore.resetPatient();
      treatmentStore.treatmentReset();
      historyStore.resetHistories();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AdminLayout
      title={`${patientStore.patient?.patientName! ?? ''} 환자`}
      content={<PatientDetailTab isGlobalLoading={isGlobalLoading} />}
      sideType={1}
      youtubeLinkParam={{
        title: 'Ye-jin Patient Management',
        src: 'https://www.youtube.com/embed/FvknR0HeuyM?autoplay=1&start=12',
      }}
    />
  );
};

export default observer(PatientDetailPage);
