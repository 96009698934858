/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Loader } from '@aws-amplify/ui-react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import message from 'antd-message';

import { useStores } from 'stores/Context';
import { genderConverter } from 'utils/DataConvertUtil';
import { Row, Col, Input, TypographyText, NoticeModal, TutorialModal } from 'components/UI/atoms';
import { convertPatientRecentActivityMessage } from 'utils/util';
import pr from 'repository/PatientRepository';
import { activityMessageMap } from 'constant';
import us from 'repository/UserRepository';
import { isEnableTillStaging } from 'config';

import CreatePatientModal from './CreatePatientModal';
import PatientActivityFilter from './PatientAcitivityFilter';

const PatientList = () => {
  const isMounted = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { clientStore } = useStores();
  const [target, setTarget] = useState<Element | null>(null);
  const [patientList, setPatientList] = useState<any[]>([]);
  const [nextToken, setNextToken] = useState<string | null | undefined>(undefined);
  const [isAdditionalListLoading, setIsAdditionalListLoading] = useState<boolean>(false);
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [isOpenCreatePatientModal, setIsOpenCreatePatientModal] = useState<boolean>(false);
  const [nameOrPhone, setNameOrPhone] = useState<string>();
  const [nameOrPhoneForSearch, setNameOrPhoneForSearch] = useState<string>();
  const [refreshFlag, setRefreshFlag] = useState<boolean>(false);
  const [isOpenNoticeModal, setIsOpenNoticeModal] = useState<boolean>(false);
  const [isOpenTutorialModal, setIsOpenTutorialModal] = useState<boolean>(false);

  // useEffect(() => {
  //   if (location.state?.noticeFlag) {
  //     const config = JSON.parse(clientStore.user?.config ?? '{}');
  //     if (
  //       !config.nonDisplayNotice240429 &&
  //       // 24년 05월 9일 23:59:59 까지 공지 노출
  //       moment().isBefore('2024-05-10')
  //     ) {
  //       setIsOpenNoticeModal(true);
  //     }
  //     window.history.replaceState({}, '');
  //   }
  // }, [location.state?.noticeFlag, clientStore.user?.config]);

  useEffect(() => {
    if (location.state?.tutorialFlag) {
      setIsOpenTutorialModal(true);
      window.history.replaceState({}, '');
    }
  }, [location.state?.tutorialFlag, clientStore.user?.config]);

  const options = useMemo(
    () =>
      Array.from(activityMessageMap).reduce(
        (a, c) => {
          if (c[1].filterOptionLabel) {
            return [
              ...a,
              {
                label: c[1].filterOptionLabel,
                value: c[0],
              },
            ];
          }
          return a;
        },
        [
          {
            label: '전체',
            value: 'all',
          },
        ],
      ),
    [],
  );
  const initFilterText = useMemo(() => '최근 활동 내역 선택', []);
  const [filterText, setFilterText] = useState(initFilterText);
  const initSelectedFilter = useMemo(
    () =>
      options.reduce((a, c) => {
        return {
          ...a,
          [c.value]: false,
        };
      }, {}),
    [],
  );
  const [selectedFilterForSearch, setSelectedFilterForSearch] = useState(initSelectedFilter);
  const [selectedFilter, setSelectedFilter] = useState(initSelectedFilter);

  const handleSearch = async ({
    initLoadingFlag = false,
    resetParamFlag = false,
    value,
    nextToken,
    selectedFilterForSearch,
  }: {
    initLoadingFlag?: boolean;
    resetParamFlag?: boolean;
    value?: string;
    nextToken?: string;
    selectedFilterForSearch?: { [key: string]: boolean };
  }) => {
    try {
      if (initLoadingFlag) {
        await setIsListLoading(true);
      } else {
        await setIsAdditionalListLoading(true);
      }

      if (resetParamFlag) {
        setNextToken(undefined);
        setIsListLoading(true);
      }

      let res = {
        items: [],
        nextToken: undefined,
      };
      res = await pr.patientsByRecentActivity({
        clinicID: clientStore.metaData?.userRole.clinic?.id,
        nextToken: resetParamFlag ? undefined : nextToken,
        searchParam: {
          patientNameOrPhone: value,
          selectedFilter: Object.entries(selectedFilterForSearch ?? {}).reduce(
            (a, [key, value]) => (value ? [...a, key] : [...a]),
            [],
          ),
        },
      });

      await setPatientList(
        resetParamFlag || nextToken === undefined ? res?.items : [...patientList, ...res?.items],
      );
      if (resetParamFlag) {
        setIsListLoading(false);
      }
      setNextToken(res?.nextToken);
    } catch (e) {
      message.error(`환자목록을 가져오는 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 8000);
    } finally {
      await setIsListLoading(false);
      await setIsAdditionalListLoading(false);
    }
  };

  useEffect(() => {
    const ob = new IntersectionObserver(
      (e) => {
        if (e?.[0]?.isIntersecting) {
          handleSearch({
            value: nameOrPhoneForSearch,
            nextToken,
            selectedFilterForSearch,
          });
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );
    target && ob.observe(target);

    return () => {
      ob.disconnect();
    };
  }, [target]);

  useEffect(() => {
    handleSearch({ resetParamFlag: true, initLoadingFlag: !isMounted.current });
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (refreshFlag) {
      handleSearch({
        resetParamFlag: true,
        value: nameOrPhoneForSearch,
        selectedFilterForSearch,
      });
      setRefreshFlag(false);
    }
  }, [refreshFlag]);

  return (
    <>
      {isOpenNoticeModal && (
        <NoticeModal
          cancelFunction={async (nonDisplayLaterFlag) => {
            if (nonDisplayLaterFlag) {
              const config = JSON.parse(clientStore.user?.config ?? '{}');
              const newConfig = JSON.stringify({
                ...config,
                nonDisplayNotice240429: nonDisplayLaterFlag ? true : false,
              });
              await us.updateUser({
                id: clientStore.metaData?.user.id!,
                config: newConfig,
              });
            }
            setIsOpenNoticeModal(false);
          }}
        />
      )}
      {isOpenTutorialModal && (
        <TutorialModal
          cancelFunction={async () => {
            setIsOpenTutorialModal(false);
          }}
        />
      )}
      {isOpenCreatePatientModal && (
        <CreatePatientModal
          setIsOpenCreatePatientModal={setIsOpenCreatePatientModal}
          setRefreshFlag={setRefreshFlag}
        />
      )}
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1.4rem',
          paddingBottom: '1rem',
          height: '100%',
          flex: '1 1 0',
        }}
      >
        <Row
          style={{
            position: 'relative',
            display: 'flex',
            flexWrap: 'wrap',
            rowGap: '0.3rem',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Row style={{ display: 'flex', alignItems: 'center', columnGap: '0.4rem' }}>
            <Col style={{ position: 'relative' }}>
              <Input
                style={{
                  height: '2.1rem',
                  width: '14rem',
                  border: '1px solid #afafaf',
                  borderRadius: '3px',
                  fontSize: '0.8rem',
                  lineHeight: '0.8rem',
                  outline: 'none',
                  textIndent: '1.2rem',
                }}
                value={nameOrPhone}
                placeholder="환자명 또는 전화번호 뒷자리"
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    setNameOrPhoneForSearch(nameOrPhone);
                    setSelectedFilterForSearch(selectedFilter);
                    handleSearch({
                      value: nameOrPhone,
                      resetParamFlag: true,
                      selectedFilterForSearch: selectedFilter,
                    });
                  }
                }}
                onChange={(e) => {
                  setNameOrPhone(e.target.value);
                }}
                autoComplete="off"
              />
              {nameOrPhone?.length > 0 && (
                <Col
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    right: '0.5rem',
                    top: '0.6rem',
                    cursor: 'pointer',
                    height: '1rem',
                  }}
                  onClick={() => {
                    setNameOrPhone(undefined);
                    setNameOrPhoneForSearch(undefined);
                    handleSearch({
                      resetParamFlag: true,
                      selectedFilterForSearch,
                    });
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/img/serach_initialization_icon.webp`}
                    style={{ width: '1rem' }}
                    alt="serach_initialization_icon"
                  />
                </Col>
              )}
              <Col
                style={{
                  position: 'absolute',
                  display: 'flex',
                  left: '0.5rem',
                  top: 'calc(50% + 0.05rem)',
                  transform: 'translateY(-50%)',
                  height: '1rem',
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/search_icon.webp`}
                  style={{ width: '1rem' }}
                  alt="search_icon"
                />
              </Col>
            </Col>
            <PatientActivityFilter
              initFilterText={initFilterText}
              filterText={filterText}
              setFilterText={setFilterText}
              options={options}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              setSelectedFilterForSearch={setSelectedFilterForSearch}
              setRefreshFlag={setRefreshFlag}
            />
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                height: '2.1rem',
                border: '1px solid #346AFF',
                borderRadius: '3px',
                background: '#ffffff',
                cursor: 'pointer',
                flexShrink: 0,
              }}
              onClick={async () => {
                await setNameOrPhoneForSearch(nameOrPhone);
                await setSelectedFilterForSearch(selectedFilter);
                handleSearch({
                  value: nameOrPhone,
                  resetParamFlag: true,
                  selectedFilterForSearch: selectedFilter,
                });
              }}
            >
              <TypographyText
                color="#346AFF"
                fontSize="1rem"
                letterSpacing="0.05rem"
                fontWeight={700}
                lineHeight="1.2rem"
              >
                검색
              </TypographyText>
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                height: '2.1rem',
                border: '1px solid #afafaf',
                borderRadius: '3px',
                background: '#ffffff',
                cursor: 'pointer',
                flexShrink: 0,
              }}
              onClick={async () => {
                setSelectedFilter(initSelectedFilter);
                setSelectedFilterForSearch(initSelectedFilter);
                setFilterText(initFilterText);
                setNameOrPhone(undefined);
                setNameOrPhoneForSearch(undefined);
                handleSearch({ resetParamFlag: true });
              }}
            >
              <TypographyText
                color="#1c252c"
                fontSize="1rem"
                letterSpacing="0.05em"
                fontWeight={700}
                lineHeight="1.2rem"
              >
                초기화
              </TypographyText>
            </Col>
          </Row>
          <Col
            style={{ display: 'flex', flex: '1 1 0', justifyContent: 'flex-end', marginBottom: '-0.45rem' }}
          >
            <button
              onClick={() => setIsOpenCreatePatientModal(true)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '7.7rem',
                height: '3rem',
                borderRadius: '3px',
                border: '1px solid #346AFF',
                background: '#346AFF',
              }}
            >
              <TypographyText
                color="#ffffff"
                fontSize="1rem"
                letterSpacing="0.05rem"
                fontWeight={700}
                lineHeight="1.2rem"
              >
                환자등록
              </TypographyText>
            </button>
          </Col>
        </Row>
        <table
          className={`list-page-common-table patient-list-page-table ${isListLoading && 'height-100 flex-1'}`}
        >
          <thead>
            <tr>
              <th>
                <span>이름</span>
              </th>
              <th>
                <span>생년월일</span>
              </th>
              <th>
                <span>성별</span>
              </th>
              <th>
                <span>최근 활동 내역</span>
              </th>
              <th>
                <span>최근 활동 일시</span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isListLoading ? (
              <tr>
                <td colSpan={6}>
                  <Row
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Row style={{ display: 'flex', marginTop: '-4rem', marginLeft: '-1rem' }}>
                      <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
                    </Row>
                  </Row>
                </td>
              </tr>
            ) : patientList?.length > 0 ? (
              patientList.map((patient, i) => (
                <tr key={patient.id} ref={patientList.length - 1 === i ? setTarget : null}>
                  <td>
                    <span>{patient.patientName}</span>
                  </td>
                  <td>
                    <span>{moment(patient.birth).format('YY/MM/DD')}</span>
                  </td>
                  <td>
                    <span>{genderConverter(patient.gender!)}</span>
                  </td>
                  <td>
                    <span>
                      {convertPatientRecentActivityMessage({
                        patientData: patient,
                        convertMode: 'shortMessage',
                      })}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        color: '#afafaf',
                      }}
                    >
                      {moment(patient.recentActivity).format('YY/MM/DD HH:mm')}
                    </span>
                  </td>
                  <td>
                    <Row
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        columnGap: '0.4rem',
                      }}
                    >
                      <button
                        style={{
                          border: '1px solid #afafaf',
                          borderRadius: '3px',
                          backgroundColor: '#ffffff',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '5rem',
                          height: '2rem',
                        }}
                        onClick={() => navigate(`/patient/${patient.id}?tab=patientInfo`)}
                      >
                        <TypographyText fontWeight={700} style={{ fontSize: '0.8rem' }}>
                          {'환자정보'}
                        </TypographyText>
                      </button>
                      <button
                        style={{
                          border: '1px solid #afafaf',
                          borderRadius: '3px',
                          backgroundColor: '#ffffff',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '5rem',
                          height: '2rem',
                        }}
                        onClick={() => navigate(`/patient/${patient.id}?tab=treatmentHistory`)}
                      >
                        <TypographyText fontWeight={700} style={{ fontSize: '0.8rem' }}>
                          {'진료내역'}
                        </TypographyText>
                      </button>
                    </Row>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <span
                    style={{
                      color: '#afafaf',
                    }}
                  >
                    등록된 환자가 없습니다.
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!isListLoading && isAdditionalListLoading && (
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
          </Row>
        )}
      </Row>
    </>
  );
};

export default observer(PatientList);
