/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createHerb = /* GraphQL */ `mutation CreateHerb(
  $input: CreateHerbInput!
  $condition: ModelHerbConditionInput
) {
  createHerb(input: $input, condition: $condition) {
    code
    herbName
    nameForSearching
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHerbMutationVariables,
  APITypes.CreateHerbMutation
>;
export const updateHerb = /* GraphQL */ `mutation UpdateHerb(
  $input: UpdateHerbInput!
  $condition: ModelHerbConditionInput
) {
  updateHerb(input: $input, condition: $condition) {
    code
    herbName
    nameForSearching
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHerbMutationVariables,
  APITypes.UpdateHerbMutation
>;
export const deleteHerb = /* GraphQL */ `mutation DeleteHerb(
  $input: DeleteHerbInput!
  $condition: ModelHerbConditionInput
) {
  deleteHerb(input: $input, condition: $condition) {
    code
    herbName
    nameForSearching
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHerbMutationVariables,
  APITypes.DeleteHerbMutation
>;
export const createCustomHerbalFormula = /* GraphQL */ `mutation CreateCustomHerbalFormula(
  $input: CreateCustomHerbalFormulaInput!
  $condition: ModelCustomHerbalFormulaConditionInput
) {
  createCustomHerbalFormula(input: $input, condition: $condition) {
    id
    ownerUserID
    originalHerbalFormulaID
    originalHerbalFormulaData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    scoringType
    memo
    isDeleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomHerbalFormulaMutationVariables,
  APITypes.CreateCustomHerbalFormulaMutation
>;
export const updateCustomHerbalFormula = /* GraphQL */ `mutation UpdateCustomHerbalFormula(
  $input: UpdateCustomHerbalFormulaInput!
  $condition: ModelCustomHerbalFormulaConditionInput
) {
  updateCustomHerbalFormula(input: $input, condition: $condition) {
    id
    ownerUserID
    originalHerbalFormulaID
    originalHerbalFormulaData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    scoringType
    memo
    isDeleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomHerbalFormulaMutationVariables,
  APITypes.UpdateCustomHerbalFormulaMutation
>;
export const deleteCustomHerbalFormula = /* GraphQL */ `mutation DeleteCustomHerbalFormula(
  $input: DeleteCustomHerbalFormulaInput!
  $condition: ModelCustomHerbalFormulaConditionInput
) {
  deleteCustomHerbalFormula(input: $input, condition: $condition) {
    id
    ownerUserID
    originalHerbalFormulaID
    originalHerbalFormulaData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    scoringType
    memo
    isDeleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomHerbalFormulaMutationVariables,
  APITypes.DeleteCustomHerbalFormulaMutation
>;
export const createHerbalFormula = /* GraphQL */ `mutation CreateHerbalFormula(
  $input: CreateHerbalFormulaInput!
  $condition: ModelHerbalFormulaConditionInput
) {
  createHerbalFormula(input: $input, condition: $condition) {
    id
    category
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    cn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    gun
    sin
    scoringType
    source
    postfix
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHerbalFormulaMutationVariables,
  APITypes.CreateHerbalFormulaMutation
>;
export const updateHerbalFormula = /* GraphQL */ `mutation UpdateHerbalFormula(
  $input: UpdateHerbalFormulaInput!
  $condition: ModelHerbalFormulaConditionInput
) {
  updateHerbalFormula(input: $input, condition: $condition) {
    id
    category
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    cn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    gun
    sin
    scoringType
    source
    postfix
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHerbalFormulaMutationVariables,
  APITypes.UpdateHerbalFormulaMutation
>;
export const deleteHerbalFormula = /* GraphQL */ `mutation DeleteHerbalFormula(
  $input: DeleteHerbalFormulaInput!
  $condition: ModelHerbalFormulaConditionInput
) {
  deleteHerbalFormula(input: $input, condition: $condition) {
    id
    category
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    cn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    gun
    sin
    scoringType
    source
    postfix
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHerbalFormulaMutationVariables,
  APITypes.DeleteHerbalFormulaMutation
>;
export const createSurvey = /* GraphQL */ `mutation CreateSurvey(
  $input: CreateSurveyInput!
  $condition: ModelSurveyConditionInput
) {
  createSurvey(input: $input, condition: $condition) {
    id
    otp
    patientID
    clinicID
    link
    surveyResult
    mainSymptom
    symptoms
    visitCount
    firstLinkClickDt
    surveyedAt
    updatedAt
    createdAt
    sendCount
    isSend
    treatmentID
    feedbacks
    feedbackResult
    feedbackAt
    feedbackLink
    feedbackDone
    isFeedback
    additionalSymptoms
    feedbackSendTime
    feedbackSchedule_GSI_PK
    feedbackScheduleStatus
    surveyDone
    surveyGrade
    surveySatisfaction
    feedbackGrade
    feedbackSatisfaction
    surveyStatus
    expiredAt
    surveyBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    feedbackBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    mainSymptomCategory
    sender
    surveyType
    insuranceType
    surveySurveyBizTalkHistoryId
    surveyFeedbackBizTalkHistoryId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSurveyMutationVariables,
  APITypes.CreateSurveyMutation
>;
export const updateSurvey = /* GraphQL */ `mutation UpdateSurvey(
  $input: UpdateSurveyInput!
  $condition: ModelSurveyConditionInput
) {
  updateSurvey(input: $input, condition: $condition) {
    id
    otp
    patientID
    clinicID
    link
    surveyResult
    mainSymptom
    symptoms
    visitCount
    firstLinkClickDt
    surveyedAt
    updatedAt
    createdAt
    sendCount
    isSend
    treatmentID
    feedbacks
    feedbackResult
    feedbackAt
    feedbackLink
    feedbackDone
    isFeedback
    additionalSymptoms
    feedbackSendTime
    feedbackSchedule_GSI_PK
    feedbackScheduleStatus
    surveyDone
    surveyGrade
    surveySatisfaction
    feedbackGrade
    feedbackSatisfaction
    surveyStatus
    expiredAt
    surveyBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    feedbackBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    mainSymptomCategory
    sender
    surveyType
    insuranceType
    surveySurveyBizTalkHistoryId
    surveyFeedbackBizTalkHistoryId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSurveyMutationVariables,
  APITypes.UpdateSurveyMutation
>;
export const deleteSurvey = /* GraphQL */ `mutation DeleteSurvey(
  $input: DeleteSurveyInput!
  $condition: ModelSurveyConditionInput
) {
  deleteSurvey(input: $input, condition: $condition) {
    id
    otp
    patientID
    clinicID
    link
    surveyResult
    mainSymptom
    symptoms
    visitCount
    firstLinkClickDt
    surveyedAt
    updatedAt
    createdAt
    sendCount
    isSend
    treatmentID
    feedbacks
    feedbackResult
    feedbackAt
    feedbackLink
    feedbackDone
    isFeedback
    additionalSymptoms
    feedbackSendTime
    feedbackSchedule_GSI_PK
    feedbackScheduleStatus
    surveyDone
    surveyGrade
    surveySatisfaction
    feedbackGrade
    feedbackSatisfaction
    surveyStatus
    expiredAt
    surveyBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    feedbackBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    mainSymptomCategory
    sender
    surveyType
    insuranceType
    surveySurveyBizTalkHistoryId
    surveyFeedbackBizTalkHistoryId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSurveyMutationVariables,
  APITypes.DeleteSurveyMutation
>;
export const createHistory = /* GraphQL */ `mutation CreateHistory(
  $input: CreateHistoryInput!
  $condition: ModelHistoryConditionInput
) {
  createHistory(input: $input, condition: $condition) {
    id
    clinicID
    patientID
    type
    typeID
    messageCode
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHistoryMutationVariables,
  APITypes.CreateHistoryMutation
>;
export const updateHistory = /* GraphQL */ `mutation UpdateHistory(
  $input: UpdateHistoryInput!
  $condition: ModelHistoryConditionInput
) {
  updateHistory(input: $input, condition: $condition) {
    id
    clinicID
    patientID
    type
    typeID
    messageCode
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHistoryMutationVariables,
  APITypes.UpdateHistoryMutation
>;
export const deleteHistory = /* GraphQL */ `mutation DeleteHistory(
  $input: DeleteHistoryInput!
  $condition: ModelHistoryConditionInput
) {
  deleteHistory(input: $input, condition: $condition) {
    id
    clinicID
    patientID
    type
    typeID
    messageCode
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHistoryMutationVariables,
  APITypes.DeleteHistoryMutation
>;
export const createPatient = /* GraphQL */ `mutation CreatePatient(
  $input: CreatePatientInput!
  $condition: ModelPatientConditionInput
) {
  createPatient(input: $input, condition: $condition) {
    id
    clinicID
    patientName
    birth
    residentRegistrationNumber
    gender
    phone
    recentActivity
    recentActivityMessage
    recentActivityCode
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    registrationPath
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePatientMutationVariables,
  APITypes.CreatePatientMutation
>;
export const updatePatient = /* GraphQL */ `mutation UpdatePatient(
  $input: UpdatePatientInput!
  $condition: ModelPatientConditionInput
) {
  updatePatient(input: $input, condition: $condition) {
    id
    clinicID
    patientName
    birth
    residentRegistrationNumber
    gender
    phone
    recentActivity
    recentActivityMessage
    recentActivityCode
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    registrationPath
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePatientMutationVariables,
  APITypes.UpdatePatientMutation
>;
export const deletePatient = /* GraphQL */ `mutation DeletePatient(
  $input: DeletePatientInput!
  $condition: ModelPatientConditionInput
) {
  deletePatient(input: $input, condition: $condition) {
    id
    clinicID
    patientName
    birth
    residentRegistrationNumber
    gender
    phone
    recentActivity
    recentActivityMessage
    recentActivityCode
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    registrationPath
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePatientMutationVariables,
  APITypes.DeletePatientMutation
>;
export const createPatientDeleted = /* GraphQL */ `mutation CreatePatientDeleted(
  $input: CreatePatientDeletedInput!
  $condition: ModelPatientDeletedConditionInput
) {
  createPatientDeleted(input: $input, condition: $condition) {
    id
    clinicID
    patientName
    birth
    gender
    phone
    residentRegistrationNumber
    recentActivity
    recentActivityMessage
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    type
    privacyTerms
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePatientDeletedMutationVariables,
  APITypes.CreatePatientDeletedMutation
>;
export const updatePatientDeleted = /* GraphQL */ `mutation UpdatePatientDeleted(
  $input: UpdatePatientDeletedInput!
  $condition: ModelPatientDeletedConditionInput
) {
  updatePatientDeleted(input: $input, condition: $condition) {
    id
    clinicID
    patientName
    birth
    gender
    phone
    residentRegistrationNumber
    recentActivity
    recentActivityMessage
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    type
    privacyTerms
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePatientDeletedMutationVariables,
  APITypes.UpdatePatientDeletedMutation
>;
export const deletePatientDeleted = /* GraphQL */ `mutation DeletePatientDeleted(
  $input: DeletePatientDeletedInput!
  $condition: ModelPatientDeletedConditionInput
) {
  deletePatientDeleted(input: $input, condition: $condition) {
    id
    clinicID
    patientName
    birth
    gender
    phone
    residentRegistrationNumber
    recentActivity
    recentActivityMessage
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    type
    privacyTerms
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePatientDeletedMutationVariables,
  APITypes.DeletePatientDeletedMutation
>;
export const createUserRole = /* GraphQL */ `mutation CreateUserRole(
  $input: CreateUserRoleInput!
  $condition: ModelUserRoleConditionInput
) {
  createUserRole(input: $input, condition: $condition) {
    id
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    config
    clinicName
    status
    email
    phone
    userID
    user {
      id
      email
      username
      phone
      terms
      termsVersion
      termsAgreedAt
      createdAt
      isDeleted
      roleName
      config
      licenseNumber
      licensePendingReviewFlag
      updatedAt
      __typename
    }
    roleName
    userName
    isDeleted
    testFlag
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserRoleMutationVariables,
  APITypes.CreateUserRoleMutation
>;
export const updateUserRole = /* GraphQL */ `mutation UpdateUserRole(
  $input: UpdateUserRoleInput!
  $condition: ModelUserRoleConditionInput
) {
  updateUserRole(input: $input, condition: $condition) {
    id
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    config
    clinicName
    status
    email
    phone
    userID
    user {
      id
      email
      username
      phone
      terms
      termsVersion
      termsAgreedAt
      createdAt
      isDeleted
      roleName
      config
      licenseNumber
      licensePendingReviewFlag
      updatedAt
      __typename
    }
    roleName
    userName
    isDeleted
    testFlag
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserRoleMutationVariables,
  APITypes.UpdateUserRoleMutation
>;
export const deleteUserRole = /* GraphQL */ `mutation DeleteUserRole(
  $input: DeleteUserRoleInput!
  $condition: ModelUserRoleConditionInput
) {
  deleteUserRole(input: $input, condition: $condition) {
    id
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    config
    clinicName
    status
    email
    phone
    userID
    user {
      id
      email
      username
      phone
      terms
      termsVersion
      termsAgreedAt
      createdAt
      isDeleted
      roleName
      config
      licenseNumber
      licensePendingReviewFlag
      updatedAt
      __typename
    }
    roleName
    userName
    isDeleted
    testFlag
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserRoleMutationVariables,
  APITypes.DeleteUserRoleMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    email
    username
    phone
    terms
    termsVersion
    termsAgreedAt
    createdAt
    isDeleted
    roleName
    config
    licenseNumber
    licensePendingReviewFlag
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    email
    username
    phone
    terms
    termsVersion
    termsAgreedAt
    createdAt
    isDeleted
    roleName
    config
    licenseNumber
    licensePendingReviewFlag
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    email
    username
    phone
    terms
    termsVersion
    termsAgreedAt
    createdAt
    isDeleted
    roleName
    config
    licenseNumber
    licensePendingReviewFlag
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createUserPool = /* GraphQL */ `mutation CreateUserPool(
  $input: CreateUserPoolInput!
  $condition: ModelUserPoolConditionInput
) {
  createUserPool(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPoolMutationVariables,
  APITypes.CreateUserPoolMutation
>;
export const updateUserPool = /* GraphQL */ `mutation UpdateUserPool(
  $input: UpdateUserPoolInput!
  $condition: ModelUserPoolConditionInput
) {
  updateUserPool(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPoolMutationVariables,
  APITypes.UpdateUserPoolMutation
>;
export const deleteUserPool = /* GraphQL */ `mutation DeleteUserPool(
  $input: DeleteUserPoolInput!
  $condition: ModelUserPoolConditionInput
) {
  deleteUserPool(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPoolMutationVariables,
  APITypes.DeleteUserPoolMutation
>;
export const createRole = /* GraphQL */ `mutation CreateRole(
  $input: CreateRoleInput!
  $condition: ModelRoleConditionInput
) {
  createRole(input: $input, condition: $condition) {
    id
    clinicID
    roleName
    permisstions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRoleMutationVariables,
  APITypes.CreateRoleMutation
>;
export const updateRole = /* GraphQL */ `mutation UpdateRole(
  $input: UpdateRoleInput!
  $condition: ModelRoleConditionInput
) {
  updateRole(input: $input, condition: $condition) {
    id
    clinicID
    roleName
    permisstions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRoleMutationVariables,
  APITypes.UpdateRoleMutation
>;
export const deleteRole = /* GraphQL */ `mutation DeleteRole(
  $input: DeleteRoleInput!
  $condition: ModelRoleConditionInput
) {
  deleteRole(input: $input, condition: $condition) {
    id
    clinicID
    roleName
    permisstions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRoleMutationVariables,
  APITypes.DeleteRoleMutation
>;
export const createClinicsForPromotion = /* GraphQL */ `mutation CreateClinicsForPromotion(
  $input: CreateClinicsForPromotionInput!
  $condition: ModelClinicsForPromotionConditionInput
) {
  createClinicsForPromotion(input: $input, condition: $condition) {
    id
    promotionType
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClinicsForPromotionMutationVariables,
  APITypes.CreateClinicsForPromotionMutation
>;
export const updateClinicsForPromotion = /* GraphQL */ `mutation UpdateClinicsForPromotion(
  $input: UpdateClinicsForPromotionInput!
  $condition: ModelClinicsForPromotionConditionInput
) {
  updateClinicsForPromotion(input: $input, condition: $condition) {
    id
    promotionType
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClinicsForPromotionMutationVariables,
  APITypes.UpdateClinicsForPromotionMutation
>;
export const deleteClinicsForPromotion = /* GraphQL */ `mutation DeleteClinicsForPromotion(
  $input: DeleteClinicsForPromotionInput!
  $condition: ModelClinicsForPromotionConditionInput
) {
  deleteClinicsForPromotion(input: $input, condition: $condition) {
    id
    promotionType
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClinicsForPromotionMutationVariables,
  APITypes.DeleteClinicsForPromotionMutation
>;
export const createClinic = /* GraphQL */ `mutation CreateClinic(
  $input: CreateClinicInput!
  $condition: ModelClinicConditionInput
) {
  createClinic(input: $input, condition: $condition) {
    id
    clinicName
    doctorName
    doctorNumber
    phone
    fax
    medicalInstitutionNumber
    prescriptionValidityPeriod
    address
    feedbackDate
    isDeleted
    pricingInfo {
      currentPricingId
      nextPricingId
      __typename
    }
    stepPayID
    numberOfPatients
    numberOfDoctors
    numberOfSteps
    clinicEmail
    updatedAt
    planInfo
    planCheckPassFlag
    createdAt
    typename
    config
    businessType
    promotion
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClinicMutationVariables,
  APITypes.CreateClinicMutation
>;
export const updateClinic = /* GraphQL */ `mutation UpdateClinic(
  $input: UpdateClinicInput!
  $condition: ModelClinicConditionInput
) {
  updateClinic(input: $input, condition: $condition) {
    id
    clinicName
    doctorName
    doctorNumber
    phone
    fax
    medicalInstitutionNumber
    prescriptionValidityPeriod
    address
    feedbackDate
    isDeleted
    pricingInfo {
      currentPricingId
      nextPricingId
      __typename
    }
    stepPayID
    numberOfPatients
    numberOfDoctors
    numberOfSteps
    clinicEmail
    updatedAt
    planInfo
    planCheckPassFlag
    createdAt
    typename
    config
    businessType
    promotion
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClinicMutationVariables,
  APITypes.UpdateClinicMutation
>;
export const deleteClinic = /* GraphQL */ `mutation DeleteClinic(
  $input: DeleteClinicInput!
  $condition: ModelClinicConditionInput
) {
  deleteClinic(input: $input, condition: $condition) {
    id
    clinicName
    doctorName
    doctorNumber
    phone
    fax
    medicalInstitutionNumber
    prescriptionValidityPeriod
    address
    feedbackDate
    isDeleted
    pricingInfo {
      currentPricingId
      nextPricingId
      __typename
    }
    stepPayID
    numberOfPatients
    numberOfDoctors
    numberOfSteps
    clinicEmail
    updatedAt
    planInfo
    planCheckPassFlag
    createdAt
    typename
    config
    businessType
    promotion
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClinicMutationVariables,
  APITypes.DeleteClinicMutation
>;
export const createTreatment = /* GraphQL */ `mutation CreateTreatment(
  $input: CreateTreatmentInput!
  $condition: ModelTreatmentConditionInput
) {
  createTreatment(input: $input, condition: $condition) {
    id
    clinicID
    surveyID
    patientID
    patientData {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    save
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    feedbackID
    mainSymptom
    userID
    prescribedHerb {
      code
      amount
      name
      __typename
    }
    status
    cure
    memo
    lastVisitedAt
    selectSymptom {
      code
      check
      __typename
    }
    symptomCheck
    name
    birth
    gender
    prescriptionStatus
    updatedAt
    createdAt
    amount
    isVisited
    isDeleted
    surveyCheck
    herbsForRecommanded
    treatmentedAt
    treatmentStatus
    patientNameForSearching
    selectedBCode
    prescriptionMode
    prescribedRecipe {
      pc
      amt
      __typename
    }
    prescriptionId
    prescriptionData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    customHerbalFormulaId
    customHerbalFormulaData {
      id
      ownerUserID
      originalHerbalFormulaID
      originalHerbalFormulaData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    prescriptionDocument
    footprintsLog
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTreatmentMutationVariables,
  APITypes.CreateTreatmentMutation
>;
export const updateTreatment = /* GraphQL */ `mutation UpdateTreatment(
  $input: UpdateTreatmentInput!
  $condition: ModelTreatmentConditionInput
) {
  updateTreatment(input: $input, condition: $condition) {
    id
    clinicID
    surveyID
    patientID
    patientData {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    save
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    feedbackID
    mainSymptom
    userID
    prescribedHerb {
      code
      amount
      name
      __typename
    }
    status
    cure
    memo
    lastVisitedAt
    selectSymptom {
      code
      check
      __typename
    }
    symptomCheck
    name
    birth
    gender
    prescriptionStatus
    updatedAt
    createdAt
    amount
    isVisited
    isDeleted
    surveyCheck
    herbsForRecommanded
    treatmentedAt
    treatmentStatus
    patientNameForSearching
    selectedBCode
    prescriptionMode
    prescribedRecipe {
      pc
      amt
      __typename
    }
    prescriptionId
    prescriptionData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    customHerbalFormulaId
    customHerbalFormulaData {
      id
      ownerUserID
      originalHerbalFormulaID
      originalHerbalFormulaData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    prescriptionDocument
    footprintsLog
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTreatmentMutationVariables,
  APITypes.UpdateTreatmentMutation
>;
export const deleteTreatment = /* GraphQL */ `mutation DeleteTreatment(
  $input: DeleteTreatmentInput!
  $condition: ModelTreatmentConditionInput
) {
  deleteTreatment(input: $input, condition: $condition) {
    id
    clinicID
    surveyID
    patientID
    patientData {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    save
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    feedbackID
    mainSymptom
    userID
    prescribedHerb {
      code
      amount
      name
      __typename
    }
    status
    cure
    memo
    lastVisitedAt
    selectSymptom {
      code
      check
      __typename
    }
    symptomCheck
    name
    birth
    gender
    prescriptionStatus
    updatedAt
    createdAt
    amount
    isVisited
    isDeleted
    surveyCheck
    herbsForRecommanded
    treatmentedAt
    treatmentStatus
    patientNameForSearching
    selectedBCode
    prescriptionMode
    prescribedRecipe {
      pc
      amt
      __typename
    }
    prescriptionId
    prescriptionData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    customHerbalFormulaId
    customHerbalFormulaData {
      id
      ownerUserID
      originalHerbalFormulaID
      originalHerbalFormulaData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    prescriptionDocument
    footprintsLog
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTreatmentMutationVariables,
  APITypes.DeleteTreatmentMutation
>;
export const createPrescriptionHisory = /* GraphQL */ `mutation CreatePrescriptionHisory(
  $input: CreatePrescriptionHisoryInput!
  $condition: ModelPrescriptionHisoryConditionInput
) {
  createPrescriptionHisory(input: $input, condition: $condition) {
    id
    treatmentID
    prescribedRecipe {
      pc
      amt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePrescriptionHisoryMutationVariables,
  APITypes.CreatePrescriptionHisoryMutation
>;
export const updatePrescriptionHisory = /* GraphQL */ `mutation UpdatePrescriptionHisory(
  $input: UpdatePrescriptionHisoryInput!
  $condition: ModelPrescriptionHisoryConditionInput
) {
  updatePrescriptionHisory(input: $input, condition: $condition) {
    id
    treatmentID
    prescribedRecipe {
      pc
      amt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrescriptionHisoryMutationVariables,
  APITypes.UpdatePrescriptionHisoryMutation
>;
export const deletePrescriptionHisory = /* GraphQL */ `mutation DeletePrescriptionHisory(
  $input: DeletePrescriptionHisoryInput!
  $condition: ModelPrescriptionHisoryConditionInput
) {
  deletePrescriptionHisory(input: $input, condition: $condition) {
    id
    treatmentID
    prescribedRecipe {
      pc
      amt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePrescriptionHisoryMutationVariables,
  APITypes.DeletePrescriptionHisoryMutation
>;
export const createFormula = /* GraphQL */ `mutation CreateFormula(
  $input: CreateFormulaInput!
  $condition: ModelFormulaConditionInput
) {
  createFormula(input: $input, condition: $condition) {
    id
    formulaName
    chineseName
    herbs
    amount
    primaryCare
    tag
    category
    priority
    gun
    sin
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFormulaMutationVariables,
  APITypes.CreateFormulaMutation
>;
export const updateFormula = /* GraphQL */ `mutation UpdateFormula(
  $input: UpdateFormulaInput!
  $condition: ModelFormulaConditionInput
) {
  updateFormula(input: $input, condition: $condition) {
    id
    formulaName
    chineseName
    herbs
    amount
    primaryCare
    tag
    category
    priority
    gun
    sin
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormulaMutationVariables,
  APITypes.UpdateFormulaMutation
>;
export const deleteFormula = /* GraphQL */ `mutation DeleteFormula(
  $input: DeleteFormulaInput!
  $condition: ModelFormulaConditionInput
) {
  deleteFormula(input: $input, condition: $condition) {
    id
    formulaName
    chineseName
    herbs
    amount
    primaryCare
    tag
    category
    priority
    gun
    sin
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFormulaMutationVariables,
  APITypes.DeleteFormulaMutation
>;
export const createBiztalkHistory = /* GraphQL */ `mutation CreateBiztalkHistory(
  $input: CreateBiztalkHistoryInput!
  $condition: ModelBiztalkHistoryConditionInput
) {
  createBiztalkHistory(input: $input, condition: $condition) {
    msgIdx
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBiztalkHistoryMutationVariables,
  APITypes.CreateBiztalkHistoryMutation
>;
export const updateBiztalkHistory = /* GraphQL */ `mutation UpdateBiztalkHistory(
  $input: UpdateBiztalkHistoryInput!
  $condition: ModelBiztalkHistoryConditionInput
) {
  updateBiztalkHistory(input: $input, condition: $condition) {
    msgIdx
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBiztalkHistoryMutationVariables,
  APITypes.UpdateBiztalkHistoryMutation
>;
export const deleteBiztalkHistory = /* GraphQL */ `mutation DeleteBiztalkHistory(
  $input: DeleteBiztalkHistoryInput!
  $condition: ModelBiztalkHistoryConditionInput
) {
  deleteBiztalkHistory(input: $input, condition: $condition) {
    msgIdx
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBiztalkHistoryMutationVariables,
  APITypes.DeleteBiztalkHistoryMutation
>;
export const createYejinSignupRequest = /* GraphQL */ `mutation CreateYejinSignupRequest(
  $input: CreateYejinSignupRequestInput!
  $condition: ModelYejinSignupRequestConditionInput
) {
  createYejinSignupRequest(input: $input, condition: $condition) {
    id
    userName
    email
    clinicName
    clinicAddress
    clinicPhone
    terms
    termsVersion
    termsAgreedAt
    permission
    licenseNumber
    clinicEmail
    businessType
    createdAt
    approvedAt
    requestStatus
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateYejinSignupRequestMutationVariables,
  APITypes.CreateYejinSignupRequestMutation
>;
export const updateYejinSignupRequest = /* GraphQL */ `mutation UpdateYejinSignupRequest(
  $input: UpdateYejinSignupRequestInput!
  $condition: ModelYejinSignupRequestConditionInput
) {
  updateYejinSignupRequest(input: $input, condition: $condition) {
    id
    userName
    email
    clinicName
    clinicAddress
    clinicPhone
    terms
    termsVersion
    termsAgreedAt
    permission
    licenseNumber
    clinicEmail
    businessType
    createdAt
    approvedAt
    requestStatus
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateYejinSignupRequestMutationVariables,
  APITypes.UpdateYejinSignupRequestMutation
>;
export const deleteYejinSignupRequest = /* GraphQL */ `mutation DeleteYejinSignupRequest(
  $input: DeleteYejinSignupRequestInput!
  $condition: ModelYejinSignupRequestConditionInput
) {
  deleteYejinSignupRequest(input: $input, condition: $condition) {
    id
    userName
    email
    clinicName
    clinicAddress
    clinicPhone
    terms
    termsVersion
    termsAgreedAt
    permission
    licenseNumber
    clinicEmail
    businessType
    createdAt
    approvedAt
    requestStatus
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteYejinSignupRequestMutationVariables,
  APITypes.DeleteYejinSignupRequestMutation
>;
export const createReport = /* GraphQL */ `mutation CreateReport(
  $input: CreateReportInput!
  $condition: ModelReportConditionInput
) {
  createReport(input: $input, condition: $condition) {
    treatmentID
    sendCount
    byeonjeung
    startedAt
    birth
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportMutationVariables,
  APITypes.CreateReportMutation
>;
export const updateReport = /* GraphQL */ `mutation UpdateReport(
  $input: UpdateReportInput!
  $condition: ModelReportConditionInput
) {
  updateReport(input: $input, condition: $condition) {
    treatmentID
    sendCount
    byeonjeung
    startedAt
    birth
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportMutationVariables,
  APITypes.UpdateReportMutation
>;
export const deleteReport = /* GraphQL */ `mutation DeleteReport(
  $input: DeleteReportInput!
  $condition: ModelReportConditionInput
) {
  deleteReport(input: $input, condition: $condition) {
    treatmentID
    sendCount
    byeonjeung
    startedAt
    birth
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportMutationVariables,
  APITypes.DeleteReportMutation
>;
export const createSelfRegistrationQR = /* GraphQL */ `mutation CreateSelfRegistrationQR(
  $input: CreateSelfRegistrationQRInput!
  $condition: ModelSelfRegistrationQRConditionInput
) {
  createSelfRegistrationQR(input: $input, condition: $condition) {
    QRID
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    expiredDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSelfRegistrationQRMutationVariables,
  APITypes.CreateSelfRegistrationQRMutation
>;
export const updateSelfRegistrationQR = /* GraphQL */ `mutation UpdateSelfRegistrationQR(
  $input: UpdateSelfRegistrationQRInput!
  $condition: ModelSelfRegistrationQRConditionInput
) {
  updateSelfRegistrationQR(input: $input, condition: $condition) {
    QRID
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    expiredDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSelfRegistrationQRMutationVariables,
  APITypes.UpdateSelfRegistrationQRMutation
>;
export const deleteSelfRegistrationQR = /* GraphQL */ `mutation DeleteSelfRegistrationQR(
  $input: DeleteSelfRegistrationQRInput!
  $condition: ModelSelfRegistrationQRConditionInput
) {
  deleteSelfRegistrationQR(input: $input, condition: $condition) {
    QRID
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    expiredDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSelfRegistrationQRMutationVariables,
  APITypes.DeleteSelfRegistrationQRMutation
>;
export const createDailySignupCount = /* GraphQL */ `mutation CreateDailySignupCount(
  $input: CreateDailySignupCountInput!
  $condition: ModelDailySignupCountConditionInput
) {
  createDailySignupCount(input: $input, condition: $condition) {
    date
    count
    memo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDailySignupCountMutationVariables,
  APITypes.CreateDailySignupCountMutation
>;
export const updateDailySignupCount = /* GraphQL */ `mutation UpdateDailySignupCount(
  $input: UpdateDailySignupCountInput!
  $condition: ModelDailySignupCountConditionInput
) {
  updateDailySignupCount(input: $input, condition: $condition) {
    date
    count
    memo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDailySignupCountMutationVariables,
  APITypes.UpdateDailySignupCountMutation
>;
export const deleteDailySignupCount = /* GraphQL */ `mutation DeleteDailySignupCount(
  $input: DeleteDailySignupCountInput!
  $condition: ModelDailySignupCountConditionInput
) {
  deleteDailySignupCount(input: $input, condition: $condition) {
    date
    count
    memo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDailySignupCountMutationVariables,
  APITypes.DeleteDailySignupCountMutation
>;
export const createDailyPatientCount = /* GraphQL */ `mutation CreateDailyPatientCount(
  $input: CreateDailyPatientCountInput!
  $condition: ModelDailyPatientCountConditionInput
) {
  createDailyPatientCount(input: $input, condition: $condition) {
    date
    count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDailyPatientCountMutationVariables,
  APITypes.CreateDailyPatientCountMutation
>;
export const updateDailyPatientCount = /* GraphQL */ `mutation UpdateDailyPatientCount(
  $input: UpdateDailyPatientCountInput!
  $condition: ModelDailyPatientCountConditionInput
) {
  updateDailyPatientCount(input: $input, condition: $condition) {
    date
    count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDailyPatientCountMutationVariables,
  APITypes.UpdateDailyPatientCountMutation
>;
export const deleteDailyPatientCount = /* GraphQL */ `mutation DeleteDailyPatientCount(
  $input: DeleteDailyPatientCountInput!
  $condition: ModelDailyPatientCountConditionInput
) {
  deleteDailyPatientCount(input: $input, condition: $condition) {
    date
    count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDailyPatientCountMutationVariables,
  APITypes.DeleteDailyPatientCountMutation
>;
