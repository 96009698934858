import AdminLayout from 'components/templates/AdminLayout';
import PatientList from 'components/UI/organisms/patient/list/PatientList';

const PatientListPage = () => {
  return (
    <AdminLayout
      title={'환자찾기'}
      content={<PatientList />}
      sideType={1}
      youtubeLinkParam={{
        title: 'Ye-jin Patient Management',
        src: 'https://www.youtube.com/embed/FvknR0HeuyM?autoplay=1&start=0',
      }}
    />
  );
};

export default PatientListPage;
