/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import { v4 } from 'uuid';
import { observer } from 'mobx-react';
import message from 'antd-message';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { API, Auth } from 'aws-amplify';
import { parseByeonjeungData, selectSurvey } from 'utils/util';

import { PrescribedHerbInput, UpdateTreatmentInput, UpdateSurveyInput } from 'API';
import { useStores } from 'stores/Context';
import { Row, Modal, TypographyText, Button, ModalType3, Span } from 'components/UI/atoms';
import { getFeedbackTime, convertPalgangData } from 'utils/util';
import { Loading, GraphType1, GraphType2 } from 'components/UI/molecules';
import { codeMap } from 'utils/code';
import tr from 'repository/TreatmentRepository';

const TreatmentFooter = ({
  mode = 'symptom',
  editMode,
  prescribedHerbs,
  selectedPrescriptionData,
  selectedMyPrescriptionData,
  prescriptionCount,
  memo,
  treatmentMode,
}: {
  editMode?: string;
  mode: 'symptom' | 'prescription' | 'cure';
  prescribedHerbs?: any;
  selectedPrescriptionData?: any;
  selectedMyPrescriptionData?: any;
  prescriptionCount?: number;
  memo?: string;
  treatmentMode?: string;
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenNoPrescriptionWarningModal, setIsOpenNoPrescriptionWarningModal] = useState(false);
  const [isOpenByeonjeungPreviewModal, setIsOpenByeonjeungPreviewModal] = useState(false);
  const { id } = useParams();
  const {
    clinicStore,
    prescriptionStore,
    treatmentPageStore,
    treatmentStore,
    surveyStore,
    feedbackStore,
    symptomStore,
  } = useStores();
  const byeonjeungLengthLimit = 3; // 변증 미리보기에서 보여줄 변증 개수

  useEffect(() => {
    if (editMode === 'prescriptionEdit' && id) {
      const handleUnload = () => {
        window.opener.postMessage('childClosed', '*');
      };

      window.addEventListener('unload', handleUnload);

      return () => {
        window.removeEventListener('unload', handleUnload);
      };
    }
  }, []);

  const treatmentDonePrescription = useCallback(
    async ({
      treatmentId,
      feedbackCount = 20,
      param = {
        amount: 0,
        selectedHerbs: [],
        isFeedback: false,
        feedbackScheduleStatus: 0,
        prescriptionId: '',
        customHerbalFormulaId: '',
        prescribedRecipe: [],
        prescriptionMode: 'GENERAL',
      },
    }: {
      treatmentId: string;
      feedbackCount: number;
      param?: {
        amount: number;
        selectedHerbs: PrescribedHerbInput[];
        isFeedback: boolean;
        feedbackScheduleStatus: number;
        prescriptionId?: string;
        customHerbalFormulaId?: string;
        prescribedRecipe?: any;
        prescriptionMode: string;
      };
    }) => {
      const selectedHerbs: PrescribedHerbInput[] = param.selectedHerbs
        .filter((item) => {
          return item.amount > 0;
        })
        .map((item) => ({
          code: item.code,
          // DB에서는 총 약재량의 의미로 쓰이지만, 프론트에서는 1첩당 약재량으로 쓰임
          // 따라서 총 약재량의 의미로 바꾸기 위해 첩수를 곱해줌
          amount: Number(item.amount) * param.amount,
          name: item.name,
        }));
      const feedbackTime = getFeedbackTime(feedbackCount);
      await API.put('yejinadminrestapi', `/treatment/${treatmentId}/done`, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
        response: true,
        body: {
          prescriptionFlag: !_.isEmpty(selectedHerbs) || !_.isEmpty(param.prescribedRecipe),
          amount: param.amount,
          sendCount: feedbackCount,
          selectedHerbs,
          isFeedback: param.isFeedback,
          feedbackSendTime: feedbackTime.feedbackSendTime,
          feedbackScheduleStatus: param.feedbackScheduleStatus,
          feedbackSchedule_GSI_PK: feedbackTime.feedbackSchedule_GSI_PK,
          prescriptionId: param.prescriptionId,
          customHerbalFormulaId: param.customHerbalFormulaId,
          prescribedRecipe: _.sortBy(param.prescribedRecipe, (e) => -e.amt),
          prescriptionMode: param.prescriptionMode,
        },
      });
      // console.log(response);
    },
    [],
  );

  const handleQuit = useCallback(
    async ({
      treatmentId,
      feedbackCount,
      param = {
        amount: 0,
        selectedHerbs: [],
        isFeedback: true,
        feedbackScheduleStatus: 1,
        prescriptionMode: 'GENERAL',
      },
      byeonjeungData = {},
      saveReportFlag = false,
    }) => {
      try {
        await setIsLoading(true);
        if (saveReportFlag) {
          const body = {
            treatmentId: id,
            treatmentStartedAt: moment(treatmentStore.treatment?.createdAt).format('YYYY/MM/DD HH:mm'),
            patientPhone: treatmentStore.treatment?.patientData.phone,
            patientBirth: treatmentStore.treatment?.patientData.birth,
            patientName: treatmentStore.treatment?.patientData.patientName,
            clinicName: clinicStore.clinic.clinicName,
            clinicBusinessType: clinicStore.clinic.businessType,
            sendKaKaoTalkFlag: false,
          };
          if (!_.isEmpty(byeonjeungData)) {
            body['byeonjeung'] = { ...byeonjeungData };
          }
          await API.post('yejinadminrestapi', `/report`, {
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
              authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
            },
            response: true,
            body,
          });
        }

        await treatmentDonePrescription({
          treatmentId,
          feedbackCount,
          param: {
            amount: param.amount,
            selectedHerbs: param.selectedHerbs,
            isFeedback: param.isFeedback,
            feedbackScheduleStatus: param.feedbackScheduleStatus,
            prescriptionMode: param.prescriptionMode,
          },
        }).then(() => {
          message.success(`처방 없이 진료가 완료되었습니다.`, 2500);
          navigate('/treatment');
        });
      } catch (e) {
        message.error(`진료종료 과정에서 문제가 발생했습니다.`, 2500);
        setIsLoading(false);
      }
    },
    [],
  );

  const handleBack = async () => {
    switch (mode) {
      case 'symptom':
        navigate('/treatment');
        treatmentPageStore.setPage(0);
        break;
      case 'cure':
        await setIsLoading(true);
        await treatmentStore.updateTreatment(
          {
            id: id,
            save: 0,
          } as UpdateTreatmentInput,
          true,
        );
        treatmentPageStore.setPage(0);
        await setIsLoading(false);
        break;
      case 'prescription':
        if (editMode === 'prescriptionEdit') {
          window.close();
          break;
        }
        await setIsLoading(true);
        treatmentStore
          .updateTreatment({
            id: id,
            save: 1,
          } as UpdateTreatmentInput)
          .then(() => {
            treatmentPageStore.setPage(1);
            symptomStore.setIsloadingForPatientAnalysis(true);
          })
          .catch((e) => {
            console.log(JSON.stringify(e));
            message.error(e, 2500);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
    }
  };

  const handleNext = async ({ buttonMode }: { buttonMode: 'symptom' | 'cure' | 'prescription' }) => {
    switch (buttonMode) {
      case 'symptom':
        await setIsLoading(true);

        //     console.log('체크된 사상', JSON.parse(JSON.stringify(symptomCheck)));
        // console.log('중요 체크된 설문', JSON.parse(JSON.stringify(surveyCheck)));
        // console.log('받아온 설문', JSON.parse(JSON.stringify(surveys)));
        // console.log('수집된 증상 코드와 중요체크 여부', JSON.parse(JSON.stringify(this.collectedSymtomsCodes)));

        const surveys = selectSurvey(treatmentStore.treatment.survey?.symptoms);
        // console.log('surveys', surveys);
        const selectSymptom = [];
        // 설문 데이터로 부터 증상코드 수집
        _.forEach(surveys ?? [], (e) => {
          const checkFlag = treatmentStore.checkedSurvey.has(e.code);
          const targetSurvey: any = codeMap.get(e.code);
          if (['Check', 'Radio'].includes(targetSurvey.selectType)) {
            const sCodes = (targetSurvey?.answers ?? []).reduce((a, c: any) => {
              const answerFlag = _.isArray(e.value) ? e.value.includes(c.value) : e.value === c.value;
              return [...a, ...(answerFlag ? c.symptomCode ?? [] : [])];
            }, []);
            _.forEach(sCodes, (code) => {
              selectSymptom.push({ code, check: checkFlag });
            });
          } else {
            if (!_.isEmpty(targetSurvey.symptomCode)) {
              _.forEach(targetSurvey.symptomCode, (code) => {
                selectSymptom.push({ code, check: checkFlag });
              });
            }
          }
        });
        const symptomCheck = Array.from(treatmentStore.checkedSymptom);
        // 사상 데이터로 부터 증상코드 수집
        _.forEach(symptomCheck, (code) => {
          if (!_.includes(selectSymptom, code)) {
            selectSymptom.push({ code, check: false }); // 사상은 중요체크가 없으므로 false
          }
        });

        await Promise.all([
          treatmentStore.updateTreatment(
            {
              id: id,
              selectSymptom: selectSymptom.filter((e) => e.code !== 'S000'), // 설문과 사상에서 수집된 증상코드들, 중요 체크된 설문의 증상코드는 check: true
              surveyCheck: Array.from(treatmentStore.checkedSurvey), // 중요 체크된 설문
              symptomCheck, // 사상
              memo: memo,
              save: 1,
            } as UpdateTreatmentInput,
            true,
          ),
          surveyStore.updateSurvey(
            {
              id: treatmentStore.treatment.surveyID,
              feedbacks: JSON.stringify(feedbackStore.symptoms),
              isFeedback: feedbackStore.isFeedback,
              sendCount: feedbackStore.count,
            } as UpdateSurveyInput,
            true,
          ),
        ])
          .then(() => {
            treatmentPageStore.setPage(1);
            symptomStore.setIsloadingForPatientAnalysis(true);
          })
          .catch((e) => {
            console.log(JSON.stringify(e));
            message.error(e, 2500);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case 'cure':
        await setIsLoading(true);
        await Promise.all([
          API.post('yejinadminrestapi', `/report`, {
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
              authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
            },
            response: true,
            body: {
              treatmentId: id,
              byeonjeung: { ...byeonjeungData },
              treatmentStartedAt: moment(treatmentStore.treatment?.createdAt).format('YYYY/MM/DD HH:mm'),
              patientPhone: treatmentStore.treatment?.patientData.phone,
              patientBirth: treatmentStore.treatment?.patientData.birth,
              patientName: treatmentStore.treatment?.patientData.patientName,
              clinicName: clinicStore.clinic.clinicName,
              clinicBusinessType: clinicStore.clinic.businessType,
              sendKaKaoTalkFlag: false,
            },
          }),
          treatmentStore.updateTreatment({
            id: id,
            save: 2,
            selectedBCode: treatmentStore.selectedBCode.map((e) => JSON.stringify(e)),
          } as UpdateTreatmentInput),
        ])
          .then(() => {
            treatmentPageStore.setPage(2);
          })
          .catch((e) => {
            console.log(JSON.stringify(e));
            message.error(e, 2500);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case 'prescription':
        // 처방 수정 모드
        const treatmentId = treatmentStore.treatment?.id;
        const prescribedRecipe = _.values(prescribedHerbs)?.map((e) => ({
          pc: e.code,
          amt: e.amount,
        }));
        if (editMode === 'prescriptionEdit') {
          await Promise.all([
            tr.createPrescriptionHistory({
              id: v4().toString(),
              treatmentID: treatmentId,
              prescribedRecipe,
              createdAt: new Date().toISOString(),
            }),
            tr.updateTreatment({
              id: treatmentId,
              prescriptionMode: treatmentMode,
              prescribedRecipe,
              prescriptionId: selectedPrescriptionData?.id,
              customHerbalFormulaId: selectedMyPrescriptionData?.id,
              amount: prescriptionCount,
            }),
          ]);
          window.close();
          break;
        }
        // 처방이 없을 경우
        if (_.isEmpty(prescriptionStore.selectedHerbs) && _.isEmpty(prescribedHerbs)) {
          setIsOpenNoPrescriptionWarningModal(true);
        } else {
          // 처방이 있을 경우
          await setIsLoading(true);
          try {
            await Promise.all([
              tr.createPrescriptionHistory({
                id: v4().toString(),
                treatmentID: treatmentId,
                prescribedRecipe,
                createdAt: new Date().toISOString(),
              }),
              treatmentDonePrescription({
                treatmentId: treatmentStore.treatment?.id,
                feedbackCount: feedbackStore.count,
                param: {
                  amount: prescriptionCount,
                  selectedHerbs: prescriptionStore.selectedHerbs,
                  prescriptionId: selectedPrescriptionData?.id,
                  customHerbalFormulaId: selectedMyPrescriptionData?.id,
                  prescribedRecipe,
                  isFeedback: feedbackStore.isFeedback,
                  feedbackScheduleStatus: feedbackStore.isFeedback ? 1 : 0,
                  prescriptionMode: treatmentMode,
                },
              }),
            ]);
            message.success(`진료가 완료되었습니다.`, 2500);
            treatmentPageStore.setPage(0);
            setIsLoading(false);
            navigate('/treatment');
          } catch (e) {
            setIsLoading(false);
            console.log(JSON.stringify(e));
            message.error(e, 2500);
          }
        }
        break;
    }
  };

  const byeonjeungData = parseByeonjeungData({
    b: symptomStore.analysisResult,
    selectedBCodeArray: _.map(treatmentStore.selectedBCode ?? [], (e) => e.code),
  });

  return (
    <>
      {isOpenNoPrescriptionWarningModal && (
        <Modal
          contentMsg="처방없이 진료를 완료하시겠습니까?"
          cancelMsg="취소"
          okMsg="네, 진료 완료하겠습니다."
          okFunction={async () => {
            await handleQuit({
              treatmentId: treatmentStore.treatment?.id,
              feedbackCount: feedbackStore.count,
              param: {
                amount: prescriptionCount,
                selectedHerbs: [],
                isFeedback: feedbackStore.isFeedback,
                feedbackScheduleStatus: feedbackStore.isFeedback ? 1 : 0, // 보낼 피드백이 없을 경우에는 0(스케쥴 없음)으로
                prescriptionMode: treatmentMode,
              },
              byeonjeungData: byeonjeungData,
              saveReportFlag: mode === 'cure',
            });
            await setIsOpenNoPrescriptionWarningModal(false);
          }}
          cancelFunction={() => setIsOpenNoPrescriptionWarningModal(false)}
        />
      )}
      <ModalType3
        isOpen={isOpenByeonjeungPreviewModal}
        title="환자 발송용 미리보기"
        titleDivStyle={{ paddingTop: '2rem', paddingLeft: '2rem', paddingBottom: '1rem' }}
        width={22}
        contentStyle={{ padding: '0 2rem 1rem 2rem' }}
        content={
          <>
            <div className=" w-full min-h-full h-max">
              <div className="flex gap-x-1">
                <Span className=" text-1 leading-1.2 tracking-m1 font-bold" divClassName="mt-3">
                  신체 상태 분석 결과
                </Span>
              </div>
              <Span divClassName="mt-3" className="text-0.75 leading-1.2 tracking-m1">
                {convertPalgangData(byeonjeungData, treatmentStore.treatment?.patientData.patientName)}
              </Span>
              <div style={{ marginTop: '1rem' }}>
                <GraphType1
                  leftKLabel="열"
                  leftCLabel="熱"
                  leftValue={byeonjeungData?.팔강?.열}
                  rightKLabel="한"
                  rightCLabel="寒"
                  rightValue={byeonjeungData?.팔강?.한}
                  bottomDidiverFlag
                />
                <GraphType1
                  leftKLabel="표"
                  leftCLabel="表"
                  leftValue={byeonjeungData?.팔강?.표}
                  rightKLabel="리"
                  rightCLabel="裏"
                  rightValue={byeonjeungData?.팔강?.리}
                  bottomDidiverFlag
                />
                <GraphType1
                  leftKLabel="실"
                  leftCLabel="實"
                  leftValue={byeonjeungData?.팔강?.실}
                  rightKLabel="허"
                  rightCLabel="虛"
                  rightValue={byeonjeungData?.팔강?.허}
                />
              </div>
              <div className="flex w-full justify-center mt-6">
                <img
                  width={'100%'}
                  src={`${process.env.PUBLIC_URL}/img/palgang-bar.webp`}
                  alt="팔강 이미지"
                />
              </div>
              <div className="mt-3 flex flex-col">
                <div className="flex border-t border-e5e5e5">
                  <div className="flex justify-center items-center w-9.4 min-h-10.8 bg-F9F9F9">
                    <span className="text-0.7 leading-1.2 font-bold whitespace-nowrap text-DF635C">
                      {'열(熱)'}
                    </span>
                  </div>
                  <div className="flex flex-1 items-center pt-2 pb-2 pl-1.2 pr-1.2 ">
                    <span className="text-0.7 leading-1.2 text-1D1C1D whitespace-pre-wrap break-keep">
                      {`특정 부위에 열이 쌓이거나 체액 부족으로 염증이 쉽게 생기는 등 신체 기능이 항진된 상태`}
                    </span>
                  </div>
                </div>
                <div className="flex border-t border-e5e5e5">
                  <div className="flex justify-center items-center w-9.4 min-h-10.8 bg-F9F9F9">
                    <span className="text-0.7 leading-1.2 font-bold whitespace-nowrap text-52B4D2">
                      {'한(寒)'}
                    </span>
                  </div>
                  <div className="flex flex-1 items-center pt-2 pb-2 pl-1.2 pr-1.2 ">
                    <span className="text-0.7 leading-1.2 text-1D1C1D whitespace-pre-wrap break-keep">
                      {`열에너지 부족으로 체온이 낮고, 말초 혈액순환과 소화기능이 저하된 상태`}
                    </span>
                  </div>
                </div>
                <div className="flex border-t border-e5e5e5">
                  <div className="flex justify-center items-center w-9.4 min-h-10.8 bg-F9F9F9">
                    <span className="text-0.7 leading-1.2 font-bold whitespace-nowrap text-DF635C">
                      {'표(表)'}
                    </span>
                  </div>
                  <div className="flex flex-1 items-center pt-2 pb-2 pl-1.2 pr-1.2 ">
                    <span className="text-0.7 leading-1.2 text-1D1C1D whitespace-pre-wrap break-keep">
                      {`면역력 저하로 코, 목, 피부 등 몸 표면이 감염성 질환에 노출된 상태`}
                    </span>
                  </div>
                </div>
                <div className="flex border-t border-e5e5e5">
                  <div className="flex justify-center items-center w-9.4 min-h-10.8 bg-F9F9F9">
                    <span className="text-0.7 leading-1.2 font-bold whitespace-nowrap text-52B4D2">
                      {'리(裏)'}
                    </span>
                  </div>
                  <div className="flex flex-1 items-center pt-2 pb-2 pl-1.2 pr-1.2 ">
                    <span className="text-0.7 leading-1.2 text-1D1C1D whitespace-pre-wrap break-keep">
                      {`신체 외부에 있던 병이 내부 장기로 진행되어 신체 구조, 기능, 체액 분포에 문제가 생긴 상태`}
                    </span>
                  </div>
                </div>
                <div className="flex border-t border-e5e5e5">
                  <div className="flex justify-center items-center w-9.4 min-h-10.8 bg-F9F9F9">
                    <span className="text-0.7 leading-1.2 font-bold whitespace-nowrap text-DF635C">
                      {'실(實)'}
                    </span>
                  </div>
                  <div className="flex flex-1 items-center pt-2 pb-2 pl-1.2 pr-1.2 ">
                    <span className="text-0.7 leading-1.2 text-1D1C1D whitespace-pre-wrap break-keep">
                      {`스트레스로 인한 불쾌감이 쌓이거나 병리성 대사산물이 정체된 상태`}
                    </span>
                  </div>
                </div>
                <div className="flex border-t border-b border-e5e5e5">
                  <div className="flex justify-center items-center w-9.4 min-h-10.8 bg-F9F9F9">
                    <span className="text-0.7 leading-1.2 font-bold whitespace-nowrap text-52B4D2">
                      {'허(虛)'}
                    </span>
                  </div>
                  <div className="flex flex-1 items-center pt-2 pb-2 pl-1.2 pr-1.2 ">
                    <span className="text-0.7 leading-1.2 text-1D1C1D whitespace-pre-wrap break-keep">
                      {`영양 부족이나 소모로 인체 생리활동과 저항력이 저하된 상태`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex gap-x-1 mt-5">
                <Span className=" text-1 leading-1.2 tracking-m1 font-bold" divClassName="mt-3">
                  진단 예측 결과
                </Span>
              </div>
              <Span className="text-0.75 leading-1.2 tracking-m1" divClassName="mt-3">
                {`환자분의 설문을 토대로 분석한 증상의 한의학적 원인 상위 ${
                  byeonjeungData?.변증?.filter((e) => e.checkFlag)?.length > 0
                    ? byeonjeungData?.변증?.filter((e) => e.checkFlag)?.length
                    : byeonjeungData?.변증?.slice(0, byeonjeungLengthLimit)?.length
                }가지입니다.`}
              </Span>
              <div className="mt-4">
                <GraphType2
                  data={
                    byeonjeungData?.변증?.filter((e) => e.checkFlag)?.length > 0
                      ? byeonjeungData?.변증?.filter((e) => e.checkFlag)
                      : byeonjeungData?.변증?.slice(0, byeonjeungLengthLimit)
                  }
                />
              </div>
              <div className="mt-5 text-0.75 leading-1.2 flex justify-center">
                <Span>자세한 내용은 한의사와 상담하시기 바랍니다. 😊</Span>
              </div>
            </div>
          </>
        }
        cancelMsg="닫기"
        okMsg="환자에게 발송"
        okFunction={async () => {
          try {
            await setIsLoading(true);
            await API.post('yejinadminrestapi', `/report`, {
              headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
                authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
              },
              response: true,
              body: {
                treatmentId: id,
                byeonjeung: { ...byeonjeungData },
                treatmentStartedAt: moment(treatmentStore.treatment?.createdAt).format('YYYY/MM/DD HH:mm'),
                patientPhone: treatmentStore.treatment?.patientData.phone,
                patientBirth: treatmentStore.treatment?.patientData.birth,
                patientName: treatmentStore.treatment?.patientData.patientName,
                clinicName: clinicStore.clinic.clinicName,
                clinicBusinessType: clinicStore.clinic.businessType,
                sendKaKaoTalkFlag: true,
              },
            });
            message.success(
              `${treatmentStore.treatment?.patientData.patientName}님에게 변증 결과 카카오톡이 발송되었습니다.`,
              2500,
            );
          } catch (e) {
            console.log(e);
            message.error(`변증 결과 카카오톡 발송이 실패되었습니다. 다시 발송해 주세요.`, 2500);
          } finally {
            setIsOpenByeonjeungPreviewModal(false);
            setIsLoading(false);
          }
        }}
        cancelFunction={() => setIsOpenByeonjeungPreviewModal(false)}
      />
      <Row style={{ display: 'flex', columnGap: '0.6rem', justifyContent: 'flex-end' }}>
        {mode === 'symptom' && (
          <Button
            style={{ border: '1px solid #346AFF' }}
            onClick={() => handleNext({ buttonMode: 'prescription' })}
            yjTypeButton="footer-white-button"
          >
            <TypographyText whiteSpace="pre" color="#346AFF">
              진료완료
            </TypographyText>
          </Button>
        )}
        {mode === 'cure' && (
          <Button
            style={{ border: '1px solid #346AFF' }}
            onClick={async () => {
              if (symptomStore.isloadingForPatientAnalysis) {
                message.error(`변증 분석중입니다. 분석 완료 후 진료 완료가 가능합니다.`, 3000);
              } else {
                handleNext({ buttonMode: 'prescription' });
              }
            }}
            yjTypeButton="footer-white-button"
          >
            <TypographyText whiteSpace="pre" color="#346AFF">
              진료완료
            </TypographyText>
          </Button>
        )}
        {mode === 'cure' && !_.isEmpty(byeonjeungData?.팔강) && !_.isEmpty(byeonjeungData?.변증) && (
          <Button onClick={() => setIsOpenByeonjeungPreviewModal(true)} yjTypeButton="footer-white-button">
            <TypographyText whiteSpace="pre">환자에게 변증 결과 카카오톡 발송</TypographyText>
          </Button>
        )}
        <Button style={{ flex: 1 }} onClick={handleBack} yjTypeButton="footer-white-button">
          <TypographyText whiteSpace="pre">
            {editMode === 'prescriptionEdit' ? '취소' : '뒤로'}
          </TypographyText>
        </Button>
        <Button
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            minWidth: '7.75rem',
            justifyContent: 'center',
            cursor: mode === 'cure' && symptomStore.isloadingForPatientAnalysis ? 'default' : 'pointer',
            background: mode === 'cure' && symptomStore.isloadingForPatientAnalysis ? '#afafaf' : '#346AFF',
          }}
          yjTypeButton="footer-black-button"
          onClick={async () => {
            if (mode === 'cure' && symptomStore.isloadingForPatientAnalysis) {
              message.error(`변증 분석중입니다. 분석 완료 후 다음 단계로 이동이 가능합니다.`, 3000);
            } else {
              handleNext({ buttonMode: mode });
            }
          }}
        >
          <TypographyText color={'#ffffff'} whiteSpace="pre">
            {mode === 'prescription'
              ? editMode === 'prescriptionEdit'
                ? '처방 수정하기'
                : '진료완료'
              : mode === 'cure' && symptomStore.isloadingForPatientAnalysis
              ? '변증 분석 중'
              : `다음으로`}
          </TypographyText>
        </Button>
      </Row>
      {isLoading && <Loading mode="global" />}
    </>
  );
};

export default observer(TreatmentFooter);
