/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onPatientByClinicID = /* GraphQL */ `subscription OnPatientByClinicID($clinicID: ID!) {
  onPatientByClinicID(clinicID: $clinicID) {
    id
    clinicID
    patientName
    birth
    residentRegistrationNumber
    gender
    phone
    recentActivity
    recentActivityMessage
    recentActivityCode
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    registrationPath
    isDeleted
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnPatientByClinicIDSubscriptionVariables,
  APITypes.OnPatientByClinicIDSubscription
>;
export const onHistoryByPatientID = /* GraphQL */ `subscription OnHistoryByPatientID($patientID: ID!) {
  onHistoryByPatientID(patientID: $patientID) {
    id
    clinicID
    patientID
    type
    typeID
    messageCode
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnHistoryByPatientIDSubscriptionVariables,
  APITypes.OnHistoryByPatientIDSubscription
>;
export const onTreatmentByClinicID = /* GraphQL */ `subscription OnTreatmentByClinicID($clinicID: ID!) {
  onTreatmentByClinicID(clinicID: $clinicID) {
    id
    clinicID
    surveyID
    patientID
    patientData {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    save
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    feedbackID
    mainSymptom
    userID
    prescribedHerb {
      code
      amount
      name
      __typename
    }
    status
    cure
    memo
    lastVisitedAt
    selectSymptom {
      code
      check
      __typename
    }
    symptomCheck
    name
    birth
    gender
    prescriptionStatus
    updatedAt
    createdAt
    amount
    isVisited
    isDeleted
    surveyCheck
    herbsForRecommanded
    treatmentedAt
    treatmentStatus
    patientNameForSearching
    selectedBCode
    prescriptionMode
    prescribedRecipe {
      pc
      amt
      __typename
    }
    prescriptionId
    prescriptionData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    customHerbalFormulaId
    customHerbalFormulaData {
      id
      ownerUserID
      originalHerbalFormulaID
      originalHerbalFormulaData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    prescriptionDocument
    footprintsLog
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnTreatmentByClinicIDSubscriptionVariables,
  APITypes.OnTreatmentByClinicIDSubscription
>;
export const onClinicByID = /* GraphQL */ `subscription OnClinicByID($id: ID!) {
  onClinicByID(id: $id) {
    id
    clinicName
    doctorName
    doctorNumber
    phone
    fax
    medicalInstitutionNumber
    prescriptionValidityPeriod
    address
    feedbackDate
    isDeleted
    pricingInfo {
      currentPricingId
      nextPricingId
      __typename
    }
    stepPayID
    numberOfPatients
    numberOfDoctors
    numberOfSteps
    clinicEmail
    updatedAt
    planInfo
    planCheckPassFlag
    createdAt
    typename
    config
    businessType
    promotion
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnClinicByIDSubscriptionVariables,
  APITypes.OnClinicByIDSubscription
>;
export const onCreateHerb = /* GraphQL */ `subscription OnCreateHerb($filter: ModelSubscriptionHerbFilterInput) {
  onCreateHerb(filter: $filter) {
    code
    herbName
    nameForSearching
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateHerbSubscriptionVariables,
  APITypes.OnCreateHerbSubscription
>;
export const onUpdateHerb = /* GraphQL */ `subscription OnUpdateHerb($filter: ModelSubscriptionHerbFilterInput) {
  onUpdateHerb(filter: $filter) {
    code
    herbName
    nameForSearching
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateHerbSubscriptionVariables,
  APITypes.OnUpdateHerbSubscription
>;
export const onDeleteHerb = /* GraphQL */ `subscription OnDeleteHerb($filter: ModelSubscriptionHerbFilterInput) {
  onDeleteHerb(filter: $filter) {
    code
    herbName
    nameForSearching
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteHerbSubscriptionVariables,
  APITypes.OnDeleteHerbSubscription
>;
export const onCreateCustomHerbalFormula = /* GraphQL */ `subscription OnCreateCustomHerbalFormula(
  $filter: ModelSubscriptionCustomHerbalFormulaFilterInput
) {
  onCreateCustomHerbalFormula(filter: $filter) {
    id
    ownerUserID
    originalHerbalFormulaID
    originalHerbalFormulaData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    scoringType
    memo
    isDeleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCustomHerbalFormulaSubscriptionVariables,
  APITypes.OnCreateCustomHerbalFormulaSubscription
>;
export const onUpdateCustomHerbalFormula = /* GraphQL */ `subscription OnUpdateCustomHerbalFormula(
  $filter: ModelSubscriptionCustomHerbalFormulaFilterInput
) {
  onUpdateCustomHerbalFormula(filter: $filter) {
    id
    ownerUserID
    originalHerbalFormulaID
    originalHerbalFormulaData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    scoringType
    memo
    isDeleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCustomHerbalFormulaSubscriptionVariables,
  APITypes.OnUpdateCustomHerbalFormulaSubscription
>;
export const onDeleteCustomHerbalFormula = /* GraphQL */ `subscription OnDeleteCustomHerbalFormula(
  $filter: ModelSubscriptionCustomHerbalFormulaFilterInput
) {
  onDeleteCustomHerbalFormula(filter: $filter) {
    id
    ownerUserID
    originalHerbalFormulaID
    originalHerbalFormulaData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    scoringType
    memo
    isDeleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCustomHerbalFormulaSubscriptionVariables,
  APITypes.OnDeleteCustomHerbalFormulaSubscription
>;
export const onCreateHerbalFormula = /* GraphQL */ `subscription OnCreateHerbalFormula(
  $filter: ModelSubscriptionHerbalFormulaFilterInput
) {
  onCreateHerbalFormula(filter: $filter) {
    id
    category
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    cn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    gun
    sin
    scoringType
    source
    postfix
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateHerbalFormulaSubscriptionVariables,
  APITypes.OnCreateHerbalFormulaSubscription
>;
export const onUpdateHerbalFormula = /* GraphQL */ `subscription OnUpdateHerbalFormula(
  $filter: ModelSubscriptionHerbalFormulaFilterInput
) {
  onUpdateHerbalFormula(filter: $filter) {
    id
    category
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    cn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    gun
    sin
    scoringType
    source
    postfix
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateHerbalFormulaSubscriptionVariables,
  APITypes.OnUpdateHerbalFormulaSubscription
>;
export const onDeleteHerbalFormula = /* GraphQL */ `subscription OnDeleteHerbalFormula(
  $filter: ModelSubscriptionHerbalFormulaFilterInput
) {
  onDeleteHerbalFormula(filter: $filter) {
    id
    category
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    cn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    gun
    sin
    scoringType
    source
    postfix
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteHerbalFormulaSubscriptionVariables,
  APITypes.OnDeleteHerbalFormulaSubscription
>;
export const onCreateSurvey = /* GraphQL */ `subscription OnCreateSurvey($filter: ModelSubscriptionSurveyFilterInput) {
  onCreateSurvey(filter: $filter) {
    id
    otp
    patientID
    clinicID
    link
    surveyResult
    mainSymptom
    symptoms
    visitCount
    firstLinkClickDt
    surveyedAt
    updatedAt
    createdAt
    sendCount
    isSend
    treatmentID
    feedbacks
    feedbackResult
    feedbackAt
    feedbackLink
    feedbackDone
    isFeedback
    additionalSymptoms
    feedbackSendTime
    feedbackSchedule_GSI_PK
    feedbackScheduleStatus
    surveyDone
    surveyGrade
    surveySatisfaction
    feedbackGrade
    feedbackSatisfaction
    surveyStatus
    expiredAt
    surveyBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    feedbackBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    mainSymptomCategory
    sender
    surveyType
    insuranceType
    surveySurveyBizTalkHistoryId
    surveyFeedbackBizTalkHistoryId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSurveySubscriptionVariables,
  APITypes.OnCreateSurveySubscription
>;
export const onUpdateSurvey = /* GraphQL */ `subscription OnUpdateSurvey($filter: ModelSubscriptionSurveyFilterInput) {
  onUpdateSurvey(filter: $filter) {
    id
    otp
    patientID
    clinicID
    link
    surveyResult
    mainSymptom
    symptoms
    visitCount
    firstLinkClickDt
    surveyedAt
    updatedAt
    createdAt
    sendCount
    isSend
    treatmentID
    feedbacks
    feedbackResult
    feedbackAt
    feedbackLink
    feedbackDone
    isFeedback
    additionalSymptoms
    feedbackSendTime
    feedbackSchedule_GSI_PK
    feedbackScheduleStatus
    surveyDone
    surveyGrade
    surveySatisfaction
    feedbackGrade
    feedbackSatisfaction
    surveyStatus
    expiredAt
    surveyBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    feedbackBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    mainSymptomCategory
    sender
    surveyType
    insuranceType
    surveySurveyBizTalkHistoryId
    surveyFeedbackBizTalkHistoryId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSurveySubscriptionVariables,
  APITypes.OnUpdateSurveySubscription
>;
export const onDeleteSurvey = /* GraphQL */ `subscription OnDeleteSurvey($filter: ModelSubscriptionSurveyFilterInput) {
  onDeleteSurvey(filter: $filter) {
    id
    otp
    patientID
    clinicID
    link
    surveyResult
    mainSymptom
    symptoms
    visitCount
    firstLinkClickDt
    surveyedAt
    updatedAt
    createdAt
    sendCount
    isSend
    treatmentID
    feedbacks
    feedbackResult
    feedbackAt
    feedbackLink
    feedbackDone
    isFeedback
    additionalSymptoms
    feedbackSendTime
    feedbackSchedule_GSI_PK
    feedbackScheduleStatus
    surveyDone
    surveyGrade
    surveySatisfaction
    feedbackGrade
    feedbackSatisfaction
    surveyStatus
    expiredAt
    surveyBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    feedbackBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    mainSymptomCategory
    sender
    surveyType
    insuranceType
    surveySurveyBizTalkHistoryId
    surveyFeedbackBizTalkHistoryId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSurveySubscriptionVariables,
  APITypes.OnDeleteSurveySubscription
>;
export const onCreateHistory = /* GraphQL */ `subscription OnCreateHistory($filter: ModelSubscriptionHistoryFilterInput) {
  onCreateHistory(filter: $filter) {
    id
    clinicID
    patientID
    type
    typeID
    messageCode
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateHistorySubscriptionVariables,
  APITypes.OnCreateHistorySubscription
>;
export const onUpdateHistory = /* GraphQL */ `subscription OnUpdateHistory($filter: ModelSubscriptionHistoryFilterInput) {
  onUpdateHistory(filter: $filter) {
    id
    clinicID
    patientID
    type
    typeID
    messageCode
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateHistorySubscriptionVariables,
  APITypes.OnUpdateHistorySubscription
>;
export const onDeleteHistory = /* GraphQL */ `subscription OnDeleteHistory($filter: ModelSubscriptionHistoryFilterInput) {
  onDeleteHistory(filter: $filter) {
    id
    clinicID
    patientID
    type
    typeID
    messageCode
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteHistorySubscriptionVariables,
  APITypes.OnDeleteHistorySubscription
>;
export const onCreatePatient = /* GraphQL */ `subscription OnCreatePatient($filter: ModelSubscriptionPatientFilterInput) {
  onCreatePatient(filter: $filter) {
    id
    clinicID
    patientName
    birth
    residentRegistrationNumber
    gender
    phone
    recentActivity
    recentActivityMessage
    recentActivityCode
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    registrationPath
    isDeleted
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePatientSubscriptionVariables,
  APITypes.OnCreatePatientSubscription
>;
export const onUpdatePatient = /* GraphQL */ `subscription OnUpdatePatient($filter: ModelSubscriptionPatientFilterInput) {
  onUpdatePatient(filter: $filter) {
    id
    clinicID
    patientName
    birth
    residentRegistrationNumber
    gender
    phone
    recentActivity
    recentActivityMessage
    recentActivityCode
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    registrationPath
    isDeleted
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePatientSubscriptionVariables,
  APITypes.OnUpdatePatientSubscription
>;
export const onDeletePatient = /* GraphQL */ `subscription OnDeletePatient($filter: ModelSubscriptionPatientFilterInput) {
  onDeletePatient(filter: $filter) {
    id
    clinicID
    patientName
    birth
    residentRegistrationNumber
    gender
    phone
    recentActivity
    recentActivityMessage
    recentActivityCode
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    registrationPath
    isDeleted
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePatientSubscriptionVariables,
  APITypes.OnDeletePatientSubscription
>;
export const onCreatePatientDeleted = /* GraphQL */ `subscription OnCreatePatientDeleted(
  $filter: ModelSubscriptionPatientDeletedFilterInput
) {
  onCreatePatientDeleted(filter: $filter) {
    id
    clinicID
    patientName
    birth
    gender
    phone
    residentRegistrationNumber
    recentActivity
    recentActivityMessage
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    type
    privacyTerms
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePatientDeletedSubscriptionVariables,
  APITypes.OnCreatePatientDeletedSubscription
>;
export const onUpdatePatientDeleted = /* GraphQL */ `subscription OnUpdatePatientDeleted(
  $filter: ModelSubscriptionPatientDeletedFilterInput
) {
  onUpdatePatientDeleted(filter: $filter) {
    id
    clinicID
    patientName
    birth
    gender
    phone
    residentRegistrationNumber
    recentActivity
    recentActivityMessage
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    type
    privacyTerms
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePatientDeletedSubscriptionVariables,
  APITypes.OnUpdatePatientDeletedSubscription
>;
export const onDeletePatientDeleted = /* GraphQL */ `subscription OnDeletePatientDeleted(
  $filter: ModelSubscriptionPatientDeletedFilterInput
) {
  onDeletePatientDeleted(filter: $filter) {
    id
    clinicID
    patientName
    birth
    gender
    phone
    residentRegistrationNumber
    recentActivity
    recentActivityMessage
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    type
    privacyTerms
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePatientDeletedSubscriptionVariables,
  APITypes.OnDeletePatientDeletedSubscription
>;
export const onCreateUserRole = /* GraphQL */ `subscription OnCreateUserRole($filter: ModelSubscriptionUserRoleFilterInput) {
  onCreateUserRole(filter: $filter) {
    id
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    config
    clinicName
    status
    email
    phone
    userID
    user {
      id
      email
      username
      phone
      terms
      termsVersion
      termsAgreedAt
      createdAt
      isDeleted
      roleName
      config
      licenseNumber
      licensePendingReviewFlag
      updatedAt
      __typename
    }
    roleName
    userName
    isDeleted
    testFlag
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserRoleSubscriptionVariables,
  APITypes.OnCreateUserRoleSubscription
>;
export const onUpdateUserRole = /* GraphQL */ `subscription OnUpdateUserRole($filter: ModelSubscriptionUserRoleFilterInput) {
  onUpdateUserRole(filter: $filter) {
    id
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    config
    clinicName
    status
    email
    phone
    userID
    user {
      id
      email
      username
      phone
      terms
      termsVersion
      termsAgreedAt
      createdAt
      isDeleted
      roleName
      config
      licenseNumber
      licensePendingReviewFlag
      updatedAt
      __typename
    }
    roleName
    userName
    isDeleted
    testFlag
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserRoleSubscriptionVariables,
  APITypes.OnUpdateUserRoleSubscription
>;
export const onDeleteUserRole = /* GraphQL */ `subscription OnDeleteUserRole($filter: ModelSubscriptionUserRoleFilterInput) {
  onDeleteUserRole(filter: $filter) {
    id
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    config
    clinicName
    status
    email
    phone
    userID
    user {
      id
      email
      username
      phone
      terms
      termsVersion
      termsAgreedAt
      createdAt
      isDeleted
      roleName
      config
      licenseNumber
      licensePendingReviewFlag
      updatedAt
      __typename
    }
    roleName
    userName
    isDeleted
    testFlag
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserRoleSubscriptionVariables,
  APITypes.OnDeleteUserRoleSubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
  onCreateUser(filter: $filter) {
    id
    email
    username
    phone
    terms
    termsVersion
    termsAgreedAt
    createdAt
    isDeleted
    roleName
    config
    licenseNumber
    licensePendingReviewFlag
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
  onUpdateUser(filter: $filter) {
    id
    email
    username
    phone
    terms
    termsVersion
    termsAgreedAt
    createdAt
    isDeleted
    roleName
    config
    licenseNumber
    licensePendingReviewFlag
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
  onDeleteUser(filter: $filter) {
    id
    email
    username
    phone
    terms
    termsVersion
    termsAgreedAt
    createdAt
    isDeleted
    roleName
    config
    licenseNumber
    licensePendingReviewFlag
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateUserPool = /* GraphQL */ `subscription OnCreateUserPool($filter: ModelSubscriptionUserPoolFilterInput) {
  onCreateUserPool(filter: $filter) {
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserPoolSubscriptionVariables,
  APITypes.OnCreateUserPoolSubscription
>;
export const onUpdateUserPool = /* GraphQL */ `subscription OnUpdateUserPool($filter: ModelSubscriptionUserPoolFilterInput) {
  onUpdateUserPool(filter: $filter) {
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserPoolSubscriptionVariables,
  APITypes.OnUpdateUserPoolSubscription
>;
export const onDeleteUserPool = /* GraphQL */ `subscription OnDeleteUserPool($filter: ModelSubscriptionUserPoolFilterInput) {
  onDeleteUserPool(filter: $filter) {
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserPoolSubscriptionVariables,
  APITypes.OnDeleteUserPoolSubscription
>;
export const onCreateRole = /* GraphQL */ `subscription OnCreateRole($filter: ModelSubscriptionRoleFilterInput) {
  onCreateRole(filter: $filter) {
    id
    clinicID
    roleName
    permisstions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRoleSubscriptionVariables,
  APITypes.OnCreateRoleSubscription
>;
export const onUpdateRole = /* GraphQL */ `subscription OnUpdateRole($filter: ModelSubscriptionRoleFilterInput) {
  onUpdateRole(filter: $filter) {
    id
    clinicID
    roleName
    permisstions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRoleSubscriptionVariables,
  APITypes.OnUpdateRoleSubscription
>;
export const onDeleteRole = /* GraphQL */ `subscription OnDeleteRole($filter: ModelSubscriptionRoleFilterInput) {
  onDeleteRole(filter: $filter) {
    id
    clinicID
    roleName
    permisstions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRoleSubscriptionVariables,
  APITypes.OnDeleteRoleSubscription
>;
export const onCreateClinicsForPromotion = /* GraphQL */ `subscription OnCreateClinicsForPromotion(
  $filter: ModelSubscriptionClinicsForPromotionFilterInput
) {
  onCreateClinicsForPromotion(filter: $filter) {
    id
    promotionType
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateClinicsForPromotionSubscriptionVariables,
  APITypes.OnCreateClinicsForPromotionSubscription
>;
export const onUpdateClinicsForPromotion = /* GraphQL */ `subscription OnUpdateClinicsForPromotion(
  $filter: ModelSubscriptionClinicsForPromotionFilterInput
) {
  onUpdateClinicsForPromotion(filter: $filter) {
    id
    promotionType
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateClinicsForPromotionSubscriptionVariables,
  APITypes.OnUpdateClinicsForPromotionSubscription
>;
export const onDeleteClinicsForPromotion = /* GraphQL */ `subscription OnDeleteClinicsForPromotion(
  $filter: ModelSubscriptionClinicsForPromotionFilterInput
) {
  onDeleteClinicsForPromotion(filter: $filter) {
    id
    promotionType
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteClinicsForPromotionSubscriptionVariables,
  APITypes.OnDeleteClinicsForPromotionSubscription
>;
export const onCreateClinic = /* GraphQL */ `subscription OnCreateClinic($filter: ModelSubscriptionClinicFilterInput) {
  onCreateClinic(filter: $filter) {
    id
    clinicName
    doctorName
    doctorNumber
    phone
    fax
    medicalInstitutionNumber
    prescriptionValidityPeriod
    address
    feedbackDate
    isDeleted
    pricingInfo {
      currentPricingId
      nextPricingId
      __typename
    }
    stepPayID
    numberOfPatients
    numberOfDoctors
    numberOfSteps
    clinicEmail
    updatedAt
    planInfo
    planCheckPassFlag
    createdAt
    typename
    config
    businessType
    promotion
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateClinicSubscriptionVariables,
  APITypes.OnCreateClinicSubscription
>;
export const onUpdateClinic = /* GraphQL */ `subscription OnUpdateClinic($filter: ModelSubscriptionClinicFilterInput) {
  onUpdateClinic(filter: $filter) {
    id
    clinicName
    doctorName
    doctorNumber
    phone
    fax
    medicalInstitutionNumber
    prescriptionValidityPeriod
    address
    feedbackDate
    isDeleted
    pricingInfo {
      currentPricingId
      nextPricingId
      __typename
    }
    stepPayID
    numberOfPatients
    numberOfDoctors
    numberOfSteps
    clinicEmail
    updatedAt
    planInfo
    planCheckPassFlag
    createdAt
    typename
    config
    businessType
    promotion
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateClinicSubscriptionVariables,
  APITypes.OnUpdateClinicSubscription
>;
export const onDeleteClinic = /* GraphQL */ `subscription OnDeleteClinic($filter: ModelSubscriptionClinicFilterInput) {
  onDeleteClinic(filter: $filter) {
    id
    clinicName
    doctorName
    doctorNumber
    phone
    fax
    medicalInstitutionNumber
    prescriptionValidityPeriod
    address
    feedbackDate
    isDeleted
    pricingInfo {
      currentPricingId
      nextPricingId
      __typename
    }
    stepPayID
    numberOfPatients
    numberOfDoctors
    numberOfSteps
    clinicEmail
    updatedAt
    planInfo
    planCheckPassFlag
    createdAt
    typename
    config
    businessType
    promotion
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteClinicSubscriptionVariables,
  APITypes.OnDeleteClinicSubscription
>;
export const onCreateTreatment = /* GraphQL */ `subscription OnCreateTreatment($filter: ModelSubscriptionTreatmentFilterInput) {
  onCreateTreatment(filter: $filter) {
    id
    clinicID
    surveyID
    patientID
    patientData {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    save
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    feedbackID
    mainSymptom
    userID
    prescribedHerb {
      code
      amount
      name
      __typename
    }
    status
    cure
    memo
    lastVisitedAt
    selectSymptom {
      code
      check
      __typename
    }
    symptomCheck
    name
    birth
    gender
    prescriptionStatus
    updatedAt
    createdAt
    amount
    isVisited
    isDeleted
    surveyCheck
    herbsForRecommanded
    treatmentedAt
    treatmentStatus
    patientNameForSearching
    selectedBCode
    prescriptionMode
    prescribedRecipe {
      pc
      amt
      __typename
    }
    prescriptionId
    prescriptionData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    customHerbalFormulaId
    customHerbalFormulaData {
      id
      ownerUserID
      originalHerbalFormulaID
      originalHerbalFormulaData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    prescriptionDocument
    footprintsLog
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTreatmentSubscriptionVariables,
  APITypes.OnCreateTreatmentSubscription
>;
export const onUpdateTreatment = /* GraphQL */ `subscription OnUpdateTreatment($filter: ModelSubscriptionTreatmentFilterInput) {
  onUpdateTreatment(filter: $filter) {
    id
    clinicID
    surveyID
    patientID
    patientData {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    save
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    feedbackID
    mainSymptom
    userID
    prescribedHerb {
      code
      amount
      name
      __typename
    }
    status
    cure
    memo
    lastVisitedAt
    selectSymptom {
      code
      check
      __typename
    }
    symptomCheck
    name
    birth
    gender
    prescriptionStatus
    updatedAt
    createdAt
    amount
    isVisited
    isDeleted
    surveyCheck
    herbsForRecommanded
    treatmentedAt
    treatmentStatus
    patientNameForSearching
    selectedBCode
    prescriptionMode
    prescribedRecipe {
      pc
      amt
      __typename
    }
    prescriptionId
    prescriptionData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    customHerbalFormulaId
    customHerbalFormulaData {
      id
      ownerUserID
      originalHerbalFormulaID
      originalHerbalFormulaData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    prescriptionDocument
    footprintsLog
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTreatmentSubscriptionVariables,
  APITypes.OnUpdateTreatmentSubscription
>;
export const onDeleteTreatment = /* GraphQL */ `subscription OnDeleteTreatment($filter: ModelSubscriptionTreatmentFilterInput) {
  onDeleteTreatment(filter: $filter) {
    id
    clinicID
    surveyID
    patientID
    patientData {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    save
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    feedbackID
    mainSymptom
    userID
    prescribedHerb {
      code
      amount
      name
      __typename
    }
    status
    cure
    memo
    lastVisitedAt
    selectSymptom {
      code
      check
      __typename
    }
    symptomCheck
    name
    birth
    gender
    prescriptionStatus
    updatedAt
    createdAt
    amount
    isVisited
    isDeleted
    surveyCheck
    herbsForRecommanded
    treatmentedAt
    treatmentStatus
    patientNameForSearching
    selectedBCode
    prescriptionMode
    prescribedRecipe {
      pc
      amt
      __typename
    }
    prescriptionId
    prescriptionData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    customHerbalFormulaId
    customHerbalFormulaData {
      id
      ownerUserID
      originalHerbalFormulaID
      originalHerbalFormulaData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    prescriptionDocument
    footprintsLog
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTreatmentSubscriptionVariables,
  APITypes.OnDeleteTreatmentSubscription
>;
export const onCreatePrescriptionHisory = /* GraphQL */ `subscription OnCreatePrescriptionHisory(
  $filter: ModelSubscriptionPrescriptionHisoryFilterInput
) {
  onCreatePrescriptionHisory(filter: $filter) {
    id
    treatmentID
    prescribedRecipe {
      pc
      amt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePrescriptionHisorySubscriptionVariables,
  APITypes.OnCreatePrescriptionHisorySubscription
>;
export const onUpdatePrescriptionHisory = /* GraphQL */ `subscription OnUpdatePrescriptionHisory(
  $filter: ModelSubscriptionPrescriptionHisoryFilterInput
) {
  onUpdatePrescriptionHisory(filter: $filter) {
    id
    treatmentID
    prescribedRecipe {
      pc
      amt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePrescriptionHisorySubscriptionVariables,
  APITypes.OnUpdatePrescriptionHisorySubscription
>;
export const onDeletePrescriptionHisory = /* GraphQL */ `subscription OnDeletePrescriptionHisory(
  $filter: ModelSubscriptionPrescriptionHisoryFilterInput
) {
  onDeletePrescriptionHisory(filter: $filter) {
    id
    treatmentID
    prescribedRecipe {
      pc
      amt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePrescriptionHisorySubscriptionVariables,
  APITypes.OnDeletePrescriptionHisorySubscription
>;
export const onCreateFormula = /* GraphQL */ `subscription OnCreateFormula($filter: ModelSubscriptionFormulaFilterInput) {
  onCreateFormula(filter: $filter) {
    id
    formulaName
    chineseName
    herbs
    amount
    primaryCare
    tag
    category
    priority
    gun
    sin
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFormulaSubscriptionVariables,
  APITypes.OnCreateFormulaSubscription
>;
export const onUpdateFormula = /* GraphQL */ `subscription OnUpdateFormula($filter: ModelSubscriptionFormulaFilterInput) {
  onUpdateFormula(filter: $filter) {
    id
    formulaName
    chineseName
    herbs
    amount
    primaryCare
    tag
    category
    priority
    gun
    sin
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFormulaSubscriptionVariables,
  APITypes.OnUpdateFormulaSubscription
>;
export const onDeleteFormula = /* GraphQL */ `subscription OnDeleteFormula($filter: ModelSubscriptionFormulaFilterInput) {
  onDeleteFormula(filter: $filter) {
    id
    formulaName
    chineseName
    herbs
    amount
    primaryCare
    tag
    category
    priority
    gun
    sin
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFormulaSubscriptionVariables,
  APITypes.OnDeleteFormulaSubscription
>;
export const onCreateBiztalkHistory = /* GraphQL */ `subscription OnCreateBiztalkHistory(
  $filter: ModelSubscriptionBiztalkHistoryFilterInput
) {
  onCreateBiztalkHistory(filter: $filter) {
    msgIdx
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBiztalkHistorySubscriptionVariables,
  APITypes.OnCreateBiztalkHistorySubscription
>;
export const onUpdateBiztalkHistory = /* GraphQL */ `subscription OnUpdateBiztalkHistory(
  $filter: ModelSubscriptionBiztalkHistoryFilterInput
) {
  onUpdateBiztalkHistory(filter: $filter) {
    msgIdx
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBiztalkHistorySubscriptionVariables,
  APITypes.OnUpdateBiztalkHistorySubscription
>;
export const onDeleteBiztalkHistory = /* GraphQL */ `subscription OnDeleteBiztalkHistory(
  $filter: ModelSubscriptionBiztalkHistoryFilterInput
) {
  onDeleteBiztalkHistory(filter: $filter) {
    msgIdx
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBiztalkHistorySubscriptionVariables,
  APITypes.OnDeleteBiztalkHistorySubscription
>;
export const onCreateYejinSignupRequest = /* GraphQL */ `subscription OnCreateYejinSignupRequest(
  $filter: ModelSubscriptionYejinSignupRequestFilterInput
) {
  onCreateYejinSignupRequest(filter: $filter) {
    id
    userName
    email
    clinicName
    clinicAddress
    clinicPhone
    terms
    termsVersion
    termsAgreedAt
    permission
    licenseNumber
    clinicEmail
    businessType
    createdAt
    approvedAt
    requestStatus
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateYejinSignupRequestSubscriptionVariables,
  APITypes.OnCreateYejinSignupRequestSubscription
>;
export const onUpdateYejinSignupRequest = /* GraphQL */ `subscription OnUpdateYejinSignupRequest(
  $filter: ModelSubscriptionYejinSignupRequestFilterInput
) {
  onUpdateYejinSignupRequest(filter: $filter) {
    id
    userName
    email
    clinicName
    clinicAddress
    clinicPhone
    terms
    termsVersion
    termsAgreedAt
    permission
    licenseNumber
    clinicEmail
    businessType
    createdAt
    approvedAt
    requestStatus
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateYejinSignupRequestSubscriptionVariables,
  APITypes.OnUpdateYejinSignupRequestSubscription
>;
export const onDeleteYejinSignupRequest = /* GraphQL */ `subscription OnDeleteYejinSignupRequest(
  $filter: ModelSubscriptionYejinSignupRequestFilterInput
) {
  onDeleteYejinSignupRequest(filter: $filter) {
    id
    userName
    email
    clinicName
    clinicAddress
    clinicPhone
    terms
    termsVersion
    termsAgreedAt
    permission
    licenseNumber
    clinicEmail
    businessType
    createdAt
    approvedAt
    requestStatus
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteYejinSignupRequestSubscriptionVariables,
  APITypes.OnDeleteYejinSignupRequestSubscription
>;
export const onCreateReport = /* GraphQL */ `subscription OnCreateReport($filter: ModelSubscriptionReportFilterInput) {
  onCreateReport(filter: $filter) {
    treatmentID
    sendCount
    byeonjeung
    startedAt
    birth
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReportSubscriptionVariables,
  APITypes.OnCreateReportSubscription
>;
export const onUpdateReport = /* GraphQL */ `subscription OnUpdateReport($filter: ModelSubscriptionReportFilterInput) {
  onUpdateReport(filter: $filter) {
    treatmentID
    sendCount
    byeonjeung
    startedAt
    birth
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReportSubscriptionVariables,
  APITypes.OnUpdateReportSubscription
>;
export const onDeleteReport = /* GraphQL */ `subscription OnDeleteReport($filter: ModelSubscriptionReportFilterInput) {
  onDeleteReport(filter: $filter) {
    treatmentID
    sendCount
    byeonjeung
    startedAt
    birth
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReportSubscriptionVariables,
  APITypes.OnDeleteReportSubscription
>;
export const onCreateSelfRegistrationQR = /* GraphQL */ `subscription OnCreateSelfRegistrationQR(
  $filter: ModelSubscriptionSelfRegistrationQRFilterInput
) {
  onCreateSelfRegistrationQR(filter: $filter) {
    QRID
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    expiredDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSelfRegistrationQRSubscriptionVariables,
  APITypes.OnCreateSelfRegistrationQRSubscription
>;
export const onUpdateSelfRegistrationQR = /* GraphQL */ `subscription OnUpdateSelfRegistrationQR(
  $filter: ModelSubscriptionSelfRegistrationQRFilterInput
) {
  onUpdateSelfRegistrationQR(filter: $filter) {
    QRID
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    expiredDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSelfRegistrationQRSubscriptionVariables,
  APITypes.OnUpdateSelfRegistrationQRSubscription
>;
export const onDeleteSelfRegistrationQR = /* GraphQL */ `subscription OnDeleteSelfRegistrationQR(
  $filter: ModelSubscriptionSelfRegistrationQRFilterInput
) {
  onDeleteSelfRegistrationQR(filter: $filter) {
    QRID
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    expiredDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSelfRegistrationQRSubscriptionVariables,
  APITypes.OnDeleteSelfRegistrationQRSubscription
>;
export const onCreateDailySignupCount = /* GraphQL */ `subscription OnCreateDailySignupCount(
  $filter: ModelSubscriptionDailySignupCountFilterInput
) {
  onCreateDailySignupCount(filter: $filter) {
    date
    count
    memo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDailySignupCountSubscriptionVariables,
  APITypes.OnCreateDailySignupCountSubscription
>;
export const onUpdateDailySignupCount = /* GraphQL */ `subscription OnUpdateDailySignupCount(
  $filter: ModelSubscriptionDailySignupCountFilterInput
) {
  onUpdateDailySignupCount(filter: $filter) {
    date
    count
    memo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDailySignupCountSubscriptionVariables,
  APITypes.OnUpdateDailySignupCountSubscription
>;
export const onDeleteDailySignupCount = /* GraphQL */ `subscription OnDeleteDailySignupCount(
  $filter: ModelSubscriptionDailySignupCountFilterInput
) {
  onDeleteDailySignupCount(filter: $filter) {
    date
    count
    memo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDailySignupCountSubscriptionVariables,
  APITypes.OnDeleteDailySignupCountSubscription
>;
export const onCreateDailyPatientCount = /* GraphQL */ `subscription OnCreateDailyPatientCount(
  $filter: ModelSubscriptionDailyPatientCountFilterInput
) {
  onCreateDailyPatientCount(filter: $filter) {
    date
    count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDailyPatientCountSubscriptionVariables,
  APITypes.OnCreateDailyPatientCountSubscription
>;
export const onUpdateDailyPatientCount = /* GraphQL */ `subscription OnUpdateDailyPatientCount(
  $filter: ModelSubscriptionDailyPatientCountFilterInput
) {
  onUpdateDailyPatientCount(filter: $filter) {
    date
    count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDailyPatientCountSubscriptionVariables,
  APITypes.OnUpdateDailyPatientCountSubscription
>;
export const onDeleteDailyPatientCount = /* GraphQL */ `subscription OnDeleteDailyPatientCount(
  $filter: ModelSubscriptionDailyPatientCountFilterInput
) {
  onDeleteDailyPatientCount(filter: $filter) {
    date
    count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDailyPatientCountSubscriptionVariables,
  APITypes.OnDeleteDailyPatientCountSubscription
>;
