/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import message from 'antd-message';
import moment from 'moment';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { Row, Col, TypographyText, ModalWith3Button, ModalType2 } from 'components/UI/atoms';
import { useStores } from 'stores/Context';
import CommonPrescriptionOneColumnTable from 'components/UI/molecules/table/CommonPrescriptionOneColumnTable';
import PrescriptionRepository from 'repository/PrescriptionRepository';
import SymptomTable from 'components/UI/molecules/table/commonSymptomTable';
import { selectSurvey } from 'utils/util';

import HerbTd from './HerbTd';
import PrescriptionPart from './PrescriptionPart';
import PrescribedHerbTable from './PrescribedHerbTable';
import TreatmentModeSelect from './TreatmentModeSelect';

const TreatmentPrescription = ({
  formulaSearchKeyword,
  setFormulaSearchKeyword,
  selectedProcessingCodes,
  setSelectedProcessingCodes,
  herbSearchKeyword,
  setHerbSearchKeyword,
  setZeroScoringTypeprescription,
  zeroScoringTypeprescription,
  prescribedHerbs,
  setPrescribedHerbs,
  selectedPrescriptionData,
  setSelectedPrescriptionData,
  selectedMyPrescriptionData,
  setSelectedMyPrescriptionData,
  prescriptionCount,
  setPrescriptionCount,
  setTreatmentMode,
  treatmentMode,
  updateFootprintsLog,
}) => {
  const { symptomStore, surveyStore, prescriptionStore, treatmentStore, clinicStore } = useStores();

  const isMounted = useRef(false);
  const [recommandedPrescriptionArr, setRecommandedPrescriptionArr] = useState<any>([]);
  const [recommandedHerbArr, setRecommandedHerbArr] = useState<any>([]);
  const [recommendedFormulaTab, setRecommendedFormulaTab] = useState<
    '고방' | '후세방' | '고유' | '사상' | '변증'
  >([undefined, 'GENERAL'].includes(treatmentMode) ? '고방' : '고유');
  const [isLoadingPresciptionList, setIsLoadingPresciptionList] = useState(true);
  const [isLoadingPresciptionListForZeroScoring, setIsLoadingPresciptionListForZeroScoring] = useState(false);
  const [isLoadingHerbList, setIsLoadingHerbList] = useState(true);
  const [needConfirmationHerbIds, setNeedConfirmationHerbIds] = useState([]);
  const [prescribedHerbTableScrollTrigger, setPrescribedHerbTableScrollTrigger] = useState(false);

  const [isOpenAddHerbMethodModal, setIsOpenAddHerbMethodModal] = useState(false);
  const [pmData, setIsOpenPrescriptionInfoModal] = useState<any>({});
  const [needConfirmationHerbsTemp, setNeedConfirmationHerbsTemp] = useState({});
  const [dontNeedConfirmationHerbsTemp, setDontNeedConfirmationHerbsTemp] = useState({});
  const [isOpenPrescriptionHistoryModal, setIsOpenPrescriptionHistoryModal] = useState({
    isOpen: false,
    hiddenFlag: false,
  });
  const [isOpenMyPrescriptionListModal, setIsOpenMyPrescriptionListModal] = useState({
    isOpen: false,
    hiddenFlag: false,
  });
  const [isOpenSymptomInfoModal, setIsOpenSymptomInfoModal] = useState(false);
  const [isShowTreatmentInsuranceMode, setIsShowTreatmentInsuranceMode] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const mode = searchParams.get('mode');

  useEffect(() => {
    if (!_.isEmpty(clinicStore.clinic)) {
      const clinicConfig = JSON.parse(clinicStore.clinic?.config ?? '{}');
      if (clinicConfig.insuranceBusinessJoinFlag) {
        setIsShowTreatmentInsuranceMode(true);
      }
    }
  }, [clinicStore.clinic]);

  useEffect(() => {
    // treatmentMode 변경 시
    if ([undefined, 'GENERAL'].includes(treatmentMode)) {
      // 비급여 default 탭
      setRecommendedFormulaTab('고방');
    } else {
      // 급여 default 탭
      setRecommendedFormulaTab('고유');
    }
  }, [treatmentMode]);

  const suggestHerbCodeMap = useMemo(() => {
    const suggestHerbCodeMapTemp = new Map(
      [...(treatmentStore.treatment?.selectSymptom || [])].map((e) => [e.code, e]),
    );
    _.forEach(
      treatmentStore.selectedBCode,
      (e: { code: string; relativeSCode: { code: string; contribution: number }[] }) => {
        _.forEach(e.relativeSCode, (sData) => {
          if (suggestHerbCodeMapTemp.has(sData.code)) {
            suggestHerbCodeMapTemp.set(sData.code, {
              ...suggestHerbCodeMapTemp.get(sData.code),
              bScore:
                (suggestHerbCodeMapTemp.get(sData.code).bScore ?? 0) + _.round(sData.contribution ?? 0) / 100,
            });
          }
        });
      },
    );
    return suggestHerbCodeMapTemp;
  }, []);

  const recommend = useCallback(
    async ({
      suggestHerbCodeMap,
      subMode,
      herbCodeArrayforSearch = [],
      insuranceMode,
      insuranceDiseaseCode,
      processingCodeArrayforSearch = [],
      formulaSearchKeyword = '',
      prescriptionCount,
    }: {
      suggestHerbCodeMap: Map<string, any>;
      subMode: 'go' | 'hu' | 'all' | '고유' | '사상' | '변증';
      herbCodeArrayforSearch?: string[];
      insuranceMode: string;
      insuranceDiseaseCode: string;
      processingCodeArrayforSearch?: string[];
      formulaSearchKeyword: string;
      prescriptionCount: number;
    }) => {
      try {
        setIsLoadingPresciptionList(true);
        if (subMode === 'all') {
          setIsLoadingHerbList(true);
        }
        const res = await symptomStore.getRecommendation({
          symptoms: Array.from(suggestHerbCodeMap.values()),
          mode: 'herb',
          subMode,
          insuranceMode,
          insuranceDiseaseCode,
          herbCodeArrayforSearch,
          processingCodeArrayforSearch,
          queryParams: {
            prescriptionCount,
            gunBaseScore: '1.5',
            sinBaseScore: '1.3',
            generalHerbBaseScore: '1',
            formulaSearchKeyword,
          },
        });
        if (!isMounted.current) {
          if (!_.isEmpty(res.recommenededHerbs)) {
            setRecommandedHerbArr(res.recommenededHerbs);
            setIsLoadingHerbList(false);
          }
          isMounted.current = true;
        }
        if (!_.isEmpty(res.recommenededPrescriptions)) {
          setRecommandedPrescriptionArr(res.recommenededPrescriptions);
          setIsLoadingPresciptionList(false);
        }
      } catch (e) {
        message.error(e, 2500);
      } finally {
        setIsLoadingPresciptionList(false);
        setIsLoadingHerbList(false);
      }
    },
    [isMounted.current, treatmentMode],
  );

  useEffect(() => {
    if (mode !== 'prescriptionEdit') {
      updateFootprintsLog('P');
    }
    surveyStore.getSurvey(treatmentStore.treatment?.surveyID!);
    symptomStore.setSymptoms(surveyStore.getSymptomCode());
    treatmentStore.treatmentPatientByDate({ patientID: treatmentStore.treatment?.patientID! });
  }, []);

  const listHerbalFormulaByscoringType = useCallback(
    async ({ prescriptionSearchKeyword, processingCodes }) => {
      setIsLoadingPresciptionListForZeroScoring(true);
      const res = await PrescriptionRepository.listHerbalFormulaByscoringType({
        prescriptionSearchKeyword,
        selectedProcessingCodes: processingCodes,
        scoringType: 0,
      });
      setZeroScoringTypeprescription(res.items);
      setIsLoadingPresciptionListForZeroScoring(false);
    },
    [],
  );

  useEffect(() => {
    if (formulaSearchKeyword || selectedProcessingCodes.length) {
      listHerbalFormulaByscoringType({
        prescriptionSearchKeyword: formulaSearchKeyword,
        processingCodes: selectedProcessingCodes,
      });
    } else {
      setZeroScoringTypeprescription([]);
    }

    recommend({
      suggestHerbCodeMap,
      subMode: isMounted.current ? (recommendedFormulaTab === '고방' ? 'go' : 'hu') : 'all',
      insuranceMode: [undefined, 'GENERAL'].includes(treatmentMode) ? '비급여' : '급여',
      insuranceDiseaseCode: treatmentMode,
      processingCodeArrayforSearch: selectedProcessingCodes,
      formulaSearchKeyword,
      prescriptionCount: 30,
    });
  }, [selectedProcessingCodes, formulaSearchKeyword, treatmentMode]);

  return (
    <>
      <Row
        style={{
          display: 'flex',
          height: '100%',
        }}
      >
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '1.25rem',
            height: '100%',
            flex: 6,
          }}
        >
          <Row
            style={{
              display: 'flex',
              flexShrink: 0,
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              height: '4.2rem',
              paddingBottom: '0.75rem',
            }}
          >
            <TypographyText
              fontSize="1.2rem"
              lineHeight="1.2em"
              fontWeight={700}
              style={{ whiteSpace: 'nowrap' }}
            >
              추천
            </TypographyText>
          </Row>
          <Row
            style={{
              display: 'flex',
              flex: 1,
              columnGap: '1.25rem',
            }}
          >
            <Col
              style={{
                position: 'relative',
                height: '100%',
                display: 'flex',
                flex: 35,
                flexDirection: 'column',
              }}
            >
              {isShowTreatmentInsuranceMode && (
                <Row
                  style={{
                    display: 'flex',
                    zIndex: 1,
                    position: 'absolute',
                    top: '-2.5rem',
                    left: '2.75rem',
                  }}
                >
                  <TreatmentModeSelect
                    setSelectedOptionValue={setTreatmentMode}
                    selectedOptionValue={treatmentMode}
                  />
                </Row>
              )}
              <PrescriptionPart
                treatmentMode={treatmentMode}
                formulaSearchKeyword={formulaSearchKeyword}
                setFormulaSearchKeyword={setFormulaSearchKeyword}
                selectedProcessingCodes={selectedProcessingCodes}
                setSelectedProcessingCodes={setSelectedProcessingCodes}
                zeroScoringTypeprescription={zeroScoringTypeprescription}
                prescribedHerbs={prescribedHerbs}
                setPrescribedHerbs={setPrescribedHerbs}
                selectedPrescriptionData={selectedPrescriptionData}
                setSelectedPrescriptionData={setSelectedPrescriptionData}
                setSelectedMyPrescriptionData={setSelectedMyPrescriptionData}
                recommandedPrescriptionArr={recommandedPrescriptionArr}
                isLoadingPresciptionList={isLoadingPresciptionList}
                isLoadingPresciptionListForZeroScoring={isLoadingPresciptionListForZeroScoring}
                recommendedFormulaTab={recommendedFormulaTab}
                setRecommendedFormulaTab={setRecommendedFormulaTab}
                setIsOpenAddHerbMethodModal={setIsOpenAddHerbMethodModal}
                pmData={pmData}
                setIsOpenPrescriptionInfoModal={setIsOpenPrescriptionInfoModal}
                setNeedConfirmationHerbsTemp={setNeedConfirmationHerbsTemp}
                setDontNeedConfirmationHerbsTemp={setDontNeedConfirmationHerbsTemp}
                setPrescribedHerbTableScrollTrigger={setPrescribedHerbTableScrollTrigger}
              />
            </Col>
            <Col
              style={{
                position: 'relative',
                height: '100%',
                display: 'flex',
                flex: 25,
                flexDirection: 'column',
              }}
            >
              <Row
                style={{
                  display: 'flex',
                  zIndex: 1,
                  position: 'absolute',
                  top: '-2.5rem',
                  right: '0rem',
                }}
              >
                <Col
                  className="treatment-detail-button"
                  style={{
                    paddingLeft: '0.75rem',
                    paddingRight: '0.65rem',
                    height: '2rem',
                    cursor: 'pointer',
                    borderRadius: '3px',
                    background: '#EEEFF3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    columnGap: '0.4rem',
                  }}
                  onClick={() => setIsOpenSymptomInfoModal(true)}
                >
                  <TypographyText fontSize="0.8rem" fontWeight={700} lineHeight="0.8rem" whiteSpace="pre">
                    설문 확인
                  </TypographyText>
                  <Row
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      flexShrink: 0,
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/search-black-icon.webp`}
                      style={{ width: '0.85rem' }}
                      alt="search_icon"
                    />
                  </Row>
                </Col>
              </Row>
              <CommonPrescriptionOneColumnTable
                searchComponentFlag
                defaultSearchValue={herbSearchKeyword}
                searchComponentFunc={(value) => setHerbSearchKeyword(value)}
                searchInputPlaceholder={'약재명으로 검색 후 엔터를 눌러주세요.'}
                thName={`약재`}
                emptyText="추천약재가 없습니다."
                height={'100%'}
                loading={isLoadingHerbList}
                items={_.sortBy(
                  _.sortBy(
                    _.flatten(
                      (herbSearchKeyword
                        ? prescriptionStore.flatYejinHerbList.filter((e) =>
                            e.knForSearch.split('||').some((kn) => kn.includes(herbSearchKeyword)),
                          )
                        : prescriptionStore.flatYejinHerbList
                      ).map((herb) => ({
                        ...herb,
                        score: herb.basicHerbFlag
                          ? recommandedHerbArr.find((ha) => ha.code === herb.herbCode)?.score
                          : 0,
                      })),
                    ),
                    ['koreanName'],
                  ),
                  (e: any) => -e.score,
                ).map((e) => {
                  return {
                    component: (
                      <HerbTd
                        herbSearchKeyword={herbSearchKeyword}
                        prescribedHerbs={prescribedHerbs}
                        setPrescribedHerbs={setPrescribedHerbs}
                        processedHerbData={e}
                        selectedProcessingCodes={selectedProcessingCodes}
                        setSelectedProcessingCodes={setSelectedProcessingCodes}
                        setPrescribedHerbTableScrollTrigger={setPrescribedHerbTableScrollTrigger}
                      />
                    ),
                  };
                })}
              />
            </Col>
          </Row>
        </Col>
        <Col
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            borderLeft: '2px solid #E5E5E5',
            flex: 4,
            paddingLeft: '1.25rem',
          }}
        >
          <PrescribedHerbTable
            treatmentMode={treatmentMode}
            setSelectedPrescriptionData={setSelectedPrescriptionData}
            selectedMyPrescriptionData={selectedMyPrescriptionData}
            setSelectedMyPrescriptionData={setSelectedMyPrescriptionData}
            prescribedHerbs={prescribedHerbs}
            setPrescribedHerbs={setPrescribedHerbs}
            selectedPrescriptionData={selectedPrescriptionData}
            needConfirmationHerbIds={needConfirmationHerbIds}
            setNeedConfirmationHerbIds={setNeedConfirmationHerbIds}
            prescriptionCount={prescriptionCount}
            setPrescriptionCount={setPrescriptionCount}
            prescribedHerbTableScrollTrigger={prescribedHerbTableScrollTrigger}
            setPrescribedHerbTableScrollTrigger={setPrescribedHerbTableScrollTrigger}
            setIsOpenAddHerbMethodModal={setIsOpenAddHerbMethodModal}
            setIsOpenPrescriptionInfoModal={setIsOpenPrescriptionInfoModal}
            setNeedConfirmationHerbsTemp={setNeedConfirmationHerbsTemp}
            setDontNeedConfirmationHerbsTemp={setDontNeedConfirmationHerbsTemp}
            isOpenPrescriptionHistoryModal={isOpenPrescriptionHistoryModal}
            setIsOpenPrescriptionHistoryModal={setIsOpenPrescriptionHistoryModal}
            isOpenMyPrescriptionListModal={isOpenMyPrescriptionListModal}
            setIsOpenMyPrescriptionListModal={setIsOpenMyPrescriptionListModal}
          />
        </Col>
      </Row>
      <ModalWith3Button // 방제사전 모달, +버튼, 처방기록, 나의처방
        isOpen={isOpenAddHerbMethodModal}
        button1Msg="취소"
        button1Color="white"
        button1WidthRatio={33}
        button1Function={() => {
          if (isOpenMyPrescriptionListModal.hiddenFlag) {
            setIsOpenMyPrescriptionListModal({
              isOpen: true,
              hiddenFlag: false,
            });
          } else if (isOpenPrescriptionHistoryModal.hiddenFlag) {
            setIsOpenPrescriptionHistoryModal({
              isOpen: true,
              hiddenFlag: false,
            });
          } else if (!_.isEmpty(pmData)) {
            // 방제사전 모달이 켜져있던 상태라면
            if (pmData.from === 'plusButton') {
              setIsOpenPrescriptionInfoModal({});
            } else {
              setIsOpenPrescriptionInfoModal({
                ...pmData,
                hiddenModalFlag: false,
              });
            }
          }
          setDontNeedConfirmationHerbsTemp({});
          setNeedConfirmationHerbsTemp({});
          setIsOpenAddHerbMethodModal(false);
        }}
        button2Msg="합방"
        button2Color="blue"
        button2WidthRatio={33}
        button2Function={() => {
          setIsOpenAddHerbMethodModal(false);
          setPrescribedHerbs({
            ...prescribedHerbs,
            ...dontNeedConfirmationHerbsTemp,
          });
          setDontNeedConfirmationHerbsTemp({});
          setNeedConfirmationHerbsTemp({});
          setNeedConfirmationHerbIds(_.uniq(_.values(needConfirmationHerbsTemp).map((e: any) => e.code)));
          setIsOpenPrescriptionHistoryModal({
            isOpen: false,
            hiddenFlag: false,
          });
          setPrescribedHerbTableScrollTrigger(true);
          if (_.isEmpty(selectedPrescriptionData)) {
            // 기존에 선택된 처방 데이터가 없는 상태
            if (!_.isEmpty(pmData?.customHerbalFormulaData)) {
              // 이번에 선택한 나의 처방 데이터가 있는 경우
              setSelectedMyPrescriptionData(pmData.customHerbalFormulaData);
            }
            setSelectedPrescriptionData(pmData);
          }

          if (!_.isEmpty(pmData)) {
            // 방제사전 모달이 켜져있던 상태라면
            message.success(`<${pmData.kn}> 약제가 추가되었습니다.`, 2500);
            setIsOpenPrescriptionInfoModal({});
          } else {
            message.success(`약제가 추가되었습니다.`, 2500);
          }
        }}
        button3Msg="대체"
        button3Color="blue"
        button3WidthRatio={33}
        button3Function={() => {
          setIsOpenAddHerbMethodModal(false);
          setPrescribedHerbs({
            ...needConfirmationHerbsTemp,
            ...dontNeedConfirmationHerbsTemp,
          });
          setDontNeedConfirmationHerbsTemp({});
          setNeedConfirmationHerbsTemp({});
          setNeedConfirmationHerbIds([]);
          setSelectedPrescriptionData(pmData);
          if (!_.isEmpty(pmData?.customHerbalFormulaData)) {
            setSelectedMyPrescriptionData(pmData.customHerbalFormulaData);
          } else {
            setSelectedMyPrescriptionData({});
          }
          setIsOpenPrescriptionHistoryModal({
            isOpen: false,
            hiddenFlag: false,
          });
          setPrescribedHerbTableScrollTrigger(true);
          if (!_.isEmpty(pmData)) {
            // 방제사전 모달이 켜져있던 상태라면
            message.success(`<${pmData.kn}> 약제가 추가되었습니다.`, 2500);
            setIsOpenPrescriptionInfoModal({});
          } else {
            message.success(`약제가 추가되었습니다.`, 2500);
          }
        }}
        contentMsg={`약재 추가 방식을 선택해 주세요.`}
        additionalContent={
          _.isEmpty(needConfirmationHerbsTemp) ? (
            <></>
          ) : (
            <Row style={{ marginTop: '0.25rem', lineHeight: '0.95rem' }}>
              <TypographyText
                fontSize="0.8rem"
                lineHeight="1.2em"
                letterSpacing="-0.02em"
                color="#AFAFAF"
                style={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'keep-all',
                }}
              >
                {`*합방 시, 중복되는 약재는 추가되지 않으니 용량을 조절해 주세요.
(중복: ${_.values(needConfirmationHerbsTemp)
                  .map((e: any) => e.name)
                  .join(', ')})`}
              </TypographyText>
            </Row>
          )
        }
        contentHeight={_.isEmpty(needConfirmationHerbsTemp) ? 5 : 6.3}
      />
      <ModalType2
        isOpen={isOpenSymptomInfoModal}
        width={50}
        title={`${treatmentStore.treatment.name} 설문`}
        content={
          <>
            <Row style={{ flexShrink: 0, marginBottom: '2rem' }}>
              <TypographyText fontWeight={400} fontSize={'0.8rem'} color={'#AFAFAF'}>
                {moment(treatmentStore.treatment?.survey?.surveyedAt).format('YYYY/MM/DD HH:mm')} 설문입력
              </TypographyText>
            </Row>
            <SymptomTable
              treatment={treatmentStore.treatment}
              survey={treatmentStore.treatment?.survey ?? {}}
              symptoms={selectSurvey(treatmentStore.treatment?.survey?.symptoms!)}
              mode="treatmentSymptomReadOnlyForPrecriptionPage"
            />
          </>
        }
        cancelFunction={() => setIsOpenSymptomInfoModal(false)}
      />
    </>
  );
};
export default observer(TreatmentPrescription);
