import { Divider } from '@aws-amplify/ui-react';
import { css } from '@emotion/css';
import { ReactNode } from 'react';

import { Row, Col } from 'components/UI/atoms';
import TreatmentProgress from 'components/UI/organisms/treatment/TreatmentProgress';

type TreatmentContentLayoutProps = {
  tab: any;
  left: ReactNode;
  right?: ReactNode;
  leftWidthRatio?: number;
  leftContentPadding?: number;
  rightContentPadding?: number;
  rightContentRightPadding?: number;
  rightHeight?: string;
  isFullScreen?: boolean;
  barType?: string;
};

const TreatmentContentLayout = ({
  tab,
  left,
  right,
  leftWidthRatio = 50,
  leftContentPadding = 1.5,
  rightContentPadding = 2.4,
  rightContentRightPadding = 0,
  rightHeight,
  isFullScreen,
  barType = 'default',
}: TreatmentContentLayoutProps) => {
  return (
    <>
      {!isFullScreen && barType === 'default' && (
        <Row
          style={{
            position: 'relative',
            zIndex: 0,
            height: '3.4rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <TreatmentProgress progress={tab} />
        </Row>
      )}
      {!isFullScreen && barType !== 'default' && (
        <Row
          style={{
            position: 'relative',
            zIndex: 0,
            height: '1.25rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Row
            style={{
              position: 'absolute',
              bottom: 0,
              left: '-2.5%',
              width: '105%',
              borderBottom: '2px solid #e5e5e5',
            }}
          />
        </Row>
      )}
      <Row
        style={{
          display: 'flex',
          height: isFullScreen ? '100%' : 'calc(100% - 3.4rem)',
        }}
      >
        <Col
          style={{
            width: `${leftWidthRatio}%`,
            paddingRight: right ? `${leftContentPadding}%` : '0px',
            gap: 0,
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {left}
        </Col>
        {right && (
          <>
            <Divider
              orientation="vertical"
              className={css`
                border-color: #e5e5e5 !important;
              `}
            />
            <Col
              style={{
                width: `calc(100% - ${leftWidthRatio}%)`,
                paddingLeft: `${rightContentPadding}%`,
                paddingRight: `${rightContentRightPadding}%`,
                gap: 0,
                flexDirection: 'column',
                height: rightHeight ?? '100%',
                overflow: 'auto',
              }}
              className={css`
                overflow-x: hidden !important;
                &::-webkit-scrollbar {
                  background-color: white;
                  width: 0.25rem;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: #e5e5e5;
                  border-radius: 1rem;
                  cursor: pointer;
                }
              `}
            >
              {right}
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default TreatmentContentLayout;
