/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getHerb = /* GraphQL */ `query GetHerb($code: ID!) {
  getHerb(code: $code) {
    code
    herbName
    nameForSearching
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetHerbQueryVariables, APITypes.GetHerbQuery>;
export const listHerbs = /* GraphQL */ `query ListHerbs(
  $code: ID
  $filter: ModelHerbFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHerbs(
    code: $code
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      code
      herbName
      nameForSearching
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListHerbsQueryVariables, APITypes.ListHerbsQuery>;
export const getCustomHerbalFormula = /* GraphQL */ `query GetCustomHerbalFormula($id: ID!) {
  getCustomHerbalFormula(id: $id) {
    id
    ownerUserID
    originalHerbalFormulaID
    originalHerbalFormulaData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    scoringType
    memo
    isDeleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomHerbalFormulaQueryVariables,
  APITypes.GetCustomHerbalFormulaQuery
>;
export const listCustomHerbalFormulas = /* GraphQL */ `query ListCustomHerbalFormulas(
  $filter: ModelCustomHerbalFormulaFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomHerbalFormulas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ownerUserID
      originalHerbalFormulaID
      originalHerbalFormulaData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomHerbalFormulasQueryVariables,
  APITypes.ListCustomHerbalFormulasQuery
>;
export const customHerbalFormulaByOwnerUser = /* GraphQL */ `query CustomHerbalFormulaByOwnerUser(
  $ownerUserID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCustomHerbalFormulaFilterInput
  $limit: Int
  $nextToken: String
) {
  customHerbalFormulaByOwnerUser(
    ownerUserID: $ownerUserID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ownerUserID
      originalHerbalFormulaID
      originalHerbalFormulaData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CustomHerbalFormulaByOwnerUserQueryVariables,
  APITypes.CustomHerbalFormulaByOwnerUserQuery
>;
export const getHerbalFormula = /* GraphQL */ `query GetHerbalFormula($id: ID!) {
  getHerbalFormula(id: $id) {
    id
    category
    insrCCode
    insrDsCode
    InsrPrscrType
    kn
    cn
    herbCodes
    processingCodes
    recipe {
      pc
      amt
      __typename
    }
    gun
    sin
    scoringType
    source
    postfix
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHerbalFormulaQueryVariables,
  APITypes.GetHerbalFormulaQuery
>;
export const listHerbalFormulas = /* GraphQL */ `query ListHerbalFormulas(
  $filter: ModelHerbalFormulaFilterInput
  $limit: Int
  $nextToken: String
) {
  listHerbalFormulas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHerbalFormulasQueryVariables,
  APITypes.ListHerbalFormulasQuery
>;
export const listHerbalFormulaByscoringType = /* GraphQL */ `query ListHerbalFormulaByscoringType(
  $scoringType: Int!
  $sortDirection: ModelSortDirection
  $filter: ModelHerbalFormulaFilterInput
  $limit: Int
  $nextToken: String
) {
  listHerbalFormulaByscoringType(
    scoringType: $scoringType
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHerbalFormulaByscoringTypeQueryVariables,
  APITypes.ListHerbalFormulaByscoringTypeQuery
>;
export const getSurvey = /* GraphQL */ `query GetSurvey($id: ID!) {
  getSurvey(id: $id) {
    id
    otp
    patientID
    clinicID
    link
    surveyResult
    mainSymptom
    symptoms
    visitCount
    firstLinkClickDt
    surveyedAt
    updatedAt
    createdAt
    sendCount
    isSend
    treatmentID
    feedbacks
    feedbackResult
    feedbackAt
    feedbackLink
    feedbackDone
    isFeedback
    additionalSymptoms
    feedbackSendTime
    feedbackSchedule_GSI_PK
    feedbackScheduleStatus
    surveyDone
    surveyGrade
    surveySatisfaction
    feedbackGrade
    feedbackSatisfaction
    surveyStatus
    expiredAt
    surveyBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    feedbackBizTalkHistory {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    mainSymptomCategory
    sender
    surveyType
    insuranceType
    surveySurveyBizTalkHistoryId
    surveyFeedbackBizTalkHistoryId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSurveyQueryVariables, APITypes.GetSurveyQuery>;
export const listSurveys = /* GraphQL */ `query ListSurveys(
  $filter: ModelSurveyFilterInput
  $limit: Int
  $nextToken: String
) {
  listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSurveysQueryVariables,
  APITypes.ListSurveysQuery
>;
export const surveyByDate = /* GraphQL */ `query SurveyByDate(
  $patientID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyFilterInput
  $limit: Int
  $nextToken: String
) {
  surveyByDate(
    patientID: $patientID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SurveyByDateQueryVariables,
  APITypes.SurveyByDateQuery
>;
export const surveyByFeedbackSchedule = /* GraphQL */ `query SurveyByFeedbackSchedule(
  $feedbackSchedule_GSI_PK: String!
  $feedbackScheduleStatus: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSurveyFilterInput
  $limit: Int
  $nextToken: String
) {
  surveyByFeedbackSchedule(
    feedbackSchedule_GSI_PK: $feedbackSchedule_GSI_PK
    feedbackScheduleStatus: $feedbackScheduleStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SurveyByFeedbackScheduleQueryVariables,
  APITypes.SurveyByFeedbackScheduleQuery
>;
export const getHistory = /* GraphQL */ `query GetHistory($id: ID!) {
  getHistory(id: $id) {
    id
    clinicID
    patientID
    type
    typeID
    messageCode
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHistoryQueryVariables,
  APITypes.GetHistoryQuery
>;
export const listHistories = /* GraphQL */ `query ListHistories(
  $filter: ModelHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clinicID
      patientID
      type
      typeID
      messageCode
      message
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHistoriesQueryVariables,
  APITypes.ListHistoriesQuery
>;
export const historiesByDate = /* GraphQL */ `query HistoriesByDate(
  $patientID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  historiesByDate(
    patientID: $patientID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clinicID
      patientID
      type
      typeID
      messageCode
      message
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HistoriesByDateQueryVariables,
  APITypes.HistoriesByDateQuery
>;
export const getPatient = /* GraphQL */ `query GetPatient($id: ID!) {
  getPatient(id: $id) {
    id
    clinicID
    patientName
    birth
    residentRegistrationNumber
    gender
    phone
    recentActivity
    recentActivityMessage
    recentActivityCode
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    registrationPath
    isDeleted
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPatientQueryVariables,
  APITypes.GetPatientQuery
>;
export const listPatients = /* GraphQL */ `query ListPatients(
  $filter: ModelPatientFilterInput
  $limit: Int
  $nextToken: String
) {
  listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPatientsQueryVariables,
  APITypes.ListPatientsQuery
>;
export const patientsByClinic = /* GraphQL */ `query PatientsByClinic(
  $clinicID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPatientFilterInput
  $limit: Int
  $nextToken: String
) {
  patientsByClinic(
    clinicID: $clinicID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PatientsByClinicQueryVariables,
  APITypes.PatientsByClinicQuery
>;
export const patientsByName = /* GraphQL */ `query PatientsByName(
  $clinicID: ID!
  $patientName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPatientFilterInput
  $limit: Int
  $nextToken: String
) {
  patientsByName(
    clinicID: $clinicID
    patientName: $patientName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PatientsByNameQueryVariables,
  APITypes.PatientsByNameQuery
>;
export const patientsByRecentActivity = /* GraphQL */ `query PatientsByRecentActivity(
  $clinicID: ID!
  $recentActivity: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPatientFilterInput
  $limit: Int
  $nextToken: String
) {
  patientsByRecentActivity(
    clinicID: $clinicID
    recentActivity: $recentActivity
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PatientsByRecentActivityQueryVariables,
  APITypes.PatientsByRecentActivityQuery
>;
export const patientsByPrescriptionStatus = /* GraphQL */ `query PatientsByPrescriptionStatus(
  $clinicID: ID!
  $prescriptionStatus: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPatientFilterInput
  $limit: Int
  $nextToken: String
) {
  patientsByPrescriptionStatus(
    clinicID: $clinicID
    prescriptionStatus: $prescriptionStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PatientsByPrescriptionStatusQueryVariables,
  APITypes.PatientsByPrescriptionStatusQuery
>;
export const patientsByTreatmentStatus = /* GraphQL */ `query PatientsByTreatmentStatus(
  $clinicID: ID!
  $treatmentStatusDateForSorting: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPatientFilterInput
  $limit: Int
  $nextToken: String
) {
  patientsByTreatmentStatus(
    clinicID: $clinicID
    treatmentStatusDateForSorting: $treatmentStatusDateForSorting
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PatientsByTreatmentStatusQueryVariables,
  APITypes.PatientsByTreatmentStatusQuery
>;
export const getPatientDeleted = /* GraphQL */ `query GetPatientDeleted($id: ID!) {
  getPatientDeleted(id: $id) {
    id
    clinicID
    patientName
    birth
    gender
    phone
    residentRegistrationNumber
    recentActivity
    recentActivityMessage
    createdAt
    prescriptionStatus
    isWaiting
    isVisited
    symptomID
    mainSymptom
    prescriptionID
    surveyID
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    userID
    treatmentID
    patientNameForSorting
    surveyedAt
    updatedAt
    treatmentStatus
    treatmentStatusDateForSorting
    address
    type
    privacyTerms
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPatientDeletedQueryVariables,
  APITypes.GetPatientDeletedQuery
>;
export const listPatientDeleteds = /* GraphQL */ `query ListPatientDeleteds(
  $filter: ModelPatientDeletedFilterInput
  $limit: Int
  $nextToken: String
) {
  listPatientDeleteds(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clinicID
      patientName
      birth
      gender
      phone
      residentRegistrationNumber
      recentActivity
      recentActivityMessage
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      type
      privacyTerms
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPatientDeletedsQueryVariables,
  APITypes.ListPatientDeletedsQuery
>;
export const getUserRole = /* GraphQL */ `query GetUserRole($id: ID!) {
  getUserRole(id: $id) {
    id
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    config
    clinicName
    status
    email
    phone
    userID
    user {
      id
      email
      username
      phone
      terms
      termsVersion
      termsAgreedAt
      createdAt
      isDeleted
      roleName
      config
      licenseNumber
      licensePendingReviewFlag
      updatedAt
      __typename
    }
    roleName
    userName
    isDeleted
    testFlag
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserRoleQueryVariables,
  APITypes.GetUserRoleQuery
>;
export const listUserRoles = /* GraphQL */ `query ListUserRoles(
  $filter: ModelUserRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clinicID
      clinic {
        id
        clinicName
        doctorName
        doctorNumber
        phone
        fax
        medicalInstitutionNumber
        prescriptionValidityPeriod
        address
        feedbackDate
        isDeleted
        pricingInfo {
          currentPricingId
          nextPricingId
          __typename
        }
        stepPayID
        numberOfPatients
        numberOfDoctors
        numberOfSteps
        clinicEmail
        updatedAt
        planInfo
        planCheckPassFlag
        createdAt
        typename
        config
        businessType
        promotion
        __typename
      }
      config
      clinicName
      status
      email
      phone
      userID
      user {
        id
        email
        username
        phone
        terms
        termsVersion
        termsAgreedAt
        createdAt
        isDeleted
        roleName
        config
        licenseNumber
        licensePendingReviewFlag
        updatedAt
        __typename
      }
      roleName
      userName
      isDeleted
      testFlag
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserRolesQueryVariables,
  APITypes.ListUserRolesQuery
>;
export const roleByClinic = /* GraphQL */ `query RoleByClinic(
  $clinicID: ID!
  $roleName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  roleByClinic(
    clinicID: $clinicID
    roleName: $roleName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clinicID
      clinic {
        id
        clinicName
        doctorName
        doctorNumber
        phone
        fax
        medicalInstitutionNumber
        prescriptionValidityPeriod
        address
        feedbackDate
        isDeleted
        pricingInfo {
          currentPricingId
          nextPricingId
          __typename
        }
        stepPayID
        numberOfPatients
        numberOfDoctors
        numberOfSteps
        clinicEmail
        updatedAt
        planInfo
        planCheckPassFlag
        createdAt
        typename
        config
        businessType
        promotion
        __typename
      }
      config
      clinicName
      status
      email
      phone
      userID
      user {
        id
        email
        username
        phone
        terms
        termsVersion
        termsAgreedAt
        createdAt
        isDeleted
        roleName
        config
        licenseNumber
        licensePendingReviewFlag
        updatedAt
        __typename
      }
      roleName
      userName
      isDeleted
      testFlag
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RoleByClinicQueryVariables,
  APITypes.RoleByClinicQuery
>;
export const roleByUser = /* GraphQL */ `query RoleByUser(
  $userID: ID!
  $roleName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  roleByUser(
    userID: $userID
    roleName: $roleName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clinicID
      clinic {
        id
        clinicName
        doctorName
        doctorNumber
        phone
        fax
        medicalInstitutionNumber
        prescriptionValidityPeriod
        address
        feedbackDate
        isDeleted
        pricingInfo {
          currentPricingId
          nextPricingId
          __typename
        }
        stepPayID
        numberOfPatients
        numberOfDoctors
        numberOfSteps
        clinicEmail
        updatedAt
        planInfo
        planCheckPassFlag
        createdAt
        typename
        config
        businessType
        promotion
        __typename
      }
      config
      clinicName
      status
      email
      phone
      userID
      user {
        id
        email
        username
        phone
        terms
        termsVersion
        termsAgreedAt
        createdAt
        isDeleted
        roleName
        config
        licenseNumber
        licensePendingReviewFlag
        updatedAt
        __typename
      }
      roleName
      userName
      isDeleted
      testFlag
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RoleByUserQueryVariables,
  APITypes.RoleByUserQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    username
    phone
    terms
    termsVersion
    termsAgreedAt
    createdAt
    isDeleted
    roleName
    config
    licenseNumber
    licensePendingReviewFlag
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      username
      phone
      terms
      termsVersion
      termsAgreedAt
      createdAt
      isDeleted
      roleName
      config
      licenseNumber
      licensePendingReviewFlag
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getUserPool = /* GraphQL */ `query GetUserPool($id: ID!) {
  getUserPool(id: $id) {
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPoolQueryVariables,
  APITypes.GetUserPoolQuery
>;
export const listUserPools = /* GraphQL */ `query ListUserPools(
  $filter: ModelUserPoolFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserPools(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPoolsQueryVariables,
  APITypes.ListUserPoolsQuery
>;
export const getRole = /* GraphQL */ `query GetRole($id: ID!) {
  getRole(id: $id) {
    id
    clinicID
    roleName
    permisstions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRoleQueryVariables, APITypes.GetRoleQuery>;
export const listRoles = /* GraphQL */ `query ListRoles(
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clinicID
      roleName
      permisstions
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRolesQueryVariables, APITypes.ListRolesQuery>;
export const getClinicsForPromotion = /* GraphQL */ `query GetClinicsForPromotion($id: ID!) {
  getClinicsForPromotion(id: $id) {
    id
    promotionType
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClinicsForPromotionQueryVariables,
  APITypes.GetClinicsForPromotionQuery
>;
export const listClinicsForPromotions = /* GraphQL */ `query ListClinicsForPromotions(
  $filter: ModelClinicsForPromotionFilterInput
  $limit: Int
  $nextToken: String
) {
  listClinicsForPromotions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      promotionType
      clinicID
      clinic {
        id
        clinicName
        doctorName
        doctorNumber
        phone
        fax
        medicalInstitutionNumber
        prescriptionValidityPeriod
        address
        feedbackDate
        isDeleted
        pricingInfo {
          currentPricingId
          nextPricingId
          __typename
        }
        stepPayID
        numberOfPatients
        numberOfDoctors
        numberOfSteps
        clinicEmail
        updatedAt
        planInfo
        planCheckPassFlag
        createdAt
        typename
        config
        businessType
        promotion
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClinicsForPromotionsQueryVariables,
  APITypes.ListClinicsForPromotionsQuery
>;
export const listClinicsForPromotionBypromotionType = /* GraphQL */ `query ListClinicsForPromotionBypromotionType(
  $promotionType: String!
  $sortDirection: ModelSortDirection
  $filter: ModelClinicsForPromotionFilterInput
  $limit: Int
  $nextToken: String
) {
  listClinicsForPromotionBypromotionType(
    promotionType: $promotionType
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      promotionType
      clinicID
      clinic {
        id
        clinicName
        doctorName
        doctorNumber
        phone
        fax
        medicalInstitutionNumber
        prescriptionValidityPeriod
        address
        feedbackDate
        isDeleted
        pricingInfo {
          currentPricingId
          nextPricingId
          __typename
        }
        stepPayID
        numberOfPatients
        numberOfDoctors
        numberOfSteps
        clinicEmail
        updatedAt
        planInfo
        planCheckPassFlag
        createdAt
        typename
        config
        businessType
        promotion
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClinicsForPromotionBypromotionTypeQueryVariables,
  APITypes.ListClinicsForPromotionBypromotionTypeQuery
>;
export const getClinic = /* GraphQL */ `query GetClinic($id: ID!) {
  getClinic(id: $id) {
    id
    clinicName
    doctorName
    doctorNumber
    phone
    fax
    medicalInstitutionNumber
    prescriptionValidityPeriod
    address
    feedbackDate
    isDeleted
    pricingInfo {
      currentPricingId
      nextPricingId
      __typename
    }
    stepPayID
    numberOfPatients
    numberOfDoctors
    numberOfSteps
    clinicEmail
    updatedAt
    planInfo
    planCheckPassFlag
    createdAt
    typename
    config
    businessType
    promotion
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClinicQueryVariables, APITypes.GetClinicQuery>;
export const listClinics = /* GraphQL */ `query ListClinics(
  $filter: ModelClinicFilterInput
  $limit: Int
  $nextToken: String
) {
  listClinics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClinicsQueryVariables,
  APITypes.ListClinicsQuery
>;
export const clinicByDate = /* GraphQL */ `query ClinicByDate(
  $typename: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelClinicFilterInput
  $limit: Int
  $nextToken: String
) {
  clinicByDate(
    typename: $typename
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ClinicByDateQueryVariables,
  APITypes.ClinicByDateQuery
>;
export const getUsageStatistics = /* GraphQL */ `query GetUsageStatistics($clinicID: ID!, $recordDate: String!) {
  getUsageStatistics(clinicID: $clinicID, recordDate: $recordDate) {
    clinicID
    recordDate
    numberOfTreatmentCompletion
    numberOfTreatmentCompletionsWithPrescription
    numberOfKakaoSent
    numberOfSurveySentBySelf
    numberOfPlanChange
    numberOfTreatment
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUsageStatisticsQueryVariables,
  APITypes.GetUsageStatisticsQuery
>;
export const listUsageStatistics = /* GraphQL */ `query ListUsageStatistics(
  $clinicID: ID
  $recordDate: ModelStringKeyConditionInput
  $filter: ModelUsageStatisticsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsageStatistics(
    clinicID: $clinicID
    recordDate: $recordDate
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      clinicID
      recordDate
      numberOfTreatmentCompletion
      numberOfTreatmentCompletionsWithPrescription
      numberOfKakaoSent
      numberOfSurveySentBySelf
      numberOfPlanChange
      numberOfTreatment
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUsageStatisticsQueryVariables,
  APITypes.ListUsageStatisticsQuery
>;
export const getDailyUsageStatistics = /* GraphQL */ `query GetDailyUsageStatistics($date: String!) {
  getDailyUsageStatistics(date: $date) {
    date
    treatmentStartCount
    treatmentCompletionWithoutPrescriptionCount
    treatmentCompletionWithPrescriptionCount
    surveyCount
    surveySentBySelfCount
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailyUsageStatisticsQueryVariables,
  APITypes.GetDailyUsageStatisticsQuery
>;
export const listDailyUsageStatistics = /* GraphQL */ `query ListDailyUsageStatistics(
  $date: String
  $filter: ModelDailyUsageStatisticsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDailyUsageStatistics(
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      date
      treatmentStartCount
      treatmentCompletionWithoutPrescriptionCount
      treatmentCompletionWithPrescriptionCount
      surveyCount
      surveySentBySelfCount
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailyUsageStatisticsQueryVariables,
  APITypes.ListDailyUsageStatisticsQuery
>;
export const getTreatment = /* GraphQL */ `query GetTreatment($id: ID!) {
  getTreatment(id: $id) {
    id
    clinicID
    surveyID
    patientID
    patientData {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
    save
    survey {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      surveyBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      feedbackBizTalkHistory {
        msgIdx
        type
        createdAt
        updatedAt
        __typename
      }
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
    feedbackID
    mainSymptom
    userID
    prescribedHerb {
      code
      amount
      name
      __typename
    }
    status
    cure
    memo
    lastVisitedAt
    selectSymptom {
      code
      check
      __typename
    }
    symptomCheck
    name
    birth
    gender
    prescriptionStatus
    updatedAt
    createdAt
    amount
    isVisited
    isDeleted
    surveyCheck
    herbsForRecommanded
    treatmentedAt
    treatmentStatus
    patientNameForSearching
    selectedBCode
    prescriptionMode
    prescribedRecipe {
      pc
      amt
      __typename
    }
    prescriptionId
    prescriptionData {
      id
      category
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      cn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      gun
      sin
      scoringType
      source
      postfix
      createdAt
      updatedAt
      __typename
    }
    customHerbalFormulaId
    customHerbalFormulaData {
      id
      ownerUserID
      originalHerbalFormulaID
      originalHerbalFormulaData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    prescriptionDocument
    footprintsLog
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTreatmentQueryVariables,
  APITypes.GetTreatmentQuery
>;
export const listTreatments = /* GraphQL */ `query ListTreatments(
  $filter: ModelTreatmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listTreatments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clinicID
      surveyID
      patientID
      patientData {
        id
        clinicID
        patientName
        birth
        residentRegistrationNumber
        gender
        phone
        recentActivity
        recentActivityMessage
        recentActivityCode
        createdAt
        prescriptionStatus
        isWaiting
        isVisited
        symptomID
        mainSymptom
        prescriptionID
        surveyID
        survey {
          id
          otp
          patientID
          clinicID
          link
          surveyResult
          mainSymptom
          symptoms
          visitCount
          firstLinkClickDt
          surveyedAt
          updatedAt
          createdAt
          sendCount
          isSend
          treatmentID
          feedbacks
          feedbackResult
          feedbackAt
          feedbackLink
          feedbackDone
          isFeedback
          additionalSymptoms
          feedbackSendTime
          feedbackSchedule_GSI_PK
          feedbackScheduleStatus
          surveyDone
          surveyGrade
          surveySatisfaction
          feedbackGrade
          feedbackSatisfaction
          surveyStatus
          expiredAt
          mainSymptomCategory
          sender
          surveyType
          insuranceType
          surveySurveyBizTalkHistoryId
          surveyFeedbackBizTalkHistoryId
          __typename
        }
        userID
        treatmentID
        patientNameForSorting
        surveyedAt
        updatedAt
        treatmentStatus
        treatmentStatusDateForSorting
        address
        registrationPath
        isDeleted
        __typename
      }
      save
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      feedbackID
      mainSymptom
      userID
      prescribedHerb {
        code
        amount
        name
        __typename
      }
      status
      cure
      memo
      lastVisitedAt
      selectSymptom {
        code
        check
        __typename
      }
      symptomCheck
      name
      birth
      gender
      prescriptionStatus
      updatedAt
      createdAt
      amount
      isVisited
      isDeleted
      surveyCheck
      herbsForRecommanded
      treatmentedAt
      treatmentStatus
      patientNameForSearching
      selectedBCode
      prescriptionMode
      prescribedRecipe {
        pc
        amt
        __typename
      }
      prescriptionId
      prescriptionData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      customHerbalFormulaId
      customHerbalFormulaData {
        id
        ownerUserID
        originalHerbalFormulaID
        originalHerbalFormulaData {
          id
          category
          insrCCode
          insrDsCode
          InsrPrscrType
          kn
          cn
          herbCodes
          processingCodes
          gun
          sin
          scoringType
          source
          postfix
          createdAt
          updatedAt
          __typename
        }
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        scoringType
        memo
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      prescriptionDocument
      footprintsLog
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTreatmentsQueryVariables,
  APITypes.ListTreatmentsQuery
>;
export const treatmentsByPrescriptionStatus = /* GraphQL */ `query TreatmentsByPrescriptionStatus(
  $clinicID: ID!
  $treatmentedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTreatmentFilterInput
  $limit: Int
  $nextToken: String
) {
  treatmentsByPrescriptionStatus(
    clinicID: $clinicID
    treatmentedAt: $treatmentedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clinicID
      surveyID
      patientID
      patientData {
        id
        clinicID
        patientName
        birth
        residentRegistrationNumber
        gender
        phone
        recentActivity
        recentActivityMessage
        recentActivityCode
        createdAt
        prescriptionStatus
        isWaiting
        isVisited
        symptomID
        mainSymptom
        prescriptionID
        surveyID
        survey {
          id
          otp
          patientID
          clinicID
          link
          surveyResult
          mainSymptom
          symptoms
          visitCount
          firstLinkClickDt
          surveyedAt
          updatedAt
          createdAt
          sendCount
          isSend
          treatmentID
          feedbacks
          feedbackResult
          feedbackAt
          feedbackLink
          feedbackDone
          isFeedback
          additionalSymptoms
          feedbackSendTime
          feedbackSchedule_GSI_PK
          feedbackScheduleStatus
          surveyDone
          surveyGrade
          surveySatisfaction
          feedbackGrade
          feedbackSatisfaction
          surveyStatus
          expiredAt
          mainSymptomCategory
          sender
          surveyType
          insuranceType
          surveySurveyBizTalkHistoryId
          surveyFeedbackBizTalkHistoryId
          __typename
        }
        userID
        treatmentID
        patientNameForSorting
        surveyedAt
        updatedAt
        treatmentStatus
        treatmentStatusDateForSorting
        address
        registrationPath
        isDeleted
        __typename
      }
      save
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      feedbackID
      mainSymptom
      userID
      prescribedHerb {
        code
        amount
        name
        __typename
      }
      status
      cure
      memo
      lastVisitedAt
      selectSymptom {
        code
        check
        __typename
      }
      symptomCheck
      name
      birth
      gender
      prescriptionStatus
      updatedAt
      createdAt
      amount
      isVisited
      isDeleted
      surveyCheck
      herbsForRecommanded
      treatmentedAt
      treatmentStatus
      patientNameForSearching
      selectedBCode
      prescriptionMode
      prescribedRecipe {
        pc
        amt
        __typename
      }
      prescriptionId
      prescriptionData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      customHerbalFormulaId
      customHerbalFormulaData {
        id
        ownerUserID
        originalHerbalFormulaID
        originalHerbalFormulaData {
          id
          category
          insrCCode
          insrDsCode
          InsrPrscrType
          kn
          cn
          herbCodes
          processingCodes
          gun
          sin
          scoringType
          source
          postfix
          createdAt
          updatedAt
          __typename
        }
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        scoringType
        memo
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      prescriptionDocument
      footprintsLog
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TreatmentsByPrescriptionStatusQueryVariables,
  APITypes.TreatmentsByPrescriptionStatusQuery
>;
export const treatmentPatientByDate = /* GraphQL */ `query TreatmentPatientByDate(
  $patientID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTreatmentFilterInput
  $limit: Int
  $nextToken: String
) {
  treatmentPatientByDate(
    patientID: $patientID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clinicID
      surveyID
      patientID
      patientData {
        id
        clinicID
        patientName
        birth
        residentRegistrationNumber
        gender
        phone
        recentActivity
        recentActivityMessage
        recentActivityCode
        createdAt
        prescriptionStatus
        isWaiting
        isVisited
        symptomID
        mainSymptom
        prescriptionID
        surveyID
        survey {
          id
          otp
          patientID
          clinicID
          link
          surveyResult
          mainSymptom
          symptoms
          visitCount
          firstLinkClickDt
          surveyedAt
          updatedAt
          createdAt
          sendCount
          isSend
          treatmentID
          feedbacks
          feedbackResult
          feedbackAt
          feedbackLink
          feedbackDone
          isFeedback
          additionalSymptoms
          feedbackSendTime
          feedbackSchedule_GSI_PK
          feedbackScheduleStatus
          surveyDone
          surveyGrade
          surveySatisfaction
          feedbackGrade
          feedbackSatisfaction
          surveyStatus
          expiredAt
          mainSymptomCategory
          sender
          surveyType
          insuranceType
          surveySurveyBizTalkHistoryId
          surveyFeedbackBizTalkHistoryId
          __typename
        }
        userID
        treatmentID
        patientNameForSorting
        surveyedAt
        updatedAt
        treatmentStatus
        treatmentStatusDateForSorting
        address
        registrationPath
        isDeleted
        __typename
      }
      save
      survey {
        id
        otp
        patientID
        clinicID
        link
        surveyResult
        mainSymptom
        symptoms
        visitCount
        firstLinkClickDt
        surveyedAt
        updatedAt
        createdAt
        sendCount
        isSend
        treatmentID
        feedbacks
        feedbackResult
        feedbackAt
        feedbackLink
        feedbackDone
        isFeedback
        additionalSymptoms
        feedbackSendTime
        feedbackSchedule_GSI_PK
        feedbackScheduleStatus
        surveyDone
        surveyGrade
        surveySatisfaction
        feedbackGrade
        feedbackSatisfaction
        surveyStatus
        expiredAt
        surveyBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        feedbackBizTalkHistory {
          msgIdx
          type
          createdAt
          updatedAt
          __typename
        }
        mainSymptomCategory
        sender
        surveyType
        insuranceType
        surveySurveyBizTalkHistoryId
        surveyFeedbackBizTalkHistoryId
        __typename
      }
      feedbackID
      mainSymptom
      userID
      prescribedHerb {
        code
        amount
        name
        __typename
      }
      status
      cure
      memo
      lastVisitedAt
      selectSymptom {
        code
        check
        __typename
      }
      symptomCheck
      name
      birth
      gender
      prescriptionStatus
      updatedAt
      createdAt
      amount
      isVisited
      isDeleted
      surveyCheck
      herbsForRecommanded
      treatmentedAt
      treatmentStatus
      patientNameForSearching
      selectedBCode
      prescriptionMode
      prescribedRecipe {
        pc
        amt
        __typename
      }
      prescriptionId
      prescriptionData {
        id
        category
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        cn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        gun
        sin
        scoringType
        source
        postfix
        createdAt
        updatedAt
        __typename
      }
      customHerbalFormulaId
      customHerbalFormulaData {
        id
        ownerUserID
        originalHerbalFormulaID
        originalHerbalFormulaData {
          id
          category
          insrCCode
          insrDsCode
          InsrPrscrType
          kn
          cn
          herbCodes
          processingCodes
          gun
          sin
          scoringType
          source
          postfix
          createdAt
          updatedAt
          __typename
        }
        insrCCode
        insrDsCode
        InsrPrscrType
        kn
        herbCodes
        processingCodes
        recipe {
          pc
          amt
          __typename
        }
        scoringType
        memo
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      prescriptionDocument
      footprintsLog
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TreatmentPatientByDateQueryVariables,
  APITypes.TreatmentPatientByDateQuery
>;
export const getPrescriptionHisory = /* GraphQL */ `query GetPrescriptionHisory($id: ID!) {
  getPrescriptionHisory(id: $id) {
    id
    treatmentID
    prescribedRecipe {
      pc
      amt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPrescriptionHisoryQueryVariables,
  APITypes.GetPrescriptionHisoryQuery
>;
export const listPrescriptionHisories = /* GraphQL */ `query ListPrescriptionHisories(
  $filter: ModelPrescriptionHisoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrescriptionHisories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      treatmentID
      prescribedRecipe {
        pc
        amt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPrescriptionHisoriesQueryVariables,
  APITypes.ListPrescriptionHisoriesQuery
>;
export const getFormula = /* GraphQL */ `query GetFormula($id: ID!) {
  getFormula(id: $id) {
    id
    formulaName
    chineseName
    herbs
    amount
    primaryCare
    tag
    category
    priority
    gun
    sin
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFormulaQueryVariables,
  APITypes.GetFormulaQuery
>;
export const listFormulas = /* GraphQL */ `query ListFormulas(
  $filter: ModelFormulaFilterInput
  $limit: Int
  $nextToken: String
) {
  listFormulas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      formulaName
      chineseName
      herbs
      amount
      primaryCare
      tag
      category
      priority
      gun
      sin
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFormulasQueryVariables,
  APITypes.ListFormulasQuery
>;
export const getBiztalkHistory = /* GraphQL */ `query GetBiztalkHistory($msgIdx: ID!) {
  getBiztalkHistory(msgIdx: $msgIdx) {
    msgIdx
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBiztalkHistoryQueryVariables,
  APITypes.GetBiztalkHistoryQuery
>;
export const listBiztalkHistories = /* GraphQL */ `query ListBiztalkHistories(
  $msgIdx: ID
  $filter: ModelBiztalkHistoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBiztalkHistories(
    msgIdx: $msgIdx
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      msgIdx
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBiztalkHistoriesQueryVariables,
  APITypes.ListBiztalkHistoriesQuery
>;
export const getYejinSignupRequest = /* GraphQL */ `query GetYejinSignupRequest($id: ID!) {
  getYejinSignupRequest(id: $id) {
    id
    userName
    email
    clinicName
    clinicAddress
    clinicPhone
    terms
    termsVersion
    termsAgreedAt
    permission
    licenseNumber
    clinicEmail
    businessType
    createdAt
    approvedAt
    requestStatus
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetYejinSignupRequestQueryVariables,
  APITypes.GetYejinSignupRequestQuery
>;
export const listYejinSignupRequests = /* GraphQL */ `query ListYejinSignupRequests(
  $filter: ModelYejinSignupRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  listYejinSignupRequests(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userName
      email
      clinicName
      clinicAddress
      clinicPhone
      terms
      termsVersion
      termsAgreedAt
      permission
      licenseNumber
      clinicEmail
      businessType
      createdAt
      approvedAt
      requestStatus
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListYejinSignupRequestsQueryVariables,
  APITypes.ListYejinSignupRequestsQuery
>;
export const getReport = /* GraphQL */ `query GetReport($treatmentID: ID!) {
  getReport(treatmentID: $treatmentID) {
    treatmentID
    sendCount
    byeonjeung
    startedAt
    birth
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReportQueryVariables, APITypes.GetReportQuery>;
export const listReports = /* GraphQL */ `query ListReports(
  $treatmentID: ID
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReports(
    treatmentID: $treatmentID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      treatmentID
      sendCount
      byeonjeung
      startedAt
      birth
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportsQueryVariables,
  APITypes.ListReportsQuery
>;
export const getSelfRegistrationQR = /* GraphQL */ `query GetSelfRegistrationQR($QRID: ID!) {
  getSelfRegistrationQR(QRID: $QRID) {
    QRID
    clinicID
    clinic {
      id
      clinicName
      doctorName
      doctorNumber
      phone
      fax
      medicalInstitutionNumber
      prescriptionValidityPeriod
      address
      feedbackDate
      isDeleted
      pricingInfo {
        currentPricingId
        nextPricingId
        __typename
      }
      stepPayID
      numberOfPatients
      numberOfDoctors
      numberOfSteps
      clinicEmail
      updatedAt
      planInfo
      planCheckPassFlag
      createdAt
      typename
      config
      businessType
      promotion
      __typename
    }
    expiredDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSelfRegistrationQRQueryVariables,
  APITypes.GetSelfRegistrationQRQuery
>;
export const listSelfRegistrationQRS = /* GraphQL */ `query ListSelfRegistrationQRS(
  $QRID: ID
  $filter: ModelSelfRegistrationQRFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSelfRegistrationQRS(
    QRID: $QRID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      QRID
      clinicID
      clinic {
        id
        clinicName
        doctorName
        doctorNumber
        phone
        fax
        medicalInstitutionNumber
        prescriptionValidityPeriod
        address
        feedbackDate
        isDeleted
        pricingInfo {
          currentPricingId
          nextPricingId
          __typename
        }
        stepPayID
        numberOfPatients
        numberOfDoctors
        numberOfSteps
        clinicEmail
        updatedAt
        planInfo
        planCheckPassFlag
        createdAt
        typename
        config
        businessType
        promotion
        __typename
      }
      expiredDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSelfRegistrationQRSQueryVariables,
  APITypes.ListSelfRegistrationQRSQuery
>;
export const QRByClinic = /* GraphQL */ `query QRByClinic(
  $clinicID: ID!
  $QRID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSelfRegistrationQRFilterInput
  $limit: Int
  $nextToken: String
) {
  QRByClinic(
    clinicID: $clinicID
    QRID: $QRID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      QRID
      clinicID
      clinic {
        id
        clinicName
        doctorName
        doctorNumber
        phone
        fax
        medicalInstitutionNumber
        prescriptionValidityPeriod
        address
        feedbackDate
        isDeleted
        pricingInfo {
          currentPricingId
          nextPricingId
          __typename
        }
        stepPayID
        numberOfPatients
        numberOfDoctors
        numberOfSteps
        clinicEmail
        updatedAt
        planInfo
        planCheckPassFlag
        createdAt
        typename
        config
        businessType
        promotion
        __typename
      }
      expiredDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QRByClinicQueryVariables,
  APITypes.QRByClinicQuery
>;
export const getDailySignupCount = /* GraphQL */ `query GetDailySignupCount($date: String!) {
  getDailySignupCount(date: $date) {
    date
    count
    memo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailySignupCountQueryVariables,
  APITypes.GetDailySignupCountQuery
>;
export const listDailySignupCounts = /* GraphQL */ `query ListDailySignupCounts(
  $date: String
  $filter: ModelDailySignupCountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDailySignupCounts(
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      date
      count
      memo
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailySignupCountsQueryVariables,
  APITypes.ListDailySignupCountsQuery
>;
export const getDailyPatientCount = /* GraphQL */ `query GetDailyPatientCount($date: String!) {
  getDailyPatientCount(date: $date) {
    date
    count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailyPatientCountQueryVariables,
  APITypes.GetDailyPatientCountQuery
>;
export const listDailyPatientCounts = /* GraphQL */ `query ListDailyPatientCounts(
  $date: String
  $filter: ModelDailyPatientCountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDailyPatientCounts(
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      date
      count
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailyPatientCountsQueryVariables,
  APITypes.ListDailyPatientCountsQuery
>;
