/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import throttle from 'lodash/throttle';
// import { Carousel } from 'react-responsive-carousel';

import { useStores } from 'stores/Context';
import CompanyInfo from 'components/UI/organisms/landing/CompanyInfo';
import { signOutHandler } from 'utils/util';
import { isEnableTillStaging } from 'config';

import { TypographyText, Layout, Content, Footer, Row, Col, Button, Spinner } from 'components/UI/atoms';

const MainPage = () => {
  const { clientStore } = useStores();
  const [isAuth, setIsAuth] = useState(false);
  const [isAuthCheckLoading, setIsAuthCheckLoading] = useState(true);
  const init = useCallback(async () => {
    await setIsAuthCheckLoading(true);
    Auth.currentSession()
      .then(async (idToken) => {
        const token = idToken.getIdToken().getJwtToken();
        if (token.length > 0) {
          for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i)?.includes('LastAuthUser')) {
              const currentAuthUserId = localStorage.getItem(localStorage.key(i));
              sessionStorage.setItem('sub', currentAuthUserId);
              await clientStore.setMetaData(currentAuthUserId);
              await setIsAuth(true);
            }
          }
        }
      })
      .catch((e) => {
        console.log(e);
        if (e !== 'No current user') {
          // 로그인 안된 경우는 에러 취급하지 않음
          if (isEnableTillStaging) console.log(e);
        }
      })
      .finally(() => {
        setIsAuthCheckLoading(false);
      });
  }, []);

  const currentFontSize = useMemo(() => {
    const currentFontSize = parseFloat(window.getComputedStyle(document.body).getPropertyValue('font-size'));
    return currentFontSize;
  }, [
    // 브라우저 사이즈가 바뀔 때마다 폰트 사이즈를 갱신한다.
    window.innerWidth,
  ]);

  // wheel event function
  const handleWheelEvent = useCallback(
    throttle((event) => {
      let wheel = event.deltaY;
      // 현재 스크롤 위치 감지
      const scrollPosition = window.scrollY;
      const scrollPostionByRemUnit = scrollPosition / currentFontSize;
      const landingPageHeader = document.getElementsByClassName('landing-page-header')[0] as HTMLElement;
      if (landingPageHeader === undefined) return;

      if (wheel > 0) {
        if (scrollPostionByRemUnit > 5) {
          if (landingPageHeader?.classList?.contains('landing-page-header-visible')) {
            landingPageHeader.classList.replace('landing-page-header-visible', 'landing-page-header-hidden');
          } else {
            landingPageHeader.classList.add('landing-page-header-hidden');
          }
        }
      } else {
        if (landingPageHeader.classList?.contains('landing-page-header-hidden')) {
          landingPageHeader.classList.replace('landing-page-header-hidden', 'landing-page-header-visible');
        } else {
          landingPageHeader.classList.add('landing-page-header-visible');
        }
      }
      if (scrollPostionByRemUnit > 5) {
        if (landingPageHeader.classList?.contains('landing-page-header-light')) {
          landingPageHeader.classList.replace('landing-page-header-light', 'landing-page-header-dark');
        } else {
          landingPageHeader.classList.add('landing-page-header-dark');
        }
      } else {
        if (landingPageHeader.classList?.contains('landing-page-header-dark')) {
          landingPageHeader.classList.replace('landing-page-header-dark', 'landing-page-header-light');
        } else {
          landingPageHeader.classList.add('landing-page-header-light');
        }
      }
    }, 300),
    [currentFontSize],
  );

  useEffect(() => {
    const scrollPosition = window.scrollY;
    const scrollPostionByRemUnit = scrollPosition / currentFontSize;
    const landingPageHeader = document.getElementsByClassName('landing-page-header')[0] as HTMLElement;
    if (scrollPostionByRemUnit > 5) {
      document.getElementById('landing-page-logo')?.setAttribute('src', '/img/logo-white.webp');
      if (landingPageHeader.classList?.contains('landing-page-header-light')) {
        landingPageHeader.classList.replace('landing-page-header-light', 'landing-page-header-dark');
      } else {
        landingPageHeader.classList.add('landing-page-header-dark');
      }
    } else {
      if (landingPageHeader.classList?.contains('landing-page-header-dark')) {
        landingPageHeader.classList.replace('landing-page-header-dark', 'landing-page-header-light');
      } else {
        landingPageHeader.classList.add('landing-page-header-light');
      }
    }
    // throttle event listener
    window.addEventListener('wheel', handleWheelEvent);
    init();
    return () => {
      // cleanup event listener
      window.removeEventListener('wheel', handleWheelEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // .landing-page-header의 클래스 변경 감지
    const landingPageHeader = document.getElementsByClassName('landing-page-header')[0] as HTMLElement;
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          const currentClassList = (mutation.target as Element)?.classList;
          if (currentClassList?.contains('landing-page-header-dark')) {
            //landing-page-logo
            document.getElementById('landing-page-logo')?.setAttribute('src', '/img/logo-white.webp');
          } else {
            document.getElementById('landing-page-logo')?.setAttribute('src', '/img/logo_black.webp');
          }
        }
      });
    });
    observer.observe(landingPageHeader, {
      attributes: true,
    });

    // .landing-page-section-1-arrow
    const landingPageSection1Arrow = document.getElementById('landing-page-section-1-arrow') as HTMLElement;
    // animate arrow
    landingPageSection1Arrow.animate(
      [
        {
          transform: 'translate3d(0px, 0px, 0px)',
        },
        {
          transform: 'translate3d(0px, 1.1rem, 0px)',
        },
      ],
      {
        duration: 1000,
        iterations: Infinity,
        direction: 'alternate',
        easing: 'ease-in-out',
      },
    );

    landingPageSection1Arrow.animate(
      [
        {
          opacity: 0.6,
        },
        {
          opacity: 1,
        },
        {
          opacity: 0.6,
        },
      ],
      {
        duration: 2000,
        iterations: Infinity,
        easing: 'ease-in-out',
      },
    );
    return () => {
      observer.disconnect();
      // disconnect arrow animation
      landingPageSection1Arrow.getAnimations().forEach((animation) => animation.cancel());
    };
  }, []);

  const navigate = useNavigate();

  return (
    <Layout
      className="landing-page"
      style={{
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <Row
        className="landing-page-header"
        style={{
          transition: 'all 0.3s ease',
          height: '5rem',
          position: 'sticky',
          zIndex: 990,
          top: 0,
          width: '100%',
          background: '#ffffff',
          paddingLeft: '6.25%',
          paddingRight: '6.25%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          columnGap: '2rem',
        }}
      >
        <Col style={{ display: 'flex', flexShrink: 0 }} className="logo-wrapper">
          <img
            id="landing-page-logo"
            style={{ width: '7.06rem' }}
            src={`${process.env.PUBLIC_URL}/img/logo_black.webp`}
            alt="yejin-logo"
          />
        </Col>
        <Col
          className="landing-header-menu-group-2"
          style={{ display: 'flex', alignItems: 'center', columnGap: '8rem' }}
        >
          <Col
            className="landing-header-menu-group-3"
            style={{ display: 'flex', alignItems: 'center', columnGap: '2rem' }}
          >
            <Col
              yjTypeCol="center"
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => {
                document.getElementById('serviceIntrodutionScrollTarget').scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }}
            >
              <TypographyText fontWeight={700} letterSpacing={'-0.02em'} whiteSpace={'nowrap'}>
                예진 소개
              </TypographyText>
            </Col>
            <Col
              yjTypeCol="center"
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  'https://futuristic-yarrow-f15.notion.site/51f791db9792432c9d9e223ad3eedbdb',
                  '_blank',
                );
              }}
            >
              <TypographyText fontWeight={700} letterSpacing={'-0.02rem'} whiteSpace={'nowrap'}>
                FAQ
              </TypographyText>
            </Col>
            <Col
              yjTypeCol="center"
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  'https://futuristic-yarrow-f15.notion.site/73926bd06f0b45e39c2294e0bce073a9?v=5aaa9bd585bb4e89b89cf73eef6ca6b5',
                  '_blank',
                );
              }}
            >
              <TypographyText fontWeight={700} letterSpacing={'-0.02rem'} whiteSpace={'nowrap'}>
                예진 사용법
              </TypographyText>
            </Col>
            <Col
              yjTypeCol="center"
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => {
                window.open('https://blog.naver.com/yejinclinic', '_blank');
              }}
            >
              <TypographyText fontWeight={700} letterSpacing={'-0.02rem'} whiteSpace={'nowrap'}>
                블로그
              </TypographyText>
            </Col>
          </Col>
          <Col style={{ display: 'flex', columnGap: '0.5rem' }}>
            <Col>
              <Button
                id="log-button"
                style={{
                  paddingLeft: '1.25rem',
                  paddingRight: '1.25rem',
                  height: '2.05rem',
                  border: isAuth || isAuthCheckLoading ? '1px solid #AFAFAF' : '1px solid #FFC690',
                  background: isAuth || isAuthCheckLoading ? '#FFFFFF' : '#FFC690',
                  cursor: isAuthCheckLoading ? 'default' : 'pointer',
                  borderRadius: '100px',
                }}
                onClick={() => {
                  if (isAuthCheckLoading) return;
                  if (isAuth) {
                    signOutHandler();
                  } else {
                    navigate({
                      pathname: '/signin',
                    });
                  }
                }}
              >
                {isAuthCheckLoading ? (
                  <Spinner spinnerDivWidth="w-15" />
                ) : (
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '3.75rem',
                      justifyContent: 'center',
                    }}
                  >
                    {
                      <TypographyText whiteSpace="pre" lineHeight="1rem">{`${
                        isAuth ? '로그아웃' : '로그인'
                      } `}</TypographyText>
                    }
                  </Col>
                )}
              </Button>
            </Col>
            <Col>
              <Button
                id="start-yejin-button"
                style={{
                  paddingLeft: '1.25rem',
                  paddingRight: '1.25rem',
                  height: '2.05rem',
                  border: 'none',
                  background: '#1c252c',
                  cursor: isAuthCheckLoading ? 'default' : 'pointer',
                  borderRadius: '100px',
                }}
                onClick={() => {
                  if (isAuthCheckLoading) return;
                  if (isAuth) {
                    if (clientStore.metaData?.userRole) {
                      navigate('/patient', {
                        state: {
                          noticeFlag: true,
                        },
                      });
                    } else {
                      navigate('/signinWithoutClinic');
                    }
                  } else {
                    navigate('/signup');
                  }
                }}
              >
                {isAuthCheckLoading ? (
                  <Spinner spinnerDivWidth="w-26" />
                ) : (
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {
                      <TypographyText whiteSpace="pre" lineHeight="1rem" style={{ color: '#ffffff' }}>{`${
                        isAuth ? '서비스 바로가기' : '무료로 시작하기'
                      } `}</TypographyText>
                    }
                  </Col>
                )}
              </Button>
            </Col>
          </Col>
        </Col>
      </Row>
      <Content className="landing-page-content">
        <Row //section 1
          className="landing-page-section-1"
          style={{
            position: 'relative',
            background: '#ffffff',
            height: 'calc(100vh - 5rem)', // 5rem은 header의 높이
            width: 'auto',
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/landing-img-01.webp`}
            style={{
              width: '100%',
              height: '100%',
            }}
            className="object-fit-cover"
            alt="landing-img-01"
          />
          <Row
            style={{
              position: 'absolute',
              top: '10%',
              left: 0,
              width: '100%',
              height: '90%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              zIndex: 2,
            }}
          >
            <Row
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                rowGap: '2.4rem',
              }}
            >
              <Row style={{ textAlign: 'center' }}>
                <TypographyText
                  className="too-big-for-mobile-level-1"
                  fontSize="4.1rem"
                  lineHeight="1.4em"
                  whiteSpace={'pre'}
                  fontWeight={700}
                >
                  {`진료부터 관리까지
‘예진’으로
쉽고 편리하게`}
                </TypographyText>
              </Row>
              <Row>
                <Button
                  style={{
                    minWidth: '20rem',
                    paddingLeft: '2.5rem',
                    paddingRight: '2.5rem',
                    height: '3.7rem',
                    border: 'none',
                    background:
                      'linear-gradient(105.22deg, rgba(247, 130, 50, 0.29) -10.49%, #F78232 57.24%)',
                    cursor: 'pointer',
                    borderRadius: '100px',
                  }}
                  onClick={() => {
                    if (isAuthCheckLoading) return;
                    if (isAuth) {
                      if (clientStore.metaData?.userRole) {
                        navigate('/patient', {
                          state: {
                            noticeFlag: true,
                          },
                        });
                      } else {
                        navigate('/signinWithoutClinic');
                      }
                    } else {
                      navigate('/signup');
                    }
                  }}
                >
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {isAuthCheckLoading ? (
                      <Spinner spinnerDivWidth="w-20" spinnerWidth="w-8" spinnerHeight="w-8" />
                    ) : (
                      <TypographyText
                        fontWeight={700}
                        color="#ffffff"
                        fontSize="1.6rem"
                        lineHeight="1.2em"
                        letterSpacing="0.005rem"
                      >
                        {isAuth ? `‘예진’ 서비스 바로가기` : `‘예진’ 무료로 시작하기`}
                      </TypographyText>
                    )}
                  </Col>
                </Button>
              </Row>
            </Row>
            <Row
              style={{
                cursor: 'pointer',
                width: '3rem',
                height: '3.5rem',
              }}
              onClick={() => {
                // .landing-page-section-2로 이동
                document.getElementsByClassName('landing-page-section-1.5')?.[0]?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }}
            >
              <img
                id="landing-page-section-1-arrow"
                src={`${process.env.PUBLIC_URL}/img/landing-img-01-arrow.webp`}
                style={{
                  width: '100%',
                  transform: 'translate3d(0px, 0px, 0px)',
                }}
                alt="arrow"
              />
            </Row>
          </Row>
        </Row>
        <Row //section 1.5
          className="landing-page-section-1.5"
          style={{
            background: '#F9FAFB',
            height: '43rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <TypographyText
            className="too-small-for-mobile-level-1"
            fontWeight={700}
            fontSize="2.2rem"
            lineHeight="1.75em"
            whiteSpace="pre-wrap"
            wordBreak="keep-all"
          >
            {`쉽고 편리한 진료 지원 시스템,
나만의 AI 도우미 '예진'과 함께
한의원을 업그레이드하세요.`}
          </TypographyText>
        </Row>
        <Row // section3
          className="landing-page-section-3"
          style={{
            background: '#ffffff',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '1.75rem',
            padding: '9rem 15.88% 6rem',
            position: 'relative',
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/landing-img-02-1.webp`}
            width={'100%'}
            alt="landing-img-02-1"
          />
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TypographyText
              style={{ zIndex: 3 }}
              className="too-small-for-mobile-level-1"
              fontWeight={700}
              fontSize="2.2rem"
              lineHeight="1.75em"
              whiteSpace="pre-wrap"
              wordBreak="keep-all"
            >
              {`예진은 꼼꼼하고 체계적인 진료로
환자 만족도 향상 및 신규 고객 확대를 도와줍니다.`}
            </TypographyText>
          </Row>
          <Row
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: '27.52rem',
              zIndex: 2,
              background: 'linear-gradient(0deg, #F9FAFB 0%, rgba(249, 250, 251, 0) 100%)',
            }}
          />
        </Row>
        <Row //section4
          className="landing-page-section-4"
          id="serviceIntrodutionScrollTarget"
          style={{
            background: '#ffffff',
            padding: '7.55rem 5.2% 7.55rem 6.25%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Col
            style={{
              flex: 36,
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1.35rem',
            }}
          >
            <Row
              className="landing-page-section-4-text"
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1.4rem',
                justifyContent: 'center',
              }}
            >
              <TypographyText
                color="#F78232"
                fontSize="1.6rem"
                fontWeight={700}
                whiteSpace="pre-wrap"
                lineHeight="1.2em"
                letterSpacing="-0.02em"
              >
                {`증상설문`}
              </TypographyText>
              <TypographyText
                className="too-small-for-mobile-level-1"
                whiteSpace="pre-wrap"
                fontWeight={700}
                fontSize="2.4rem"
                lineHeight="1.2em"
                letterSpacing="-0.02em"
              >
                {`꼼꼼한 증상 설문`}
              </TypographyText>
              <Row
                className="landing-page-section-4-text-2"
                style={{
                  textAlign: 'left',
                }}
              >
                <TypographyText
                  className="too-small-for-mobile-level-2"
                  fontSize="1.2rem"
                  whiteSpace="pre-wrap"
                  lineHeight="1.67em"
                  letterSpacing="-0.01rem"
                >
                  {`증상 척도 및 발병일 포함
꼼꼼하고 정량적인 진료 가능
환자는 편하게 모바일로 증상 입력`}
                </TypographyText>
              </Row>
            </Row>
          </Col>
          <Col style={{ display: 'flex', flex: 64 }}>
            <img
              src={`${process.env.PUBLIC_URL}/img/landing-img-03.webp`}
              width={'100%'}
              alt="landing-img-03"
            />
          </Col>
        </Row>
        <Row //section5
          className="landing-page-section-5"
          style={{
            background: '#f9fafb',
            padding: '7.5rem 5.2% 7.5rem 6.25%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Col
            className="landing-page-section-5-text"
            style={{
              flex: 36,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: '1.4rem',
            }}
          >
            <TypographyText
              color="#F78232"
              fontSize="1.6rem"
              fontWeight={700}
              whiteSpace="pre-wrap"
              lineHeight="1.2em"
              letterSpacing="-0.02em"
            >
              {`진료실 - 변증`}
            </TypographyText>
            <TypographyText
              className="too-small-for-mobile-level-1"
              whiteSpace="pre-wrap"
              wordBreak="keep-all"
              fontWeight={700}
              fontSize="2.4rem"
              lineHeight="1.2em"
              letterSpacing="-0.02em"
            >
              {`환자 커뮤니케이션 증대`}
            </TypographyText>
            <Row
              className="landing-page-section-5-text-2"
              style={{
                textAlign: 'left',
              }}
            >
              <TypographyText
                className="too-small-for-mobile-level-2"
                fontSize="1.2rem"
                whiteSpace="pre-wrap"
                lineHeight="1.67em"
                letterSpacing="-0.01rem"
              >
                {`알고리즘을 통해 도출된 변증 결과를 시각자료로 활용
환자 모바일로 전송 가능`}
              </TypographyText>
            </Row>
          </Col>
          <Col style={{ display: 'flex', flex: 64 }}>
            <img
              src={`${process.env.PUBLIC_URL}/img/landing-img-04.webp`}
              width={'100%'}
              alt="landing-img-04"
            />
          </Col>
        </Row>
        <Row //section6
          className="landing-page-section-6"
          style={{
            background: '#ffffff',
            padding: '7.5rem 9.375% 6rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: '1.4rem',
            }}
          >
            <TypographyText
              color="#F78232"
              fontSize="1.6rem"
              fontWeight={700}
              whiteSpace="pre-wrap"
              lineHeight="1.2em"
              letterSpacing="-0.02em"
            >
              {`진료실 - 처방`}
            </TypographyText>
            <TypographyText
              className="too-small-for-mobile-level-1"
              whiteSpace="pre-wrap"
              wordBreak="keep-all"
              fontWeight={700}
              fontSize="2.4rem"
              lineHeight="1.2em"
              letterSpacing="-0.02em"
            >
              {`환자증상 기반 처방 · 약재추천`}
            </TypographyText>
            <Row className="landing-page-section-6-text" style={{ textAlign: 'center' }}>
              <TypographyText
                className="too-small-for-mobile-level-2"
                fontSize="1.2rem"
                whiteSpace="pre-wrap"
                lineHeight="1.583em"
                letterSpacing="-0.02rem"
              >
                {`수백권의 한의 전통 문헌 DB 기반으로 고방/후세방 나눠 추천
예진의 효율적인 진료 보조`}
              </TypographyText>
            </Row>
          </Row>
          <Row style={{ display: 'flex', marginTop: '4rem' }}>
            <img
              src={`${process.env.PUBLIC_URL}/img/landing-img-05.webp`}
              width={'100%'}
              alt="landing-img-05"
            />
          </Row>
          <Row
            className="landing-page-section-6-graph-wrapper"
            style={{ display: 'flex', marginTop: '5rem', justifyContent: 'space-between', columnGap: '6rem' }}
          >
            <Col style={{ display: 'flex', flexDirection: 'column', flex: 1, flexShrink: 0 }}>
              <Row>
                <Row style={{ display: 'flex', columnGap: '0.5rem' }}>
                  <Col>
                    <TypographyText
                      fontSize="2rem"
                      whiteSpace="pre"
                      lineHeight="1.2em"
                      letterSpacing="-0.01rem"
                    >
                      {`초진환자`}
                    </TypographyText>
                  </Col>
                  <Col>
                    <TypographyText
                      fontSize="2rem"
                      whiteSpace="pre"
                      lineHeight="1.2em"
                      letterSpacing="-0.01rem"
                      fontWeight={700}
                    >
                      {`진료시간`}
                    </TypographyText>
                  </Col>
                </Row>
                <Row style={{ marginTop: '0.75rem' }}>
                  <TypographyText
                    fontSize="1.2rem"
                    whiteSpace="pre-wrap"
                    lineHeight="1.7em"
                    letterSpacing="-0.01rem"
                    color="#818181"
                  >
                    {`예진 사용 전과 후로 진료 시간 변화 확인`}
                  </TypographyText>
                </Row>
                <Row style={{ display: 'flex', marginTop: '2.25rem' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/landing-img-05-1.webp`}
                    width={'100%'}
                    alt="landing-img-05-1"
                  />
                </Row>
              </Row>
            </Col>
            <Col style={{ display: 'flex', flexDirection: 'column', flex: 1, flexShrink: 0 }}>
              <Row>
                <Row style={{ display: 'flex', columnGap: '0.5rem' }}>
                  <Col>
                    <TypographyText
                      fontSize="2rem"
                      whiteSpace="pre"
                      lineHeight="1.2em"
                      letterSpacing="-0.01rem"
                    >
                      {`예진의`}
                    </TypographyText>
                  </Col>
                  <Col>
                    <TypographyText
                      fontSize="2rem"
                      whiteSpace="pre"
                      lineHeight="1.2em"
                      letterSpacing="-0.01rem"
                      fontWeight={700}
                    >
                      {`신뢰성 검증`}
                    </TypographyText>
                  </Col>
                </Row>
                <Row style={{ marginTop: '0.75rem' }}>
                  <TypographyText
                    fontSize="1.2rem"
                    whiteSpace="pre-wrap"
                    lineHeight="1.7em"
                    letterSpacing="-0.01rem"
                    color="#818181"
                  >
                    {`20년 이상의 진료 경험이 있는 전문 한의사와 진료 일치율 분석`}
                  </TypographyText>
                </Row>
                <Row style={{ display: 'flex', marginTop: '2.25rem' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/landing-img-05-2.webp`}
                    width={'100%'}
                    alt="landing-img-05-1"
                  />
                </Row>
              </Row>
            </Col>
          </Row>
        </Row>
        <Row //section7
          className="landing-page-section-7"
          style={{
            background: '#f9fafb',
            padding: '7.5rem 6.771% 5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Row
            className="landing-page-section-7-text"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: '1.4rem',
            }}
          >
            <TypographyText
              color="#F78232"
              fontSize="1.6rem"
              fontWeight={700}
              whiteSpace="pre-wrap"
              lineHeight="1.2em"
              letterSpacing="-0.02em"
            >
              {`피드백`}
            </TypographyText>
            <TypographyText
              className="too-small-for-mobile-level-1"
              whiteSpace="pre-wrap"
              wordBreak="keep-all"
              fontWeight={700}
              fontSize="2.4rem"
              lineHeight="1.2em"
              letterSpacing="-0.02em"
            >
              {`지속적인 환자 관리`}
            </TypographyText>
            <Row style={{ marginTop: '0.9rem', textAlign: 'center' }}>
              <TypographyText
                className="too-small-for-mobile-level-2"
                fontSize="1.2rem"
                whiteSpace="pre-wrap"
                wordBreak="keep-all"
                lineHeight="1.583em"
                letterSpacing="-0.01rem"
              >
                {`진료 후 피드백 설문 자동 전송 섬세한 환자 관리로 재진률 상승`}
              </TypographyText>
            </Row>
          </Row>
          <Row style={{ display: 'flex', marginTop: '4rem' }}>
            <img
              src={`${process.env.PUBLIC_URL}/img/landing-img-06.webp`}
              width={'100%'}
              alt="landing-img-06"
            />
          </Row>
        </Row>
        <Row //section9
          className="landing-page-section-9"
          style={{
            position: 'relative',
            background: '#ffffff',
            padding: '7.5rem 9.375% 6rem',
          }}
        >
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              rowGap: '1.4rem',
            }}
          >
            <TypographyText color="#F78232" fontSize="1.4rem" lineHeight="1.2em" fontWeight={700}>
              {`INTERVIEW`}
            </TypographyText>
            <TypographyText
              className="too-small-for-mobile-level-1"
              whiteSpace="pre-wrap"
              wordBreak="keep-all"
              fontWeight={700}
              fontSize="2.4rem"
              lineHeight="1.2em"
              letterSpacing="-0.02em"
            >
              {`높은 환자 만족도`}
            </TypographyText>
            <Row style={{ textAlign: 'center' }}>
              <TypographyText
                className="too-small-for-mobile-level-2"
                fontSize="1.2rem"
                whiteSpace="pre-wrap"
                wordBreak="keep-all"
                lineHeight="1.583em"
                letterSpacing="-0.01rem"
              >
                {`처음이라도 편하게 사용할 수 있는 UX/UI를 통한
환자 만족도 · 의료기관 신뢰도 상승`}
              </TypographyText>
            </Row>
          </Row>
          <Row
            className="landing-page-section-9-graph-wrapper"
            style={{
              display: 'flex',
              marginTop: '4rem',
              flexDirection: 'column',
              paddingLeft: '2.5rem',
              paddingRight: '2.5rem',
            }}
          >
            <Row style={{ display: 'flex', justifyContent: 'center', columnGap: '0.5rem' }}>
              <Col>
                <TypographyText
                  fontSize="2rem"
                  whiteSpace="pre"
                  lineHeight="1.2em"
                  letterSpacing="-0.01rem"
                  fontWeight={700}
                >
                  {`환자 만족도`}
                </TypographyText>
              </Col>
              <Col>
                <TypographyText fontSize="2rem" whiteSpace="pre" lineHeight="1.2em" letterSpacing="-0.01rem">
                  {`설문 결과`}
                </TypographyText>
              </Col>
            </Row>
            <Row
              className="landing-page-section-7-graph-wrapper"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '2.25rem',
                columnGap: '5.5rem',
              }}
            >
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  flexShrink: 0,
                }}
              >
                <Row>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <img
                      id="landing-page-circle-graph-1"
                      src={`${process.env.PUBLIC_URL}/img/landing-img-06-1.webp`}
                      style={{
                        width: '100%',
                      }}
                      alt="landing-img-06-1"
                    />
                  </Row>
                </Row>
              </Col>
              <Col style={{ display: 'flex', alignItems: 'end', flex: 2.5, flexShrink: 0 }}>
                <img
                  src={`${process.env.PUBLIC_URL}/img/landing-img-06-2.webp`}
                  width={'100%'}
                  alt="landing-img-06-2"
                />
              </Col>
            </Row>
          </Row>
          <Row style={{ marginTop: '5rem', display: 'flex', justifyContent: 'center' }}>
            <TypographyText fontSize="2rem" lineHeight="1.2em" letterSpacing="-0.02rem" color="#787878">
              환자 후기
            </TypographyText>
          </Row>
          <Row
            style={{
              marginTop: '3rem',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1.25rem',
            }}
          >
            <Row
              className="landing-page-section-9-text"
              style={{
                display: 'flex',
                background: '#FEF5EF',
                padding: '2.45rem 4rem 2.45rem 4rem',
                justifyContent: 'space-between',
                columnGap: '5rem',
                border: '1px solid #E5E5E5',
              }}
            >
              <Col
                className="multi-line-under-720"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '2rem',
                  rowGap: '1rem',
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/landing-img-07-1.webp`}
                  style={{
                    width: '7.5rem',
                  }}
                  alt="landing-img-07-1"
                />
                <Col
                  className="multi-line-under-1920 not-multi-line-under-720"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.4rem',
                    rowGap: '0.2rem',
                  }}
                >
                  <TypographyText
                    fontSize="1.8rem"
                    lineHeight="1.2em"
                    letterSpacing="-0.02rem"
                    whiteSpace="pre"
                  >
                    {`41세(남)`}
                  </TypographyText>
                  <TypographyText
                    fontSize="1.8rem"
                    lineHeight="1.2em"
                    letterSpacing="-0.02rem"
                    whiteSpace="pre"
                  >
                    {`이OO`}
                  </TypographyText>
                </Col>
              </Col>
              <Col style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <TypographyText
                  className="landing-page-section-9-text-2"
                  fontSize="1.8rem"
                  lineHeight="1.35em"
                  letterSpacing="-0.02rem"
                  whiteSpace="pre-wrap"
                  wordBreak="keep-all"
                >
                  {`“ 증상 입력 후 `}
                  <strong>몸 상태에 대한 변증 결과</strong>
                  {`를 모바일로 받고,
한의학적 진단을 이해할 수 있게 되어 만족했어요.”`}
                </TypographyText>
              </Col>
            </Row>
            <Row
              className="landing-page-section-9-text"
              style={{
                display: 'flex',
                background: '#FEF5EF',
                padding: '2.45rem 4rem 2.45rem 4rem',
                justifyContent: 'space-between',
                columnGap: '5rem',
                border: '1px solid #E5E5E5',
              }}
            >
              <Col
                className="multi-line-under-720"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '2rem',
                  rowGap: '1rem',
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/landing-img-07-2.webp`}
                  style={{
                    width: '7.5rem',
                  }}
                  alt="landing-img-07-2"
                />
                <Col
                  className="multi-line-under-1920 not-multi-line-under-720"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.4rem',
                    rowGap: '0.2rem',
                  }}
                >
                  <TypographyText
                    fontSize="1.8rem"
                    lineHeight="1.2em"
                    letterSpacing="-0.02rem"
                    whiteSpace="pre"
                  >
                    {`33세(여)`}
                  </TypographyText>
                  <TypographyText
                    fontSize="1.8rem"
                    lineHeight="1.2em"
                    letterSpacing="-0.02rem"
                    whiteSpace="pre"
                  >
                    {`조OO`}
                  </TypographyText>
                </Col>
              </Col>
              <Col style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <TypographyText
                  className="landing-page-section-9-text-2"
                  fontSize="1.8rem"
                  lineHeight="1.35em"
                  letterSpacing="-0.02rem"
                  whiteSpace="pre-wrap"
                  wordBreak="keep-all"
                >
                  {`“ 병원에서 직접 말해야 했던 `}
                  <strong>여러가지 불편한 증상</strong>
                  {`들을
모바일로 `}
                  <strong>상세하게 입력</strong>
                  {`할 수 있어 만족스러웠어요.”`}
                </TypographyText>
              </Col>
            </Row>
          </Row>
          <Row style={{ marginTop: '7.5rem', display: 'flex', flexDirection: 'column', rowGap: '1.4rem' }}>
            <Row style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', rowGap: '1.4rem' }}>
              <TypographyText
                className="too-small-for-mobile-level-2"
                fontSize="1.6rem"
                whiteSpace="pre-wrap"
                wordBreak="keep-all"
                lineHeight="1.5em"
                letterSpacing="-0.01rem"
              >
                {`한의학은 구식이다? NO
한의학은 비과학적이다? NO`}
              </TypographyText>
              <TypographyText
                className="too-small-for-mobile-level-2"
                fontSize="2.2rem"
                whiteSpace="pre-wrap"
                wordBreak="keep-all"
                lineHeight="1.545em"
                letterSpacing="-0.01rem"
                fontWeight={700}
              >
                {`한의학은 ‘데이터’다!
첨단 디지털 한의학, 예진과 함께하세요.`}
              </TypographyText>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                style={{
                  minWidth: '18.4rem',
                  paddingLeft: '2.1rem',
                  paddingRight: '2.1rem',
                  height: '3.7rem',
                  cursor: 'pointer',
                  borderRadius: '100px',
                  background: 'linear-gradient(105.22deg, rgba(247, 130, 50, 0.29) -10.49%, #F78232 57.24%)',
                }}
                onClick={() => {
                  if (isAuthCheckLoading) return;
                  if (isAuth) {
                    if (clientStore.metaData?.userRole) {
                      navigate('/patient', {
                        state: {
                          noticeFlag: true,
                        },
                      });
                    } else {
                      navigate('/signinWithoutClinic');
                    }
                  } else {
                    navigate('/signup');
                  }
                }}
              >
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {isAuthCheckLoading ? (
                    <Spinner spinnerDivWidth="w-20" spinnerWidth="w-8" spinnerHeight="w-8" />
                  ) : (
                    <TypographyText
                      fontWeight={700}
                      fontSize="1.6rem"
                      lineHeight="1.2em"
                      letterSpacing="0.005rem"
                      color="#ffffff"
                    >
                      {isAuth ? `‘예진’ 서비스 바로가기` : `‘예진’ 무료로 시작하기`}
                    </TypographyText>
                  )}
                </Col>
              </Button>
            </Row>
          </Row>
          {/* <Row style={{ marginTop: '6rem' }} id={'media-link-img'} onClick={() => setIsOpenMediaModal(true)}>
            <img
              src={`${process.env.PUBLIC_URL}/img/landing-img-08-1.webp`}
              width={'100%'}
              style={{ minWidth: '38.4rem' }}
              className={'default-img'}
              alt="media-link"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/landing-img-08-2.webp`}
              width={'100%'}
              style={{ minWidth: '38.4rem' }}
              className={'hover-img'}
              alt="media-link"
            />
          </Row>
          <ModalWithJustX
            isOpen={isOpenMediaModal}
            width={`48rem`}
            height={`27rem`}
            content={
              <Row
                style={{
                  position: 'relative',
                  width: '100%',
                  paddingBottom: '56.25%',
                }}
              >
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 0,
                  }}
                  src={`https://www.youtube.com/embed/l8Sq5t5_DwY?autoplay=1`}
                  title="Introduce Ye-jin"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen={true}
                />
              </Row>
            }
            cancelFunction={() => setIsOpenMediaModal(false)}
          /> */}
        </Row>
        <Row //section9.5
          style={{
            background: '#F8F8F8',
            padding: '7.5rem 0 6rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: '1.4rem',
          }}
        >
          <TypographyText color="#F78232" fontSize="1.4rem" lineHeight="1.2em" fontWeight={700}>
            {`HOW TO USE`}
          </TypographyText>
          <TypographyText
            fontSize="2.4rem"
            lineHeight="1.33em"
            fontWeight={700}
            whiteSpace="pre-wrap"
            wordBreak="keep-all"
          >
            {`예진 간단 사용법`}
          </TypographyText>
          <Row style={{ marginTop: '1.6rem', display: 'flex', alignItems: 'center' }}>
            <img
              src={`${process.env.PUBLIC_URL}/img/landing-simple-usage.webp`}
              style={{
                width: '50rem',
              }}
              alt="간단사용법"
            />
          </Row>
        </Row>
        <Row //section10
          style={{
            background: '#ffffff',
            padding: '7.5rem 0 6rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: '1.4rem',
          }}
        >
          <TypographyText
            fontSize="2.4rem"
            lineHeight="1.33em"
            fontWeight={700}
            whiteSpace="pre-wrap"
            wordBreak="keep-all"
          >
            {`언제든 편하게 문의하세요.`}
          </TypographyText>
          <Row style={{ display: 'flex', alignItems: 'center', columnGap: '0.6rem' }}>
            <Button
              style={{
                minWidth: '18.4rem',
                paddingLeft: '1.6rem',
                paddingRight: '1.75rem',
                height: '3.7rem',
                border: '1px solid #1d1c1d',
                background: '#ffffff',
                cursor: 'pointer',
                borderRadius: '2px',
              }}
              onClick={() => window.open('http://pf.kakao.com/_xlixodxj/chat', '_blank')}
            >
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  columnGap: '0.5rem',
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/kakao-talk-black-img.webp`}
                  style={{
                    marginTop: '1px',
                    width: '2rem',
                  }}
                  alt="landing-kakao-img"
                />
                <TypographyText
                  fontWeight={700}
                  fontSize="1.6rem"
                  lineHeight="1.2em"
                  letterSpacing="0.005rem"
                >
                  {`고객센터 연결하기`}
                </TypographyText>
              </Col>
            </Button>
          </Row>
        </Row>
        <Footer>
          <Row
            style={{
              background: '#F9FAFB',
              padding: '3rem 5.1% 4.9rem 5.1%',
            }}
          >
            <CompanyInfo mode="LANDING" />
          </Row>
        </Footer>
      </Content>
    </Layout>
  );
};

export default observer(MainPage);
