/* eslint-disable react-hooks/exhaustive-deps */
import * as subscriptions from 'graphql/subscriptions';

import { API, Auth, graphqlOperation } from 'aws-amplify';
import { IconCheck } from '@aws-amplify/ui-react';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import { Observable } from 'zen-observable-ts';
import { UpdateTreatmentInput } from 'API';

import { Button } from 'components/UI/atoms/inputs/Button';
import { Col, Modal, Row, TypographyText, ModalType2, Input } from 'components/UI/atoms';
import DaumPost from 'components/UI/molecules/DaumPost';
import { TreatmentStatus } from 'constant';
import { Gender, UpdatePatientInput } from 'API';
import { css } from '@emotion/css';
import { genderConverter } from 'utils/DataConvertUtil';
import { isEnableTillStaging } from 'config';
import message from 'antd-message';
import { Loading } from 'components/UI/molecules';
import moment from 'moment';
import { observer } from 'mobx-react';
import { useStores } from 'stores/Context';
import { phoneValidationCheck, convertHistoryMessage } from 'utils/util';
import tr from 'repository/TreatmentRepository';

const sendSurveyApi = async (patientID: string, clinicID: string) => {
  const apiName = 'yejinadminrestapi';
  const path = '/sendSurvey';
  const myInit = {
    headers: {
      'Content-Type': 'application/json',
    },
    response: true,
    body: {
      clinicID: clinicID,
      patientID: patientID,
    },
  };
  await API.post(apiName, path, myInit);
};

const PatientInfo = ({ isGlobalLoading }) => {
  const { patientStore: ps, historyStore, clientStore, treatmentStore, clinicStore } = useStores();
  const historySubscription = useRef<any>({
    closed: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [delay, setdelay] = useState(false);
  const [isOpenCancelTreatmentModal, setIsOpenCancelTreatmentModal] = useState<boolean>(false);
  const [isOpenSendingKakaoModal, setIsOpenSendingKakaoModal] = useState(false);
  const [isOpenDeletePatientModal, setIsOpenDeletePatientModal] = useState(false);
  const [isOpenCanNotDeletePatientModal, setIsOpenCanNotDeletePatientModal] = useState(false);
  const [isOpenCreateSurveyModal, setIsOpenCreateSurveyModal] = useState(false);
  const [isOpenEditPatientInfoModal, setIsOpenEditPatientInfoModal] = useState(false);
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false);
  const [name, setName] = useState('');
  const [birth, setBirth] = useState('1990-01-01');
  const [gender, setGender] = useState<string>();
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState<string>('');
  const [editedAddressFlag, setEditedAddressFlag] = useState(false);
  const [validationFlag, setValidationFlag] = useState({
    name: true,
    birth: true,
    phone: true,
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const isInProgress = useMemo(
    () => ps?.patient?.treatmentStatus === TreatmentStatus.InProgress,
    [ps?.patient, isLoading],
  );
  const isAbleCancelTreatment = useMemo(
    () => [TreatmentStatus.InProgress, TreatmentStatus.SurveySubmit].includes(ps?.patient?.treatmentStatus),
    [ps?.patient, isLoading],
  );

  const startTreatment = useCallback(async (clinicID: string, patientID: string) => {
    const response = await API.post('yejinadminrestapi', '/treatment', {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      response: true,
      body: {
        clinicID,
        patientID,
      },
    });
    return response.data;
  }, []);

  const refreshPatient = useCallback(async (patientId) => {
    await ps.getPatient(patientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletePatient = useCallback(async (patientId) => {
    await API.del('yejinadminrestapi', `/patient/${patientId}`, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      // API보니 userRole을 전달해야하는데? userRole의 id? 역할이름?
      response: true,
      body: {},
    });
  }, []);

  const sendSurveyWithKakaoTalk = useCallback(async () => {
    try {
      setdelay(true);
      await sendSurveyApi(id!, clientStore.metaData?.userRole.clinicID!);
      await historyStore.listHistoriesByDate(id!);
      message.success('카카오톡 전송이 완료 되었습니다.', 2500);
    } catch (e) {
      console.log(e);
      message.error('카카오톡 전송이 실패 했습니다.', 2500);
    } finally {
      setdelay(false);
    }
  }, []);

  const registHistorySubscription = useCallback(async ({ token, patientId }) => {
    if (isEnableTillStaging) console.log('환자 히스토리 구독시작');
    historySubscription.current = (
      API.graphql(
        graphqlOperation(subscriptions.onHistoryByPatientID, { patientID: patientId }, token),
      ) as Observable<any>
    ).subscribe({
      next: () => {
        historyStore.listHistoriesByDate(id!);
      },
      error: (error) => console.log(error),
    });
  }, []);

  const updatePatientAPI = useCallback(
    async ({
      patientId,
      patientName,
      patientBirth,
      patientGender,
      patientPhone,
      patientAddress,
      patientDetailAddress,
      clinicId,
    }: {
      patientId: string;
      patientName: string;
      patientBirth: string;
      patientGender: string;
      patientPhone: string;
      patientAddress?: string;
      patientDetailAddress?: string;
      clinicId: string;
    }) => {
      await API.patch('yejinadminrestapi', `/editPatientInfo`, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
        response: true,
        body: {
          id: patientId,
          patientName,
          birth: patientBirth,
          gender: patientGender,
          phone: patientPhone,
          address: patientAddress,
          detailAddress: patientDetailAddress,
          clinicId,
        },
      });
    },
    [],
  );

  useEffect(() => {
    historyStore.listHistoriesByDate(id!);
    // 토큰이 존재하는 경우에만 환자 히스토리 구독 세팅
    Auth.currentSession().then((idToken) => {
      const token = idToken.getIdToken().getJwtToken();
      if (token.length > 0 && id?.length > 0) {
        registHistorySubscription({ token, patientId: id });
      }
    });
    return () => {
      if (!historySubscription.current?.closed) {
        if (isEnableTillStaging) console.log('환자 히스토리 구독종료');
        historySubscription.current?.unsubscribe();
      }
    };
  }, [id]);

  useEffect(() => {
    setName(ps.patient?.patientName!);
    setBirth(ps.patient?.birth!);
    setGender(ps.patient?.gender!);
    setPhone(ps.patient?.phone?.replace('0', '+82'));
    setAddress(ps.patient?.address?.split('||')?.[0] ?? '');
    setDetailAddress(ps.patient?.address?.split('||')?.[1] ?? '');
  }, [ps.patient]);

  return (
    <>
      {(isLoading || isGlobalLoading) && <Loading mode="global" />}
      <Row style={{ display: 'flex', width: '100%', height: '100%' }}>
        <Col style={{ flex: 1, display: 'flex', flexDirection: 'column', paddingTop: '2rem' }}>
          <Row style={{ display: 'flex', marginBottom: '2rem' }}>
            <TypographyText
              fontSize="1.2rem"
              lineHeight="1.2em"
              fontWeight={700}
            >{`기본정보`}</TypographyText>
          </Row>
          <table className="patient-info-table">
            <colgroup>
              <col style={{ width: '7rem', minWidth: '7rem' }} />
              <col style={{ width: 'calc(100% - 7rem)' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <span>{`환자이름`}</span>
                </td>
                <td>
                  <span> {ps.patient?.patientName}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>{`생년월일`}</span>
                </td>
                <td>
                  <span>
                    {ps.patient?.birth && moment(ps.patient.birth, 'YYYY-MM-DD').format('YY/MM/DD')}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>{`성별`}</span>
                </td>
                <td>
                  <span> {genderConverter(ps.patient?.gender)}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>{`전화번호`}</span>
                </td>
                <td>
                  <span>{ps.patient?.phone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}</span>
                </td>
              </tr>
              {ps.patient?.address && (
                <tr>
                  <td>
                    <span>{`주소`}</span>
                  </td>
                  <td>
                    <span> {ps.patient?.address?.split('||').join(' ')}</span>
                  </td>
                </tr>
              )}
              <tr>
                <td></td>
                <td>
                  <Row
                    style={{
                      marginTop: '1.5rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      columnGap: '1rem',
                    }}
                  >
                    <Col style={{ display: 'flex', cursor: 'pointer' }}>
                      <TypographyText
                        style={{
                          color: '#1c252c',
                          fontWeight: 700,
                          fontSize: '1rem',
                          textDecoration: 'underline',
                        }}
                        onClick={() => setIsOpenEditPatientInfoModal(true)}
                      >
                        수정하기
                      </TypographyText>
                    </Col>
                    <Col style={{ display: 'flex', cursor: 'pointer' }}>
                      <TypographyText
                        style={{
                          color: '#1c252c',
                          fontWeight: 700,
                          fontSize: '1rem',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          if (
                            // 진료 대기 또는 진료 중인 환자는 삭제 불가
                            [TreatmentStatus.SurveySubmit, TreatmentStatus.InProgress].includes(
                              ps.patient.treatmentStatus,
                            )
                          ) {
                            setIsOpenCanNotDeletePatientModal(true);
                          } else {
                            setIsOpenDeletePatientModal(true);
                          }
                        }}
                      >
                        {'환자 삭제하기'}
                      </TypographyText>
                    </Col>
                  </Row>
                </td>
              </tr>
            </tbody>
          </table>
          <Row style={{ display: 'flex', marginTop: '3.5rem', marginBottom: '2rem' }}>
            <TypographyText
              fontSize="1.2rem"
              lineHeight="1.2em"
              fontWeight={700}
            >{`환자관리`}</TypographyText>
          </Row>
          <table className="patient-info-table">
            <colgroup>
              <col style={{ width: '7rem', minWidth: '7rem' }} />
              <col style={{ width: 'calc(100% - 7rem)' }} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <span>{`증상설문`}</span>
                </td>
                <td>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      columnGap: '0.4rem',
                    }}
                  >
                    <Col style={{ display: 'flex', cursor: 'pointer' }}>
                      <Button
                        isDisabled={delay || isInProgress}
                        onClick={() => {
                          if (ps.patient.treatmentStatus === TreatmentStatus.SurveySubmit) {
                            // 설문 응답 완료로 진료대기상태에 있는 환자의 경우, 진료대기 취소 후 설문을 다시 할건지 묻는 모달 띄우기
                            setIsOpenSendingKakaoModal(true);
                          } else {
                            sendSurveyWithKakaoTalk();
                          }
                        }}
                        className={(delay || isInProgress) && 'disabledButton'}
                        style={{
                          padding: '0.5rem 1rem',
                          fontSize: '1rem',
                          fontWeight: 700,
                          whiteSpace: 'nowrap',
                          background: '#ffffff',
                          border: '1px solid #346AFF',
                          borderRadius: '3px',
                          color: '#346AFF',
                        }}
                      >
                        카카오 링크 전송
                      </Button>
                    </Col>
                    <Col style={{ display: 'flex', cursor: 'pointer' }}>
                      <Button
                        isDisabled={delay || isInProgress}
                        onClick={() => {
                          if (ps.patient.treatmentStatus === TreatmentStatus.SurveySubmit) {
                            setIsOpenCreateSurveyModal(true);
                          } else {
                            navigate(`survey`);
                          }
                        }}
                        className={(delay || isInProgress) && 'disabledButton'}
                        style={{
                          padding: '0.5rem 1rem',
                          fontSize: '1rem',
                          fontWeight: 700,
                          whiteSpace: 'nowrap',
                          background: '#ffffff',
                          border: '1px solid #346AFF',
                          borderRadius: '3px',
                          color: '#346AFF',
                        }}
                      >
                        직접 입력
                      </Button>
                    </Col>
                  </Row>
                </td>
              </tr>
              {isInProgress && (
                <tr>
                  <td></td>
                  <td>
                    <Row style={{ display: 'flex', marginTop: '0.4rem' }}>
                      <TypographyText
                        style={{
                          color: '#DF635C',
                          fontSize: '0.8rem',
                          lineHeight: '1em',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'keep-all',
                          textIndent: '0.2rem',
                        }}
                      >
                        진료 중에는 증상 설문 입력이 불가합니다. 진료를 완료해 주세요.
                      </TypographyText>
                    </Row>
                  </td>
                </tr>
              )}
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span>{`진료`}</span>
                </td>
                <td>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      columnGap: '0.4rem',
                    }}
                  >
                    {['MASTER', 'DOCTOR'].includes(clinicStore.currentUserRole) && (
                      <Col style={{ display: 'flex', cursor: 'pointer' }}>
                        <Button
                          isDisabled={delay || !isAbleCancelTreatment}
                          onClick={async () => {
                            if (isInProgress) {
                              navigate(`/treatment/${ps.patient.treatmentID}`);
                            } else {
                              try {
                                await setIsLoading(true);
                                const { body } = await startTreatment(
                                  clientStore.metaData?.userRole.clinicID,
                                  id,
                                );
                                const resBody = JSON.parse(body);
                                await setIsLoading(false);
                                navigate(`/treatment/${resBody.treatmentID}`);
                              } catch (e) {
                                await setIsLoading(false);
                                message.error(`진료시작 과정에서 문제가 발생했습니다.`, 2500);
                              }
                            }
                          }}
                          className={(delay || !isAbleCancelTreatment) && 'disabledButton'}
                          style={{
                            padding: '0.5rem 1rem',
                            fontSize: '1rem',
                            fontWeight: 700,
                            whiteSpace: 'nowrap',
                            background: '#ffffff',
                            border: '1px solid #346AFF',
                            borderRadius: '3px',
                            color: '#346AFF',
                          }}
                        >
                          {isInProgress ? '진료 바로가기' : '진료 시작하기'}
                        </Button>
                      </Col>
                    )}
                    <Col style={{ display: 'flex', cursor: 'pointer' }}>
                      <Button
                        isDisabled={delay || !isAbleCancelTreatment}
                        onClick={() => setIsOpenCancelTreatmentModal(true)}
                        className={(delay || !isAbleCancelTreatment) && 'disabledButton'}
                        style={{
                          padding: '0.5rem 1rem',
                          fontSize: '1rem',
                          fontWeight: 700,
                          whiteSpace: 'nowrap',
                          background: '#ffffff',
                          border: '1px solid #346AFF',
                          borderRadius: '3px',
                          color: '#346AFF',
                        }}
                      >
                        진료 취소
                      </Button>
                    </Col>
                  </Row>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            borderLeft: '1px solid #e5e5e5',
            paddingTop: '2rem',
            paddingLeft: '2rem',
            rowGap: '2.4rem',
          }}
        >
          <TypographyText fontSize="1.2rem" lineHeight="1.2em" fontWeight={700}>{`타임라인`}</TypographyText>
          <Row
            style={{
              display: 'flex',
              justifyContent: historyStore.histories?.length < 5 ? 'flex-end' : 'flex-start',
              flexDirection: 'column-reverse',
              flex: '1 1 0',
              paddingBottom: '30vh',
              overflowY: historyStore.histories?.length < 5 ? 'hidden' : 'auto',
            }}
            className={css`
              &::-webkit-scrollbar {
                background-color: white;
                width: 0.35rem;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #ebebeb;
                border-radius: 1rem;
              }
            `}
          >
            {historyStore.histories.map((history, index) => {
              return (
                <Row
                  style={{ display: 'flex', columnGap: '2.4rem', alignItems: 'flex-start' }}
                  key={history.id}
                >
                  <Row
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      height: '4.5rem',
                    }}
                  >
                    <div
                      className="patient-history-dot"
                      style={{
                        backgroundColor: index === 0 ? '#1C252C' : '#E5E5E5',
                      }}
                    >
                      {index === 0 && <IconCheck color={'white'} fontSize={'0.8rem'} />}
                    </div>
                    {index > 0 && (
                      <Row
                        style={{ flex: '1 1 0', width: '0.25rem', height: '2.1rem', background: '#ebebeb' }}
                      />
                    )}
                  </Row>
                  <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.45rem' }}>
                    <Row style={{ position: 'relative', display: 'flex', alignItems: 'flex-start' }}>
                      <TypographyText fontSize={'1rem'} lineHeight="1em">
                        {convertHistoryMessage({
                          historyData: history,
                          convertMode: 'longMessage',
                        })}
                      </TypographyText>
                      {history.type === 'Survey' && isEnableTillStaging && (
                        <Row style={{ position: 'absolute', right: '-4rem', top: '-0.3rem' }}>
                          <a target={'_blank'} href={history.typeID!} rel="noreferrer">
                            <TypographyText fontSize={'1rem'} lineHeight="1em" color="blue">
                              설문링크
                            </TypographyText>
                          </a>
                        </Row>
                      )}
                    </Row>
                    <TypographyText fontSize={'0.8rem'} lineHeight="1.2em" color={'#AFAFAF'}>
                      {moment(history.createdAt).format('YY/MM/DD HH:mm')}
                    </TypographyText>
                  </Row>
                </Row>
              );
            })}
          </Row>
        </Col>
      </Row>
      {isOpenCancelTreatmentModal && (
        <Modal
          contentMsg={`${ps.patient.patientName} 환자의 진료를 취소하시겠습니까?`}
          cancelMsg="닫기"
          okMsg="네, 취소하겠습니다."
          additionalContent={
            <Row style={{ marginTop: '0.4rem', lineHeight: '0.95rem' }}>
              <TypographyText
                fontSize="0.8rem"
                letterSpacing="-0.02em"
                color="#AFAFAF"
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {`다시 증상을 입력하면 자동으로 진료목록에 등록됩니다.`}
              </TypographyText>
            </Row>
          }
          okFunction={async () => {
            try {
              await setIsLoading(true);
              if (ps.patient.treatmentID) {
                await tr.updateTreatment({
                  id: ps.patient.treatmentID,
                  isDeleted: true,
                } as UpdateTreatmentInput);
              }
              await Promise.all([
                ps.updatePatient({
                  id: ps.patient.id,
                  userID: null,
                  surveyID: null,
                  treatmentID: null,
                  treatmentStatus: TreatmentStatus.None,
                  recentActivity: new Date().toISOString(),
                  recentActivityCode: `AM006`,
                } as UpdatePatientInput),
                historyStore.createHistory({
                  patientID: id,
                  clinicID: clientStore.metaData?.userRole.clinic?.id!, // mobx에서 현재 세션에서의 clinicID를 가져옴
                  typeID: ps.patient?.treatmentID || '',
                  messageCode: 'AM006',
                }),
              ]);

              await refreshPatient(id);
              message.success(`${ps.patient.patientName} 환자의 진료가 취소되었습니다.`, 2500);
            } catch (e) {
              message.error(`진료 취소 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 2500);
            } finally {
              setIsOpenCancelTreatmentModal(false);
              setIsLoading(false);
            }
          }}
          cancelFunction={() => {
            setIsOpenCancelTreatmentModal(false);
          }}
        />
      )}
      {isOpenDeletePatientModal && (
        <Modal
          contentMsg={`${ps.patient?.patientName} 환자를 삭제하시겠습니까?`}
          confirmMsg="삭제된 환자는 되돌릴 수 없음을 확인하였습니다."
          confirmFlag
          cancelMsg="취소"
          okMsg="네, 삭제하겠습니다."
          okFunction={async () => {
            try {
              await setIsLoading(true);
              await setIsOpenDeletePatientModal(false);
              await deletePatient(id); // 환자 삭제 API 호출
              message.success(`${ps.patient?.patientName} 환자가 삭제되었습니다.`, 2500);
              navigate(`/patient`); // 삭제 성공 시 환자 목록으로 이동
            } catch (e) {
              message.error(`환자 삭제 과정에서 문제가 발생했습니다.`, 2500);
              setIsOpenDeletePatientModal(false); // 환자 삭제 실패 시 모달 닫기
              setIsLoading(false);
            }
          }}
          cancelFunction={() => setIsOpenDeletePatientModal(false)}
        />
      )}
      {isOpenCanNotDeletePatientModal && (
        <Modal
          contentMsg={
            ps.patient.treatmentStatus === TreatmentStatus.SurveySubmit
              ? `진료 대기 중인 환자입니다.
진료완료 또는 진료취소 후 환자삭제가 가능합니다.`
              : `진료 중인 환자입니다.
진료완료 또는 진료취소 후 환자삭제가 가능합니다.`
          }
          cancelMsg="취소"
          okMsg="진료 바로가기"
          okFunction={async () => {
            navigate(`/treatment`);
          }}
          cancelFunction={() => setIsOpenCanNotDeletePatientModal(false)}
        />
      )}
      {isOpenSendingKakaoModal && (
        <Modal
          contentMsg={`진료 대기 중인 설문이 있습니다.
진료취소 후 증상설문 링크를 전송하시겠습니까?`}
          cancelMsg="닫기"
          okMsg="네, 전송하겠습니다."
          okFunction={async () => {
            try {
              await setIsOpenSendingKakaoModal(false);
              await setIsLoading(true);
              await Promise.all([
                historyStore.createHistory({
                  patientID: id!,
                  clinicID: clientStore.metaData?.userRole.clinic?.id!, // mobx에서 현재 세션에서의 clinicID를 가져옴
                  messageCode: 'AM006',
                }),
                ps.updatePatient({
                  id: id,
                  surveyID: '',
                  treatmentID: '',
                  treatmentStatus: TreatmentStatus.None,
                  recentActivity: new Date().toISOString(),
                  recentActivityCode: `AM006`,
                } as UpdatePatientInput),
              ]);
              refreshPatient(id);
              await sendSurveyWithKakaoTalk();
            } catch (e) {
              console.log(e);
            } finally {
              setIsLoading(false);
            }
          }}
          cancelFunction={() => setIsOpenSendingKakaoModal(false)}
        />
      )}
      {isOpenCreateSurveyModal && (
        <Modal
          contentMsg={`진료 대기중인 증상이 있습니다. 진료취소 후 새로운 증상을 입력하시겠습니까?`}
          cancelMsg="닫기"
          okMsg="네, 입력하겠습니다."
          okFunction={async () => {
            try {
              await setIsOpenSendingKakaoModal(false);
              await setIsLoading(true);
              await Promise.all([
                historyStore.createHistory({
                  patientID: id!,
                  clinicID: clientStore.metaData?.userRole.clinic?.id!, // mobx에서 현재 세션에서의 clinicID를 가져옴
                  message: '진료가 취소되었습니다.',
                }),
                ps.updatePatient({
                  id: id,
                  surveyID: '',
                  treatmentID: '',
                  treatmentStatus: TreatmentStatus.None,
                  recentActivity: new Date().toISOString(),
                  recentActivityCode: `AM006`,
                } as UpdatePatientInput),
              ]);
              navigate(`survey`);
            } catch (e) {
              console.log(e);
              setIsLoading(false);
            }
          }}
          cancelFunction={() => setIsOpenCreateSurveyModal(false)}
        />
      )}
      <ModalType2
        isOpen={isOpenEditPatientInfoModal && !isOpenAddressModal}
        title={'환자정보 수정하기'}
        content={
          <Row flex flexDirection="column">
            <table style={{ borderCollapse: 'separate', borderSpacing: '0 1rem' }}>
              <colgroup>
                <col />
                <col style={{ width: '26rem' }} />
              </colgroup>
              <tbody>
                <tr>
                  <td style={{ verticalAlign: 'top', paddingTop: '1rem' }}>
                    <TypographyText
                      style={{
                        color: '#1d1c1d',
                        fontWeight: 700,
                        fontSize: '1rem',
                        lineHeight: '1.2rem',
                        letterSpacing: '0.5%',
                      }}
                    >
                      환자 이름
                    </TypographyText>
                  </td>
                  <td>
                    <Input
                      style={{
                        height: '3.2rem',
                        width: '100%',
                        border: '1px solid #afafaf',
                        borderRadius: '3px',
                        textIndent: '0.4rem',
                        fontSize: '1rem',
                        outline: 'none',
                      }}
                      className={css`
                        &::placeholder {
                          color: #afafaf;
                        }
                      `}
                      value={name}
                      placeholder="환자 이름"
                      onChange={(e) => {
                        if (e.target.value?.length) {
                          setValidationFlag({
                            ...validationFlag,
                            name: true,
                          });
                        } else {
                          setValidationFlag({
                            ...validationFlag,
                            name: undefined,
                          });
                        }
                        setName(e.target.value);
                      }}
                      autoComplete="off"
                    />
                    {!validationFlag.name && (
                      <Row style={{ marginBottom: '-0.5rem' }}>
                        <TypographyText yjTypeTypographyText="survey-validation">
                          환자 이름을 입력해주세요.
                        </TypographyText>
                      </Row>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top', paddingTop: '1rem' }}>
                    <TypographyText
                      style={{
                        color: '#1d1c1d',
                        fontWeight: 700,
                        fontSize: '1rem',
                        lineHeight: '1.2rem',
                        letterSpacing: '0.5%',
                      }}
                    >
                      생년월일
                    </TypographyText>
                  </td>
                  <td>
                    <Col>
                      <input
                        autoComplete="off"
                        style={{
                          outline: 'none',
                          width: '100%',
                          height: '3.2rem',
                          border: '1px solid #afafaf',
                          borderRadius: '3px',
                          textIndent: '0.35rem',
                          paddingRight: '0.7rem',
                        }}
                        value={birth}
                        max={moment().format('YYYY-MM-DD')}
                        type={'date'}
                        onChange={(e) => {
                          if (e.target.value?.length) {
                            if (e.target.value > moment().format('YYYY-MM-DD')) {
                              setValidationFlag({
                                ...validationFlag,
                                birth: false,
                              });
                            } else {
                              setValidationFlag({
                                ...validationFlag,
                                birth: true,
                              });
                            }
                          } else {
                            setValidationFlag({
                              ...validationFlag,
                              birth: undefined,
                            });
                          }
                          setBirth(e.target.value);
                        }}
                      />
                    </Col>
                    {!validationFlag.birth && (
                      <Row style={{ marginBottom: '-0.5rem' }}>
                        <TypographyText yjTypeTypographyText="survey-validation">
                          {validationFlag.birth === false
                            ? `${moment().format('YYYY-MM-DD')} 이전의 생년월일을 입력해주세요.`
                            : `생년월일을 입력해주세요.`}
                        </TypographyText>
                      </Row>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top', paddingTop: '1rem' }}>
                    <TypographyText
                      style={{
                        color: '#1d1c1d',
                        fontWeight: 700,
                        fontSize: '1rem',
                        lineHeight: '1.2rem',
                        letterSpacing: '0.5%',
                      }}
                    >
                      성별
                    </TypographyText>
                  </td>
                  <td>
                    <div className="relative">
                      <select
                        className="select-input"
                        style={{
                          outline: 'none',
                          width: '100%',
                          height: '3.2rem',
                          border: '1px solid #afafaf',
                          borderRadius: '3px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          textIndent: '0.9rem',
                        }}
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value={Gender.MALE}>남성</option>
                        <option value={Gender.FEMALE}>여성</option>
                      </select>
                      <img
                        className="absolute"
                        src={`${process.env.PUBLIC_URL}/img/select-arrow-icon.webp`}
                        style={{ top: '1.3rem', right: '1rem', width: '0.6rem' }}
                        alt="select-arrow-icon"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top', paddingTop: '1rem' }}>
                    <TypographyText fontWeight={700}>{'전화번호'}</TypographyText>
                  </td>
                  <td>
                    <PhoneInput
                      style={{
                        height: '3.2rem',
                        border: '1px solid #afafaf',
                        borderRadius: '3px',
                        fontSize: '1rem',
                        lineHeight: '1rem',
                        textIndent: '1rem',
                        outline: 'none',
                        width: '100%',
                      }}
                      country={'KR'}
                      placeholder={'휴대 전화번호 (숫자만 입력)'}
                      value={phone}
                      onChange={(e) => {
                        if (!phoneValidationCheck(e?.replace('+82', '0'))) {
                          setValidationFlag({
                            ...validationFlag,
                            phone: false,
                          });
                        } else {
                          setValidationFlag({
                            ...validationFlag,
                            phone: true,
                          });
                        }
                        setPhone(e);
                      }}
                    />
                    {!validationFlag.phone && (
                      <Row style={{ marginBottom: '-0.5rem' }}>
                        <TypographyText yjTypeTypographyText="survey-validation">
                          {validationFlag.phone === false
                            ? `휴대 전화번호 양식으로 입력해주세요.`
                            : `전화번호를 입력해주세요.`}
                        </TypographyText>
                      </Row>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top', paddingTop: '1rem' }}>
                    <TypographyText fontWeight={700}>{'주소(선택)'}</TypographyText>
                  </td>
                  <td>
                    {editedAddressFlag && (
                      <Row flex flexDirection="column" style={{ rowGap: '0.5rem' }}>
                        <Col
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            height: '3.2rem',
                            border: '1px solid #afafaf',
                            background: '#f2f2f2',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            borderRadius: '3px',
                            marginRight: '0.5rem',
                          }}
                        >
                          <TypographyText color={`#1d1c1d`} lineHeight="1.2em">
                            {address}
                          </TypographyText>
                        </Col>
                        <Col style={{}}>
                          <Input
                            style={{
                              height: '3.2rem',
                              width: `100%`,
                              border: '1px solid #afafaf',
                              borderRadius: '3px',
                              textIndent: '0.4rem',
                              fontSize: '1rem',
                              outline: 'none',
                            }}
                            className={css`
                              &::placeholder {
                                color: #afafaf;
                              }
                            `}
                            value={detailAddress}
                            placeholder="상세 주소"
                            onChange={(e) => {
                              setDetailAddress(e.target.value);
                            }}
                            autoComplete="off"
                          />
                        </Col>
                        <Row style={{ marginBottom: '0.5rem' }}>
                          <TypographyText
                            color="#afafaf"
                            letterSpacing="0.02rem"
                            fontSize={'1rem'}
                            lineHeight="1.2em"
                          >
                            {'해당 주소는 처방 약재가 배송될 주소입니다.'}
                          </TypographyText>
                        </Row>
                      </Row>
                    )}
                    <Col
                      style={{
                        flex: 1,
                        display: 'flex',
                        columnGap: '0.5rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '3.2rem',
                        border: '1px solid #afafaf',
                        borderColor: '#afafaf',
                        borderRadius: '3px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setIsOpenAddressModal(true);
                      }}
                    >
                      <Col flexFlag>
                        <TypographyText
                          yjTypeTypographyText="sub-title"
                          fontWeight={700}
                          letterSpacing="0.02rem"
                          fontSize={'1rem'}
                          lineHeight="1.2em"
                        >
                          {editedAddressFlag ? '주소 재검색' : '주소 찾기'}
                        </TypographyText>
                      </Col>
                      <Col flexFlag>
                        <img
                          src={`${process.env.PUBLIC_URL}/img/search-black-icon.webp`}
                          style={{ width: '1rem' }}
                          alt="search-black-icon"
                        />
                      </Col>
                    </Col>
                    {!editedAddressFlag && (
                      <Row style={{ marginTop: '0.3rem' }}>
                        <TypographyText
                          color="#afafaf"
                          letterSpacing="0.02rem"
                          fontSize={'1rem'}
                          lineHeight="1.2em"
                        >
                          {'해당 주소는 처방 약재가 배송될 주소입니다.'}
                        </TypographyText>
                      </Row>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
        }
        width={40}
        okMsg={'수정하기'}
        okFunction={async () => {
          if (validationFlag.name && validationFlag.birth && validationFlag.phone) {
            try {
              setIsLoading(true);
              setIsOpenEditPatientInfoModal(false);
              await updatePatientAPI({
                patientId: id,
                patientName: name,
                patientBirth: birth,
                patientGender: gender,
                patientPhone: phone,
                patientAddress: address,
                patientDetailAddress: detailAddress,
                clinicId: clientStore.metaData?.userRole.clinic?.id!,
              });
              await ps.getPatient(id!);
              message.success('회원정보가 수정되었습니다.', 2500);
            } catch (e) {
              console.log(e);
              message.error('회원정보 수정에 문제가 발생했습니다.', 2500);
            } finally {
              setIsLoading(false);
            }
          }
        }}
        cancelFunction={() => {
          setName(ps.patient?.patientName!);
          setBirth(ps.patient?.birth!);
          setGender(ps.patient?.gender!);
          setPhone(ps.patient?.phone?.replace('0', '+82'));
          setAddress(ps.patient?.address?.split('||')?.[0] ?? '');
          setDetailAddress(ps.patient?.address?.split('||')?.[1] ?? '');
          setEditedAddressFlag(false);
          setIsOpenEditPatientInfoModal(false);
        }}
      />
      <ModalType2
        isOpen={isOpenAddressModal}
        cancelMsg="닫기"
        cancelFunction={() => setIsOpenAddressModal(false)}
        content={
          <Row style={{ marginBottom: '0' }}>
            <DaumPost
              setIsOpenAddressModal={(value) => {
                setIsOpenAddressModal(value);
                setDetailAddress('');
                setEditedAddressFlag(true);
              }}
              setAddress={setAddress}
            />
          </Row>
        }
        contentNonePaddingFlag={true}
        width={'600px'}
        contentHeight={'445px'}
      />
    </>
  );
};
export default observer(PatientInfo);
