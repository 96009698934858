import React, { ReactNode, useState, useMemo } from 'react';
import { Heading } from '@aws-amplify/ui-react';
import _ from 'lodash';
import moment from 'moment';

import { Row, ModalWithJustX } from 'components/UI/atoms';

import SideBar from '../UI/organisms/sidebar/SideBar';
import { Loading } from 'components/UI/molecules';

interface TreatmentLayoutProps {
  treatmentData?: any;
  content: ReactNode;
  footer?: ReactNode;
  sideType: number;
  loading?: boolean;
  serviceGuideLink?: string;
  youtubeLinkParam?: {
    title: string;
    src: string;
  };
  isFullScreen?: boolean;
  setIsTestMode?: (mode: string) => void;
  isTestMode?: string;
  mode?: string;
}

const TreatmentLayout = ({
  treatmentData,
  content,
  sideType,
  footer,
  loading,
  serviceGuideLink,
  youtubeLinkParam,
  isFullScreen,
  isTestMode,
  setIsTestMode,
  mode,
}: TreatmentLayoutProps) => {
  const [isOpenMediaModal, setIsOpenMediaModal] = useState(false);
  const {
    name = '',
    birth = '',
    gender = '',
    age = '',
  } = useMemo(() => {
    if (!treatmentData) return {};

    const { name, birth, gender } = treatmentData ?? {};
    let convertedGender;
    switch (gender) {
      case 'MALE':
        convertedGender = '남';
        break;
      case 'FEMALE':
        convertedGender = '여';
        break;
      default:
        convertedGender = '성별미상';
        break;
    }
    const age = moment().diff(moment(birth), 'years');

    return {
      name,
      birth,
      gender: convertedGender,
      age,
    };
  }, [treatmentData]);

  return (
    <Row style={{ height: '100%' }}>
      {!isFullScreen && mode !== 'prescriptionEdit' && <SideBar type={sideType} />}
      {loading ? (
        <Loading mode={mode === 'prescriptionEdit' ? 'withoutSideNav' : ''} />
      ) : (
        <Row
          style={{
            height: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: mode === 'prescriptionEdit' || isFullScreen ? '100%' : 'calc(100% - 14rem)', // 사이드바 너비 14rem
            marginLeft: mode === 'prescriptionEdit' || isFullScreen ? '0' : '14rem', // 사이드바 너비 14rem
          }}
        >
          <Row
            style={{
              height: isFullScreen
                ? '100vh'
                : mode === 'prescriptionEdit'
                ? 'calc(100vh - 2.85rem)'
                : 'calc(100vh - 5rem)', // footer 5rem
              display: 'flex',
              flexDirection: 'column',
              padding: '1.8rem 2.5% 0',
              position: 'relative',
            }}
          >
            {isFullScreen && (
              <div className="border-t border-e5e5e5 absolute top-24 left-0 w-full h-px "></div>
            )}
            <Row
              style={{
                height: '2.4rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Heading fontWeight={700} fontSize={'1.8rem'}>
                {`${name} ${birth} (${gender}/만 ${age}세)`}
              </Heading>
              <div className="flex items-center">
                {/* {isEnableTillStaging && (
                  <>
                    <GuideButton
                      buttonText={`Test Mode ${isTestMode !== 'true' ? 'On' : 'Off'}`}
                      onClickFunc={() => {
                        if (isTestMode !== 'true') {
                          sessionStorage.setItem('treatmentAnalysisTestMode', 'true');
                          setIsTestMode('true');
                        } else {
                          sessionStorage.setItem('treatmentAnalysisTestMode', 'false');
                          setIsTestMode('false');
                          sessionStorage.setItem('herbFilterTestMode', 'false');
                        }
                      }}
                    />
                  </>
                )} */}
                {/* {!isFullScreen &&
                  (serviceGuideLink ||
                    (!_.isEmpty(youtubeLinkParam) && (
                      <Row flex style={{ columnGap: '0.5rem' }}>
                        <GuideButton
                          buttonText="동영상 가이드"
                          onClickFunc={() => {
                            if (!_.isEmpty(youtubeLinkParam)) {
                              setIsOpenMediaModal(true);
                            } else if (serviceGuideLink) {
                              window.open(serviceGuideLink, '_blank');
                            }
                          }}
                        />
                      </Row>
                    )))} */}
              </div>
            </Row>
            <Row
              style={{
                flex: 1,
                height: 'calc(100vh - 5rem - 2.4rem - 1.8rem)',
                marginTop: isFullScreen && '1.8rem',
              }}
            >
              {content}
            </Row>
          </Row>
          {!isFullScreen && (
            <Row
              style={{
                position: 'fixed',
                width: mode === 'prescriptionEdit' || isFullScreen ? '100%' : 'calc(100% - 14rem)', // 사이드바 너비 14rem
                bottom: 0,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                background: '#ffffff',
                height: '5rem',
                paddingLeft: '2.4rem',
                paddingRight: '2rem',
                borderTop: '1px solid #e5e5e5',
                zIndex: 1,
              }}
            >
              {footer}
            </Row>
          )}
        </Row>
      )}
      {!_.isEmpty(youtubeLinkParam) && (
        <ModalWithJustX
          isOpen={isOpenMediaModal}
          width={`48rem`}
          height={`27rem`}
          content={
            <Row
              style={{
                position: 'relative',
                width: '100%',
                paddingBottom: '56.25%',
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 0,
                }}
                src={youtubeLinkParam.src}
                title={youtubeLinkParam.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen={true}
              />
            </Row>
          }
          cancelFunction={() => setIsOpenMediaModal(false)}
        />
      )}
    </Row>
  );
};

export default TreatmentLayout;
