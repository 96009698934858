import _ from 'lodash';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { TypographyText, Row, Col } from 'components/UI/atoms';
import { getHerb } from 'utils/HerbCode';
import { observer } from 'mobx-react';
import { useStores } from 'stores/Context';

import { TooltipCard } from 'components/UI/molecules';
import { treatmentModeMap, 기준처방분류코드_질환_Map } from 'constant/index';

const CommonPrescriptionTable = ({
  treatment,
  titleHiddenFlag = false,
  topRightButtonText,
  topRightButtonFunc,
  topRightButtonTheme = 'primary',
  treatmentMode, // 처방하기 버튼의 사용가능 유무 조건에 필요
}: {
  treatment: any;
  titleHiddenFlag?: boolean;
  topRightButtonText?: string;
  topRightButtonFunc?: () => void;
  treatmentMode?: string;
  topRightButtonTheme?: 'primary' | 'black';
}) => {
  const { prescriptionStore: ps } = useStores();
  const prescribedFlag = !_.isEmpty(treatment.prescribedHerb) || !_.isEmpty(treatment.prescribedRecipe);
  const processedHerbMode = !_.isEmpty(treatment.prescribedRecipe);
  const [isOpenTooltip, setIsOpenTooltip] = useState('');

  const topRightButtonTextStyle = useMemo(() => {
    if (topRightButtonTheme === 'black') {
      return {
        color: '#1D1C1D',
        fontWeight: 700,
        fontSize: '0.8rem',
        lineHeight: '1.2em',
        whiteSpace: 'pre',
      };
    } else {
      return {
        color: '#ffffff',
        fontWeight: 700,
        fontSize: '0.8rem',
        lineHeight: '1.2em',
        whiteSpace: 'pre',
      };
    }
  }, [topRightButtonTheme]);

  const topRightButtonStyle = useMemo(() => {
    if (topRightButtonTheme === 'black') {
      return {
        border: '1px solid #afafaf',
        borderRadius: '3px',
        background: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.45rem 1rem',
        flexShrink: 0,
        marginBottom: '-0.35rem',
      };
    } else {
      return {
        border: '1px solid #346AFF',
        borderRadius: '3px',
        background: '#346AFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.45rem 1rem',
        flexShrink: 0,
        marginBottom: '-0.25rem',
      };
    }
  }, [topRightButtonTheme]);

  const Table = useCallback(
    ({ processedHerbMode, prescribedRecipe, prescriptionCount }) => {
      const amountField = processedHerbMode ? 'amt' : 'amount';
      const koreanNameField = processedHerbMode ? 'koreanName' : 'name';
      const amountWeight = processedHerbMode ? 1 : prescriptionCount;
      const recipeLength = prescribedRecipe?.length ?? 0;
      const body = [
        <tr key="herb-th-0">
          <th>
            <TypographyText fontSize="0.8rem">{`약재명`}</TypographyText>
          </th>
          <th>
            <TypographyText fontSize="0.8rem">{`1첩용량(g)`}</TypographyText>
          </th>
          <th>
            <TypographyText fontSize="0.8rem">{`총약재량(g)`}</TypographyText>
          </th>
        </tr>,
      ];

      for (let i = 0; i < recipeLength; i++) {
        const herb = processedHerbMode
          ? ps.yejinHerbForSearchByProcessingCode.get(prescribedRecipe[i]?.pc)
          : getHerb(prescribedRecipe[i].code!);
        const amount1 = prescribedRecipe[i]?.[amountField] / 1000 / amountWeight;
        body.push(
          <tr key={`herb-body-${i}`}>
            <td>
              <TypographyText fontSize="0.9rem" whiteSpace="pre">
                {herb[koreanNameField]}
              </TypographyText>
            </td>
            <td>
              <TypographyText fontSize="0.9rem" whiteSpace="pre">
                {_.round(amount1, 2)}
              </TypographyText>
            </td>
            <td>
              <TypographyText fontSize="0.9rem" whiteSpace="pre">
                {_.round(amount1 * prescriptionCount, 2)}
              </TypographyText>
            </td>
          </tr>,
        );
      }

      return (
        <table className="prescription-history-table herb-info-table ">
          <colgroup>
            <col style={{ width: 'calc(100% / 3)' }} />
            <col style={{ width: 'calc(100% / 3)' }} />
            <col style={{ width: 'calc(100% / 3)' }} />
          </colgroup>
          <tbody>{body}</tbody>
        </table>
      );
    },
    [ps.yejinHerbForSearchByProcessingCode],
  );

  const 비급여Flag = useMemo(
    () => !treatment?.prescriptionData?.insrCCode,
    [treatment?.prescriptionData?.insrCCode],
  );
  const 급여진료모드Flag = useMemo(() => ![undefined, 'GENERAL'].includes(treatmentMode), [treatmentMode]);
  const disabledBtnFlag = useMemo(
    () =>
      급여진료모드Flag && // 현재 진료가 비급여 모드가 아니면서
      (비급여Flag || // 처방에 기준처방분류코드가 없거나 (비급여)
        !기준처방분류코드_질환_Map.get(treatment?.prescriptionData?.insrCCode)?.includes(treatmentMode)), // 질환에 해당하는 기준처방분류코드가 없으면
    [비급여Flag, 급여진료모드Flag],
  );

  return (
    <>
      {treatment && (
        <>
          <Row key={treatment.id}>
            <Col>
              <Row style={{ display: 'block' }}>
                {!titleHiddenFlag && (
                  <Col>
                    <TypographyText yjTypeTypographyText="sub-title" fontWeight={700} fontSize={'1.2rem'}>
                      {`처방 기록`}
                    </TypographyText>
                  </Col>
                )}
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '0.5rem',
                    marginBottom: '0.75rem',
                  }}
                >
                  {prescribedFlag && (
                    <Row
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '0.5rem',
                      }}
                    >
                      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Col style={{ display: 'flex', alignItems: 'center', columnGap: '0.75rem' }}>
                          <TypographyText
                            fontSize={'1.2rem'}
                            lineHeight="1.2em"
                            fontWeight={700}
                            whiteSpace={'pre'}
                          >
                            {`${moment(treatment?.treatmentedAt).format('YY/MM/DD')}${
                              _.isEmpty(treatment.customHerbalFormulaData)
                                ? treatment?.prescriptionData?.kn
                                  ? ` | ${treatment.prescriptionData.kn}`
                                  : ''
                                : ` | ${treatment?.customHerbalFormulaData?.kn}`
                            }`}
                          </TypographyText>
                          <Col style={{ display: 'flex', alignItems: 'center' }}>
                            <Row
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #afafaf',
                                padding: '0.2rem 0.5rem',
                                background: '#1C252C',
                              }}
                            >
                              <TypographyText
                                color="#ffffff"
                                fontSize="0.8rem"
                                lineHeight="1.2em"
                                fontWeight={700}
                                letterSpacing="0.05em"
                              >
                                {`${treatment.amount ?? 0}첩`}
                              </TypographyText>
                            </Row>
                          </Col>
                        </Col>
                        {topRightButtonText && topRightButtonFunc && (
                          <Col
                            id={`${treatment.id}-btn`}
                            style={{ position: 'relative' }}
                            onMouseOver={() => {
                              if (disabledBtnFlag) {
                                setIsOpenTooltip(`${treatment.id}`);
                              }
                            }}
                            onMouseOut={() => {
                              if (disabledBtnFlag) {
                                setIsOpenTooltip('');
                              }
                            }}
                          >
                            <button
                              style={{
                                ...topRightButtonStyle,
                                border: disabledBtnFlag ? '1px solid #c4c4c4' : topRightButtonStyle.border,
                                background: disabledBtnFlag ? '#afafaf' : topRightButtonStyle.background,
                              }}
                              onClick={() => {
                                if (!disabledBtnFlag) {
                                  topRightButtonFunc();
                                }
                              }}
                            >
                              <TypographyText
                                //@ts-ignore
                                style={{
                                  ...topRightButtonTextStyle,
                                }}
                              >
                                {topRightButtonText}
                              </TypographyText>
                            </button>

                            {disabledBtnFlag && (
                              <TooltipCard
                                zIndex={999}
                                isOpen={isOpenTooltip === `${treatment.id}`}
                                targetComponentId={`${treatment.id}-btn`}
                                topRem={-3.75}
                                leftRem={0}
                                minWidth={'4rem'}
                                description={[
                                  <Row
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      rowGap: '0.3rem',
                                    }}
                                  >
                                    <TypographyText
                                      fontSize="0.8rem"
                                      whiteSpace="pre-wrap"
                                      wordBreak="keep-all"
                                    >
                                      {`${
                                        treatmentModeMap.get(treatmentMode).label
                                      }에 급여로 처방할 수 없습니다.`}
                                    </TypographyText>
                                    <div className="flex gap-x-1">
                                      {비급여Flag ? (
                                        <TypographyText
                                          fontSize="0.8rem"
                                          whiteSpace="pre-wrap"
                                          wordBreak="keep-all"
                                        >
                                          {`비급여에서 처방가능합니다.`}
                                        </TypographyText>
                                      ) : (
                                        <>
                                          <TypographyText
                                            fontWeight={700}
                                            fontSize="0.8rem"
                                            whiteSpace="pre-wrap"
                                            wordBreak="keep-all"
                                          >
                                            {'[처방 가능 질환]'}
                                          </TypographyText>
                                          <TypographyText
                                            fontSize="0.8rem"
                                            whiteSpace="pre-wrap"
                                            wordBreak="keep-all"
                                          >
                                            {`${기준처방분류코드_질환_Map
                                              .get(treatment?.prescriptionData?.insrCCode)
                                              ?.map((d) => treatmentModeMap.get(d).label)
                                              .join(', ')}`}
                                          </TypographyText>
                                        </>
                                      )}
                                    </div>
                                  </Row>,
                                ]}
                              />
                            )}
                          </Col>
                        )}
                      </Row>
                    </Row>
                  )}
                </Col>
                {prescribedFlag && (
                  <Table
                    processedHerbMode={processedHerbMode}
                    prescribedRecipe={
                      processedHerbMode
                        ? _.sortBy(treatment.prescribedRecipe, (e) => -e.amt)
                        : _.sortBy(treatment.prescribedHerb, (e) => -e.amount)
                    }
                    prescriptionCount={treatment.amount}
                  />
                )}
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default observer(CommonPrescriptionTable);
