/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import message from 'antd-message';
import _ from 'lodash';
import { v4 } from 'uuid';
import { useEffect, useCallback } from 'react';
import { API, Auth } from 'aws-amplify';

import { CreateSurveyInput, UpdatePatientInput } from 'API';
import { TreatmentStatus } from 'constant';
import { url } from 'config';
import { useStores } from 'stores/Context';
import { TypographyText, Row, Col, Modal, Spinner } from 'components/UI/atoms';
import { DIAGNOSIS } from 'utils/SymptomCode';
import SymptomTable from 'components/UI/molecules/table/commonSymptomTable';
import CommonPrescriptionTable from 'components/UI/molecules/table/CommonPrescriptionTable';
import { copyChart, selectSurvey } from 'utils/util';
import AnalysisHistory from 'components/UI/organisms/treatment/AnalysisHistory';
import tr from 'repository/TreatmentRepository';
import { treatmentModeMap } from 'constant';

const TreatmentHistoryDetail = ({
  treatmentData: t = {},
  setRefreshSpecificTreatmentDataTrigger,
}: {
  treatmentData: any;
  setRefreshSpecificTreatmentDataTrigger: any;
}) => {
  const latestTId = useRef(t?.id);
  const [isLoadingForReport, setIsLoadingForReport] = useState(false);
  const [isLoadingForSendReport, setIsLoadingForSendReport] = useState(false);
  const [reportData, setReportData] = useState();
  const [isLoadingForCopySymptom, setIsLoadingForCopySymptom] = useState(false);
  const [isOpenCopySymptomModal, setIsOpenCopySymptomModal] = useState(false);

  const { surveyStore, patientStore, historyStore, clinicStore } = useStores();

  const {
    name = '',
    birth = '',
    gender = '',
    age = '',
  } = useMemo(() => {
    if (!t) return {};

    const { name, birth, gender } = t ?? {};
    let convertedGender;
    switch (gender) {
      case 'MALE':
        convertedGender = '남';
        break;
      case 'FEMALE':
        convertedGender = '여';
        break;
      default:
        convertedGender = '성별미상';
        break;
    }
    const age = moment().diff(moment(birth), 'years');

    return {
      name,
      birth,
      gender: convertedGender,
      age,
    };
  }, [t]);

  const insuranceType = useMemo(
    () => t.prescriptionMode !== 'GENERAL' && treatmentModeMap.has(t.prescriptionMode),
    [t],
  );

  const checkedDiagnosisSymptomcodes = useMemo(() => t?.symptomCheck ?? [], [t]);

  // const sendFeedback = useCallback(async (surveyId, treatmentedAt) => {
  //   try {
  //     await API.post('yejinadminrestapi', '/sendFeedback', {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       response: true,
  //       body: {
  //         surveyId,
  //         mode: 'manual',
  //         treatmentedAt,
  //       },
  //     });
  //     message.success('피드백 발송을 완료했습니다.', 2500);
  //   } catch (e) {
  //     console.log(e);
  //     message.error('피드백 발송에 실패했습니다.', 2500);
  //   }
  // }, []);

  const DiagonosisContents = useMemo(() => {
    return DIAGNOSIS.reduce((a, c) => {
      const contentsArray = c.children.reduce((acc, cur) => {
        const flag = checkedDiagnosisSymptomcodes.includes(cur.sympomCode);
        if (flag) {
          return [...acc, cur.label];
        } else {
          return acc;
        }
      }, []);
      if (contentsArray.length > 0) {
        return [
          ...a,
          <Row style={{ display: 'flex' }}>
            <Col style={{ width: '6.5rem' }}>
              <TypographyText fontWeight={700} fontSize={'1rem'} lineHeight="1.2em">
                {c.label}
              </TypographyText>
            </Col>
            <Col style={{ flex: 1 }}>
              <TypographyText fontSize={'1rem'} lineHeight="1.2em">
                {contentsArray.join('/')}
              </TypographyText>
            </Col>
          </Row>,
        ];
      } else {
        return a;
      }
    }, []);
  }, [DIAGNOSIS, t]);

  const copySymptom = useCallback(async (survey) => {
    const token = v4().toString();
    const nowDtISOString = new Date().toISOString();
    await surveyStore
      .createSurvey({
        patientID: survey.patientID,
        clinicID: survey.clinicID,
        otp: token,
        surveyedAt: moment().toISOString(),
        symptoms: survey.symptoms,
        surveyResult: survey.surveyResult,
        mainSymptom: survey.mainSymptom,
        mainSymptomCategory: survey?.mainSymptomCategory,
        additionalSymptoms: survey.additionalSymptoms,
        feedbackDone: false,
        surveyDone: true,
        feedbackLink: `${url.feedbackLink}${token}`,
        surveyType: survey.surveyType ?? 'GENERAL',
        insuranceType: survey.insuranceType ?? 'GENERAL',
      } as CreateSurveyInput)
      .then((e: any) => {
        historyStore.createHistory({
          patientID: survey.patientID,
          clinicID: survey.clinicID,
          type: 'copySymptom',
          messageCode: `AM004`,
        });
        patientStore
          .updatePatient({
            id: survey.patientID,
            userID: null,
            surveyID: e.createSurvey.id,
            treatmentStatus: TreatmentStatus.SurveySubmit,
            treatmentStatusDateForSorting: `${TreatmentStatus.SurveySubmit}_${nowDtISOString}`,
            recentActivity: nowDtISOString,
            recentActivityCode: `AM004`,
            surveyedAt: moment().toISOString(),
          } as UpdatePatientInput)
          .then(() => {
            message.success(`설문이 입력되어 진료 목록에 등록되었습니다.`, 2500);
          });
      });
  }, []);

  const feedbackMessage = useMemo(() => {
    const s = t?.survey ?? {};
    if (s.feedbackDone) {
      return `${moment(t.survey.feedbackAt).format('YYYY/MM/DD HH:mm')} 피드백 응답 완료`;
    } else if (s.isFeedback && s.feedbackScheduleStatus === 1) {
      return `${moment(t.survey.feedbackSendTime).format('YYYY/MM/DD')} 피드백 발송 예정`;
    } else if (s.isFeedback && s.feedbackScheduleStatus === 3) {
      return `${moment(t.survey.feedbackSendTime).format('YYYY/MM/DD')} 피드백 발송 완료`;
    } else {
      return '피드백 발송 예정 없음';
    }
  }, [t]);

  const patientDeletedFlag = useMemo(() => (t?.patientData?.isDeleted ? true : false), [t]);
  const treatmentProgressingFlag = useMemo(
    () => t?.patientData?.treatmentStatus === TreatmentStatus.InProgress,
    [t],
  );

  const prescribedFlag = useMemo(() => !_.isEmpty(t?.prescribedHerb) || !_.isEmpty(t?.prescribedRecipe), [t]);

  const getReport = async () => {
    setIsLoadingForReport(true);
    const response = await tr.getReport(t.id);
    setReportData(JSON.parse(response?.getReport?.byeonjeung ?? '{}'));
    setIsLoadingForReport(false);
  };

  const sendReportToPatient = useCallback(async () => {
    try {
      await setIsLoadingForSendReport(true);
      await API.post('yejinadminrestapi', `/report`, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
        response: true,
        body: {
          treatmentId: t.id,
          notSaveFlag: true,
          patientName: t.patientData.patientName,
          patientPhone: t.patientData.phone,
          sendKaKaoTalkFlag: true,
          clinicName: clinicStore.clinic.clinicName,
          clinicBusinessType: clinicStore.clinic.businessType,
        },
      });
      message.success(`${t.patientData?.patientName}님에게 변증 결과 카카오톡이 발송되었습니다.`, 2500);
    } catch (e) {
      console.log(e);
      message.error(`변증 결과 카카오톡 발송이 실패되었습니다. 다시 발송해 주세요.`, 2500);
    } finally {
      setIsLoadingForSendReport(false);
    }
  }, [t]);

  useEffect(() => {
    latestTId.current = t?.id;
  }, [t?.id]);

  useEffect(() => {
    getReport();
    return () => {
      setIsLoadingForReport(false);
      setReportData(undefined);
    };
  }, [t]);

  const handleMessage = useCallback(
    (event) => {
      if (event.data === 'childClosed') {
        setRefreshSpecificTreatmentDataTrigger(latestTId.current);
      }
    },
    [setRefreshSpecificTreatmentDataTrigger],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  return (
    <>
      {!_.isEmpty(t) && (
        <>
          <Row
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              padding: '2rem 2rem 1.25rem 2rem',
              boxShadow: '0px 4px 4px 0px #E5E5E580',
              border: '1px solid #E5E5E5',
              borderRadius: '3px',
              background: '#ffffff',
              rowGap: '2rem',
            }}
          >
            {/* {isEnableTillStaging && (
              <button
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  fontSize: '0.8rem',
                  fontWeight: 700,
                  border: '1px solid #afafaf',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0.3rem 0.5rem',
                }}
                onClick={async () => sendFeedback(t.survey.id, t.treatmentedAt)}
              >
                {'피드백 즉시 발송(상용노출금지)'}
              </button>
            )} */}
            <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
              <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    rowGap: '0.2rem',
                    textAlign: 'right',
                  }}
                >
                  <TypographyText fontSize={'1.2rem'} lineHeight="1.2em" fontWeight={700} whiteSpace="pre">
                    {patientDeletedFlag
                      ? '삭제된 환자'
                      : `${name} ${moment(birth).format('YY-MM-DD')} (${gender}/ 만 ${age}세)`}
                  </TypographyText>
                  <TypographyText
                    fontSize={'1.2rem'}
                    lineHeight="1.2em"
                    whiteSpace="pre-wrap"
                    wordBreak="keep-all"
                  >
                    {`${moment(t.treatmentedAt).format('YYYY/MM/DD')} 진료내역`}
                  </TypographyText>
                </Col>
                {!patientDeletedFlag && (
                  <Col
                    style={{
                      display: 'flex',
                      rowGap: '0.3rem',
                      columnGap: '0.4rem',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <button
                      style={{
                        display: 'flex',
                        paddingInline: '0.75rem',
                        paddingBlock: '0.6rem',
                        background: '#ffffff',
                        border: '1px solid #346AFF',
                        borderRadius: '3px',
                      }}
                      disabled={isLoadingForReport}
                      onClick={() => {
                        try {
                          copyChart(t, reportData || {});
                          message.success('차트가 복사되었습니다.', 3000);
                        } catch (e) {
                          console.log(e);
                          message.error(`차트 복사에 문제가 발생했습니다. ${e}`, 3000);
                        }
                      }}
                    >
                      <TypographyText fontSize="0.9rem" color="#346AFF" fontWeight={700} whiteSpace="pre">
                        {'차트 복사'}
                      </TypographyText>
                    </button>
                    {prescribedFlag ? (
                      <>
                        <button
                          style={{
                            display: 'flex',
                            paddingInline: '0.75rem',
                            paddingBlock: '0.6rem',
                            background: '#346AFF',
                            border: '1px solid #346AFF',
                            borderRadius: '3px',
                          }}
                          onClick={() =>
                            // 새탭으로 열기
                            window.open(`/pr/${t.id}`, '_blank')
                          }
                        >
                          <TypographyText fontSize="0.9rem" color="#ffffff" fontWeight={700} whiteSpace="pre">
                            {'처방전'}
                          </TypographyText>
                        </button>
                        <button
                          style={{
                            display: 'flex',
                            paddingInline: '0.75rem',
                            paddingBlock: '0.6rem',
                            background: '#346AFF',
                            border: '1px solid #346AFF',
                            borderRadius: '3px',
                          }}
                          onClick={() =>
                            // 새탭으로 열기
                            window.open(`/pdr/${t.id}`, '_blank')
                          }
                        >
                          <TypographyText fontSize="0.9rem" color="#ffffff" fontWeight={700} whiteSpace="pre">
                            {'첩약처방/조제내역 안내'}
                          </TypographyText>
                        </button>
                      </>
                    ) : (
                      <button
                        style={{
                          display: 'flex',
                          paddingInline: '0.75rem',
                          paddingBlock: '0.6rem',
                          background: '#346AFF',
                          border: '1px solid #346AFF',
                          borderRadius: '3px',
                        }}
                        onClick={() =>
                          // 새탭으로 열기
                          window.open(`/treatment/${t?.id}?mode=prescriptionEdit`, '_blank')
                        }
                      >
                        <TypographyText fontSize="0.9rem" color="#ffffff" fontWeight={700} whiteSpace="pre">
                          {'처방하기'}
                        </TypographyText>
                      </button>
                    )}
                  </Col>
                )}
              </Row>

              <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.1rem' }}>
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.3rem',
                    flexShrink: 0,
                  }}
                >
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: prescribedFlag ? '1px solid #DF635C' : '1px solid #1d1c1d',
                      backgroundColor: '#ffffff',
                      width: '3.8rem',
                      height: '1.2rem',
                      borderRadius: '100px',
                    }}
                  >
                    {prescribedFlag ? (
                      <>
                        <TypographyText color="#DF635C" fontSize="0.8rem" lineHeight="1em" fontWeight={700}>
                          {'처방완료'}
                        </TypographyText>
                      </>
                    ) : (
                      <>
                        <TypographyText color="#1d1c1d" fontSize="0.8rem" lineHeight="1em" fontWeight={700}>
                          {'처방없음'}
                        </TypographyText>
                      </>
                    )}
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: insuranceType ? '1px solid #DF635C' : '1px solid #1d1c1d',
                      width: '3rem',
                      height: '1.2rem',
                      borderRadius: '100px',
                    }}
                  >
                    {insuranceType ? (
                      <>
                        <TypographyText color="#DF635C" fontSize="0.8rem" lineHeight="1em" fontWeight={700}>
                          {'급여'}
                        </TypographyText>
                      </>
                    ) : (
                      <>
                        <TypographyText color="#1d1c1d" fontSize="0.8rem" lineHeight="1em" fontWeight={700}>
                          {'비급여'}
                        </TypographyText>
                      </>
                    )}
                  </Col>
                  {insuranceType && (
                    <Col style={{ display: 'flex' }}>
                      <TypographyText fontSize="0.8rem" lineHeight="1em">
                        {`${treatmentModeMap.get(t.prescriptionMode).label}`}
                      </TypographyText>
                    </Col>
                  )}
                </Row>
              </Row>
              <Row style={{ marginTop: '-0rem', display: 'flex', flexDirection: 'column', rowGap: '0.1rem' }}>
                <TypographyText fontSize={'0.8rem'} lineHeight="1.2em" color={'#AFAFAF'} whiteSpace="pre">
                  {`${moment(t.treatmentedAt).format('YYYY/MM/DD HH:mm')} 진료완료`}
                </TypographyText>
                <TypographyText
                  fontSize={'0.8rem'}
                  lineHeight="1.2em"
                  color={'#AFAFAF'}
                  whiteSpace="pre"
                >{`${feedbackMessage}`}</TypographyText>
              </Row>
            </Row>
            <Row style={{ marginTop: '-1rem', display: 'flex', flexDirection: 'column', rowGap: '0.75rem' }}>
              <Row
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.75rem' }}>
                  <TypographyText fontWeight={700} fontSize={'1.2rem'} lineHeight="1.2em">
                    증상 및 피드백
                  </TypographyText>
                </Row>
                {!patientDeletedFlag && (
                  <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <button
                      style={{
                        padding: '0.45rem 0.75rem',
                        minWidth: '6rem',
                        height: '2rem',
                        background: '#ffffff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #afafaf',
                        borderRadius: '3px',
                        marginBottom: '-0.25rem',
                      }}
                      className={treatmentProgressingFlag ? 'disabledButton' : ''}
                      disabled={treatmentProgressingFlag}
                      onClick={() => {
                        setIsOpenCopySymptomModal(true);
                      }}
                    >
                      <TypographyText
                        color={treatmentProgressingFlag ? '#afafaf' : '#1d1c1d'}
                        fontWeight={700}
                        fontSize={'0.8rem'}
                        lineHeight="1.2em"
                        whiteSpace="pre"
                      >
                        {'해당 증상으로 진료 등록'}
                      </TypographyText>
                    </button>
                    {treatmentProgressingFlag && (
                      <Row style={{ marginTop: '0.15rem', marginBottom: '-0.6rem' }}>
                        <TypographyText
                          color={'#DF635C'}
                          fontWeight={700}
                          fontSize={'0.8rem'}
                          lineHeight="1em"
                          whiteSpace="pre"
                        >
                          {'진료 중에는 증상 복사가 불가합니다. 진료를 완료해 주세요.'}
                        </TypographyText>
                      </Row>
                    )}
                  </Row>
                )}
              </Row>
              <SymptomTable
                treatment={t}
                survey={t.survey}
                symptoms={selectSurvey(t.survey?.symptoms)}
                mode="feedbackHistory"
                feedbackScheduleFlag={t.survey?.isFeedback ?? false}
              />
            </Row>
            {!_.isEmpty(DiagonosisContents) && (
              <Row>
                <Row style={{ paddingBottom: '0.75rem', borderBottom: '3px solid #e5e5e5' }}>
                  <TypographyText fontWeight={700} fontSize={'1.2rem'} lineHeight="1.2em">
                    사진 四診
                  </TypographyText>
                </Row>
                <Row
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '1.2rem',
                    paddingBlock: '1rem',
                    borderBottom: '1px solid #f2f2f2',
                  }}
                >
                  {DiagonosisContents}
                </Row>
              </Row>
            )}
            {t?.memo && (
              <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.75rem' }}>
                <TypographyText fontWeight={700} fontSize={'1.2rem'} lineHeight="1.2em">
                  한의사 메모
                </TypographyText>
                <Row
                  style={{
                    width: '100%',
                    border: '1px solid #AFAFAF',
                    borderRadius: '3px',
                    padding: '0.8rem 1rem',
                    minHeight: '3rem',
                  }}
                >
                  <TypographyText fontSize="1rem" lineHeight="1.2em">
                    {t.memo}
                  </TypographyText>
                </Row>
              </Row>
            )}
            {!_.isEmpty(reportData) && (
              <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.75rem' }}>
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '0.75rem',
                    borderBottom: '3px solid #e5e5e5',
                  }}
                >
                  <TypographyText fontWeight={700} fontSize={'1.2rem'} lineHeight="1.2em">
                    변증 辨證
                  </TypographyText>
                  <button
                    style={{
                      padding: '0.45rem 0.75rem',
                      minWidth: '12.5rem',
                      height: '2rem',
                      background: '#ffffff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #afafaf',
                      borderRadius: '3px',
                      marginBottom: '-0.25rem',
                    }}
                    disabled={isLoadingForSendReport}
                    onClick={() => sendReportToPatient()}
                  >
                    {isLoadingForSendReport ? (
                      <Spinner spinnerDivWidth="w-20" spinnerWidth="w-4" spinnerHeight="w-4" />
                    ) : (
                      <TypographyText
                        fontWeight={700}
                        fontSize={'0.8rem'}
                        lineHeight="1.2em"
                        whiteSpace="pre"
                      >
                        {'환자에게 변증 결과 카카오톡 발송'}
                      </TypographyText>
                    )}
                  </button>
                </Row>
                <Row style={{ borderBottom: prescribedFlag ? '1px solid #f2f2f2' : 'none' }}>
                  <AnalysisHistory
                    paddingInlineNoneFlag
                    patientName={t.name}
                    byeonjeungData={reportData ?? undefined}
                    isLoading={isLoadingForReport}
                  />
                </Row>
              </Row>
            )}
            {prescribedFlag && (
              <CommonPrescriptionTable
                treatment={t}
                titleHiddenFlag={true}
                topRightButtonText={'처방 수정하기'}
                topRightButtonFunc={() => {
                  window.open(`/treatment/${t?.id}?mode=prescriptionEdit`, '_blank');
                }}
                topRightButtonTheme={'black'}
              />
            )}
          </Row>
          <Modal
            isOpen={isOpenCopySymptomModal}
            okBtnLoading={isLoadingForCopySymptom}
            contentMsg={`해당 증상으로 진료 목록에 등록하시겠습니까?`}
            cancelMsg="취소"
            okMsg="네, 등록하겠습니다."
            okFunction={async () => {
              try {
                await setIsLoadingForCopySymptom(true);
                await copySymptom(t.survey);
                setIsOpenCopySymptomModal(false);
              } catch (e) {
                console.log(e);
                message.error(`진료 등록과정에 문제가 발생했습니다. ${e}`, 8000);
              } finally {
                setIsLoadingForCopySymptom(false);
              }
            }}
            cancelFunction={() => {
              setIsOpenCopySymptomModal(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default observer(TreatmentHistoryDetail);
