/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { css } from '@emotion/css';
import _, { set } from 'lodash';
import moment from 'moment';
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

import { Row, Button, TypographyText } from 'components/UI/atoms';
import { useStores } from 'stores/Context';
import { isEnableTillStaging } from 'config';
import tr from 'repository/TreatmentRepository';
import pr from 'repository/PatientRepository';
import cr from 'repository/ClinicRepository';
import ur from 'repository/UserRepository';
import { Loading } from 'components/UI/molecules';
import { pc2prescriptionHerbMap, comparePrescription } from 'utils/util';

const PrescriptionRecord = () => {
  const { treatmentId } = useParams();
  const componentRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState('view');
  const [medicalInstitutionNumber, setMedicalInstitutionNumber] = useState('');
  const [prescriptionNumber, setPrescriptionNumber] = useState();
  const [patientName, setPatientName] = useState('');
  const [patientResidentRegistrationNumber, setPatientResidentRegistrationNumber] = useState('');
  const [diseaseClassificationCode, setDiseaseClassificationCode] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [doctorLicenseNumber, setDoctorLicenseNumber] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [clinicPhone, setClinicPhone] = useState('');
  const [clinicFax, setClinicFax] = useState('');
  const [prescriptionName, setPrescriptionName] = useState('');
  const [prescriptionCode, setPrescriptionCode] = useState('');
  const [singleDose, setSingleDose] = useState(1);
  const [dailyDose, setDailyDose] = useState(1);
  const [dailyDosageFrequency, setDailyDosageFrequency] = useState(2);
  const [totalDosageDays, setTotalDosageDays] = useState(10);
  const [dosagePerPack, setDosagePerPack] = useState(120);
  const [administrationMethod, setAdministrationMethod] = useState('후');
  const [administrationTimingHour, setAdministrationTimingHour] = useState(0);
  const [administrationTimingMinute, setAdministrationTimingMinute] = useState(30);
  const [copaymentRateCode, setCopaymentRateCode] = useState('');
  const [selfPaymentClassificationCode, setSelfPaymentClassificationCode] = useState('');
  const [prescriptionValidityPeriod, setPrescriptionValidityPeriod] = useState(7);

  const [c, setClinicData] = useState({});
  const [p, setPatientData] = useState({});
  const [t, setTreatmentData] = useState({});
  const [tpd, setTreatmentPrescriptionDocument] = useState({});
  const [u, setUserData] = useState({});

  const { prescriptionStore: ps } = useStores();

  const insuranceFlag = useMemo(() => t?.prescriptionMode !== 'GENERAL', [t]);

  const commonInputStyle = useMemo(
    () => ({
      fontSize: '15px',
      border: '1px solid #1d1c1d',
      outline: 'none',
      textIndent: '5px',
      width: '100%',
    }),
    [],
  );

  const herbTds = useCallback(
    ({ herbName, herbCode, singleDose, dailyDose, totalDays, adjustment, origin }) => (
      <>
        <td style={{ border: '1px solid black' }} colSpan={2} height={22} align="center">
          <span>{`${herbName}${herbCode ? ` (${herbCode})` : ''}`}</span>
        </td>
        <td style={{ border: '1px solid black' }} colSpan={3} align="center">
          <span>{singleDose}</span>
        </td>
        <td style={{ border: '1px solid black' }} colSpan={3} align="center">
          <span>{dailyDose}</span>
        </td>
        <td style={{ border: '1px solid black' }} colSpan={4} align="center">
          <span>{totalDays}</span>
        </td>
        <td style={{ border: '1px solid black' }} colSpan={3} align="center">
          <span>{adjustment}</span>
        </td>
        <td style={{ border: '1px solid black' }} colSpan={4} align="center">
          <span>{origin}</span>
        </td>
      </>
    ),
    [],
  );

  const prescribedRecipe = useMemo(() => {
    if (!_.isEmpty(t)) {
      const result = [];
      if (!insuranceFlag) {
        _.sortBy(t?.prescribedRecipe ?? [], (e) => -e.amt).forEach((r, i) => {
          const 예외허브 = pc2prescriptionHerbMap.get(r.pc);
          const hd = ps.yejinHerbForSearchByProcessingCode.get(r.pc);
          result.push(
            <tr key={`p-h-${i}`}>
              {herbTds({
                herbName: _.isEmpty(예외허브) ? hd.koreanName : 예외허브.name,
                // herbCode: hd.processingCode,
                herbCode: '',
                singleDose: _.round(r.amt / 1000, 2).toFixed(2),
                dailyDose: _.round(t.amount / totalDosageDays, 2),
                totalDays: insuranceFlag ? 1 : '',
                adjustment: '',
                origin: _.isEmpty(예외허브) ? '' : 예외허브.origin,
              })}
              {i === 0 && (
                <td
                  style={{
                    border: '1px solid black',
                  }}
                  rowSpan={t?.prescribedRecipe?.length ?? 1}
                  align="center"
                >
                  {mode === 'view' ? (
                    <span>{selfPaymentClassificationCode}</span>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        style={{
                          ...commonInputStyle,
                        }}
                        value={selfPaymentClassificationCode}
                        onChange={(e) => setSelfPaymentClassificationCode(e.target.value)}
                      />
                    </div>
                  )}
                </td>
              )}
            </tr>,
          );
        });
      } else {
        const list = comparePrescription({
          basePrescription: t?.prescriptionData?.recipe ?? [],
          targetPrescription: t?.prescribedRecipe ?? [],
        });
        list.forEach((r, i) => {
          const 예외허브 = pc2prescriptionHerbMap.get(r.pc);
          const hd = ps.yejinHerbForSearchByProcessingCode.get(r.pc);
          result.push(
            <tr>
              {herbTds({
                herbName: _.isEmpty(예외허브) ? hd.koreanName : 예외허브.name,
                // herbCode: hd.processingCode,
                herbCode: '',
                singleDose: _.round(r.amt / 1000, 2).toFixed(2),
                dailyDose: insuranceFlag ? 2 : '',
                totalDays: insuranceFlag ? 1 : '',
                adjustment: r.adjustment ?? '',
                origin: _.isEmpty(예외허브) ? '' : 예외허브.origin,
              })}
              {i === 0 && (
                <td
                  style={{
                    border: '1px solid black',
                  }}
                  rowSpan={list?.length ?? 1}
                  align="center"
                >
                  {mode === 'view' || insuranceFlag ? (
                    <span>{selfPaymentClassificationCode}</span>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        style={{
                          ...commonInputStyle,
                        }}
                        value={selfPaymentClassificationCode}
                        onChange={(e) => setSelfPaymentClassificationCode(e.target.value)}
                      />
                    </div>
                  )}
                </td>
              )}
            </tr>,
          );
        });
      }

      return result;
    } else {
      return [];
    }
  }, [t, insuranceFlag, selfPaymentClassificationCode, mode, totalDosageDays]);

  const parseData = useCallback(
    ({ cd, pd, td, ud, tpd }) => {
      const {
        medicalInstitutionNumber,
        prescriptionNumber,
        patientResidentRegistrationNumber,
        diseaseClassificationCode,
        doctorName,
        doctorLicenseNumber,
        clinicName,
        clinicPhone,
        clinicFax,
        singleDose,
        dailyDose,
        dailyDosageFrequency,
        totalDosageDays,
        dosagePerPack,
        administrationMethod,
        administrationTimingHour,
        administrationTimingMinute,
        copaymentRateCode,
        selfPaymentClassificationCode,
        prescriptionValidityPeriod,
        prescriptionName,
        prescriptionCode,
      } = tpd ?? {};

      setMedicalInstitutionNumber(medicalInstitutionNumber ?? cd.medicalInstitutionNumber ?? '');
      setPrescriptionNumber(prescriptionNumber ?? '');
      setPatientName(pd.patientName ?? '');
      setPatientResidentRegistrationNumber(
        patientResidentRegistrationNumber ??
          pd.residentRegistrationNumber ??
          `${pd.birth?.replace(/-/g, '')?.slice(2)}-${
            pd.gender === 'MALE' ? (pd.birth?.startsWith('1') ? 1 : 3) : pd.birth?.startsWith('1') ? 2 : 4
          }******` ??
          '',
      );
      setDiseaseClassificationCode(diseaseClassificationCode ?? '');
      setDoctorName(doctorName ?? ud.username ?? '');
      setDoctorLicenseNumber(doctorLicenseNumber ?? ud.licenseNumber ?? '');
      setClinicName(clinicName ?? cd.clinicName ?? '');
      setClinicPhone(clinicPhone ?? cd.phone ?? '');
      setClinicFax(clinicFax ?? cd.fax ?? '');
      setPrescriptionName(prescriptionName ?? td.prescriptionData?.kn ?? '');
      setPrescriptionCode(insuranceFlag ? prescriptionCode ?? td.prescriptionData?.id : '');
      setSingleDose(singleDose ?? 1);
      setDailyDose(dailyDose ?? 1);
      setDailyDosageFrequency(dailyDosageFrequency ?? 2);
      setTotalDosageDays(totalDosageDays ?? _.ceil(td?.amount / 2));
      setDosagePerPack(dosagePerPack ?? tpd?.dosagePerPack ?? 120);
      setAdministrationMethod(administrationMethod ?? '후');
      setAdministrationTimingHour(administrationTimingHour ?? 0);
      setAdministrationTimingMinute(administrationTimingMinute ?? 30);
      setCopaymentRateCode(copaymentRateCode ?? '');
      setSelfPaymentClassificationCode(selfPaymentClassificationCode ?? insuranceFlag ? 'S027' : '');
      setPrescriptionValidityPeriod(prescriptionValidityPeriod ?? cd?.prescriptionValidityPeriod ?? 7);
    },
    [insuranceFlag],
  );

  const getData = useCallback(async () => {
    try {
      await setIsLoading(true);
      const tRes = (await tr.getTreatment(treatmentId))?.getTreatment ?? {};
      const [cRes, pRes, uRes] = await Promise.all([
        cr.getClinic(tRes?.clinicID),
        pr.getPatient(tRes?.patientID),
        ur.getUser(tRes?.userID),
      ]);
      setTreatmentData(tRes);
      const prescriptionDocument = JSON.parse(tRes.prescriptionDocument ?? '{}');
      setTreatmentPrescriptionDocument(prescriptionDocument);
      setClinicData(cRes?.getClinic ?? {});
      setPatientData(pRes?.getPatient ?? {});
      setUserData(uRes?.getUser ?? {});
      if (isEnableTillStaging) {
        console.log('진료 데이터', tRes);
        console.log('한의원 데이터', cRes);
        console.log('환자 데이터', pRes);
        console.log('의사 데이터', uRes);
        console.log('처방전 데이터', prescriptionDocument);
      }
      parseData({
        cd: cRes?.getClinic ?? {},
        pd: pRes?.getPatient ?? {},
        td: tRes ?? {},
        ud: uRes?.getUser ?? {},
        tpd: prescriptionDocument,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSave = useCallback(
    async ({
      originalPrescriptionDocumentData,
      medicalInstitutionNumber,
      prescriptionNumber,
      patientResidentRegistrationNumber,
      diseaseClassificationCode,
      doctorName,
      doctorLicenseNumber,
      clinicName,
      clinicPhone,
      clinicFax,
      singleDose,
      dailyDose,
      dailyDosageFrequency,
      totalDosageDays,
      dosagePerPack,
      administrationMethod,
      administrationTimingHour,
      administrationTimingMinute,
      copaymentRateCode,
      selfPaymentClassificationCode,
      prescriptionValidityPeriod,
      prescriptionName,
      prescriptionCode,
    }) => {
      try {
        const param = {
          id: treatmentId,
          prescriptionDocument: JSON.stringify({
            ...originalPrescriptionDocumentData,
            medicalInstitutionNumber,
            prescriptionNumber,
            patientResidentRegistrationNumber,
            diseaseClassificationCode,
            doctorName,
            doctorLicenseNumber,
            clinicName,
            clinicPhone,
            clinicFax,
            singleDose,
            dailyDose,
            dailyDosageFrequency,
            totalDosageDays,
            dosagePerPack,
            administrationMethod,
            administrationTimingHour,
            administrationTimingMinute,
            copaymentRateCode,
            selfPaymentClassificationCode,
            prescriptionValidityPeriod,
            prescriptionCode,
            prescriptionName,
          }),
        };
        await tr.updateTreatment(param);
        await getData();
        setMode('view');
      } catch (error) {
        console.error(error);
      }
    },
    [],
  );

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading mode="global" />
      ) : (
        <div
          style={{
            width: '100%',
            paddingBlock: '1.1rem',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.75rem',
            alignItems: 'center',
          }}
        >
          <div
            ref={(r) => {
              if (r !== null) {
                componentRef.current = r;
              }
            }}
            id="pr-print-area"
          >
            <table
              cellSpacing={0}
              border={0}
              className={css`
                td {
                  vertical-align: middle;
                }
                span {
                  font-size: 15px;
                }
              `}
            >
              <colgroup width={63} />
              <colgroup width={79} />
              <colgroup width={13} />
              <colgroup width={31} />
              <colgroup width={40} />
              <colgroup width={50} />
              <colgroup width={10} />
              <colgroup width={57} />
              <colgroup width={19} />
              <colgroup width={10} />
              <colgroup width={67} />
              <colgroup span={2} width={12} />
              <colgroup width={47} />
              <colgroup width={35} />
              <colgroup width={25} />
              <colgroup width={35} />
              <colgroup width={3} />
              <colgroup width={70} />
              <colgroup width={80} />
              <tbody>
                <tr className="no-print">
                  <td colSpan={100}>
                    <Row
                      style={{
                        display: 'flex',
                        columnGap: '0.4rem',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        paddingBottom: '0.75rem',
                      }}
                    >
                      <Row style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap' }}>
                        <TypographyText
                          fontSize="15px"
                          color="#afafaf"
                          wordBreak="keep-all"
                          lineHeight="1.2em"
                          letterSpacing="-0.03em"
                        >
                          {'요양기관기호, 팩스번호, 사용기간 항목은 [설정]에서 기본값을 설정할 수 있습니다.'}
                        </TypographyText>
                      </Row>
                      <Row style={{ display: 'flex', columnGap: '5px' }}>
                        {mode === 'view' && (
                          <>
                            <Button
                              yjTypeButton="footer-white-button"
                              style={{
                                width: '5.2rem',
                                height: '2.5rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              onClick={() => window.close()}
                            >
                              <TypographyText style={{ whiteSpace: 'pre', fontSize: '1rem' }}>
                                닫기
                              </TypographyText>
                            </Button>
                            <Button
                              yjTypeButton="footer-white-button"
                              style={{
                                width: '5.2rem',
                                height: '2.5rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              onClick={() => setMode('edit')}
                            >
                              <TypographyText style={{ whiteSpace: 'pre', fontSize: '1rem' }}>
                                수정
                              </TypographyText>
                            </Button>
                            <ReactToPrint
                              trigger={() => (
                                <Button
                                  style={{
                                    width: '5.2rem',
                                    height: '2.5rem',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    whiteSpace: 'pre',
                                    background: '#346AFF',
                                  }}
                                >
                                  <TypographyText
                                    color="#ffffff"
                                    style={{ whiteSpace: 'pre', fontSize: '1rem' }}
                                  >
                                    인쇄
                                  </TypographyText>
                                </Button>
                              )}
                              content={() => componentRef?.current}
                            />
                          </>
                        )}
                        {mode === 'edit' && (
                          <>
                            <Button
                              yjTypeButton="footer-white-button"
                              style={{
                                width: '5.2rem',
                                height: '2.5rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              onClick={() => setMode('view')}
                            >
                              <TypographyText style={{ whiteSpace: 'pre', fontSize: '1rem' }}>
                                취소
                              </TypographyText>
                            </Button>
                            <Button
                              style={{
                                width: '5.2rem',
                                height: '2.5rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                whiteSpace: 'pre',
                                background: '#346AFF',
                              }}
                              onClick={() =>
                                handleSave({
                                  originalPrescriptionDocumentData: tpd,
                                  medicalInstitutionNumber,
                                  prescriptionNumber,
                                  patientResidentRegistrationNumber,
                                  diseaseClassificationCode,
                                  doctorName,
                                  doctorLicenseNumber,
                                  clinicName,
                                  clinicPhone,
                                  clinicFax,
                                  singleDose,
                                  dailyDose,
                                  dailyDosageFrequency,
                                  totalDosageDays,
                                  dosagePerPack,
                                  administrationMethod,
                                  administrationTimingHour,
                                  administrationTimingMinute,
                                  copaymentRateCode,
                                  selfPaymentClassificationCode,
                                  prescriptionValidityPeriod,
                                  prescriptionCode,
                                  prescriptionName,
                                })
                              }
                            >
                              <TypographyText color="#ffffff" style={{ whiteSpace: 'pre', fontSize: '1rem' }}>
                                저장
                              </TypographyText>
                            </Button>
                          </>
                        )}
                      </Row>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black' }} colSpan={20} height={22} align="center">
                    <b>
                      <font face="Source Han Sans CN" size={5} color="black">
                        처방전 [공동이용탕전실]
                      </font>
                    </b>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ border: '1px solid black', textIndent: '5px' }}
                    colSpan={20}
                    height={22}
                    align="left"
                  >
                    {mode === 'view' ? (
                      <span>{`요양기관기호: ${medicalInstitutionNumber}`}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
                        <span>{`요양기관기호: `}</span>
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '230px',
                          }}
                          value={medicalInstitutionNumber}
                          onChange={(e) => setMedicalInstitutionNumber(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ border: '1px solid black', textIndent: '5px' }}
                    colSpan={3}
                    height={22}
                    align="left"
                  >
                    <span>발급 연월일 및 번호</span>
                  </td>
                  <td style={{ border: '1px solid black', textIndent: '5px' }} colSpan={6} align="left">
                    {mode === 'view' ? (
                      <Row style={{ display: 'flex' }}>
                        <span>{`${moment().format('YYYY년 MM월 DD일')} - 제 ${prescriptionNumber}호`}</span>
                      </Row>
                    ) : (
                      <Row style={{ display: 'flex', columnGap: '1px', alignItems: 'center', textIndent: 0 }}>
                        <span>{`${moment().format('YYYY년 MM월 DD일')} - 제`}</span>
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '35px',
                            textIndent: '2px',
                          }}
                          value={prescriptionNumber}
                          onChange={(e) => setPrescriptionNumber(e.target.value)}
                        />
                        <span>호</span>
                      </Row>
                    )}
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={4} rowSpan={3} align="center">
                    <span>의료기관</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={2} align="center">
                    <span>명 칭</span>
                  </td>
                  <td style={{ border: '1px solid black', textIndent: '5px' }} colSpan={5} align="left">
                    {mode === 'view' ? (
                      <span>{clinicName}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={clinicName}
                          onChange={(e) => setClinicName(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black' }} rowSpan={2} height={44} align="center">
                    <span>환자</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={2} align="center">
                    <span>성 명</span>
                  </td>
                  <td style={{ border: '1px solid black', textIndent: '5px' }} colSpan={6} align="left">
                    <span>{patientName}</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={2} align="center">
                    <span>전화번호</span>
                  </td>
                  <td style={{ border: '1px solid black', textIndent: '5px' }} colSpan={5} align="left">
                    {mode === 'view' ? (
                      <span>
                        {clinicPhone
                          ? isValidPhoneNumber('+82' + clinicPhone)
                            ? formatPhoneNumber('+82' + clinicPhone)
                            : clinicPhone
                          : ''}
                      </span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={clinicPhone}
                          onChange={(e) => setClinicPhone(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black' }} colSpan={2} align="center">
                    <span>주민등록번호</span>
                  </td>
                  <td style={{ border: '1px solid black', textIndent: '5px' }} colSpan={6} align="left">
                    {mode === 'view' ? (
                      <span>{patientResidentRegistrationNumber}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={patientResidentRegistrationNumber}
                          onChange={(e) => setPatientResidentRegistrationNumber(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={2} align="center">
                    <span>팩스번호</span>
                  </td>
                  <td style={{ border: '1px solid black', textIndent: '5px' }} colSpan={5} align="left">
                    {mode === 'view' ? (
                      <span>{clinicFax}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={clinicFax}
                          onChange={(e) => setClinicFax(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ border: '1px solid black' }}
                    rowSpan={2}
                    colSpan={2}
                    height={44}
                    align="center"
                  >
                    <span>질병분류기호</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={3} rowSpan={2} align="center">
                    {mode === 'view' ? (
                      <span>{diseaseClassificationCode}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={diseaseClassificationCode}
                          onChange={(e) => setDiseaseClassificationCode(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={4} rowSpan={2} align="center">
                    <span>처방의료인의 성명</span>
                  </td>
                  <td
                    style={{ border: '1px solid black', paddingRight: '5px' }}
                    colSpan={6}
                    rowSpan={2}
                    align="right"
                  >
                    {mode === 'view' ? (
                      <span>{`${doctorName} ( 서명 또는 날인)`}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={doctorName}
                          onChange={(e) => setDoctorName(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={4} align="center">
                    <span>면허종류</span>
                  </td>
                  <td
                    style={{
                      borderBottom: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    align="center"
                  >
                    <span>{'한의사'}</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black' }} colSpan={4} align="center">
                    <span>면허번호</span>
                  </td>
                  <td
                    style={{
                      borderBottom: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    align="center"
                  >
                    {mode === 'view' ? (
                      <span style={{ letterSpacing: '-0.05em' }}>
                        {doctorLicenseNumber ? `제 ${doctorLicenseNumber}호` : ''}
                      </span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={doctorLicenseNumber}
                          onChange={(e) => setDoctorLicenseNumber(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black' }} colSpan={2} height={22} align="center">
                    <span>기준처방명 및 코드</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={3} align="center">
                    <span>1회 투약량</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={3} align="center">
                    <span>1일 투여횟수</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={4} align="center">
                    <span>총 투약일수</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={6} align="center">
                    <span>조제시 참고사항</span>
                  </td>
                  <td
                    style={{
                      borderBottom: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    align="center"
                  >
                    <span>용 법</span>
                  </td>
                  <td
                    style={{
                      borderBottom: '1px solid black',
                      borderRight: '1px solid black',
                      wordBreak: 'keep-all',
                    }}
                    align="center"
                  >
                    <span>{`본인부담률 코드`}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ border: '1px solid black' }}
                    colSpan={2}
                    rowSpan={3}
                    height={72}
                    align="center"
                  >
                    {!(mode === 'edit' && insuranceFlag) ? (
                      <span>{`${prescriptionName ?? ''} ${
                        prescriptionCode ? `(${prescriptionCode})` : ''
                      }`}</span>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          placeholder="기준처방명"
                          value={prescriptionName}
                          onChange={(e) => setPrescriptionName(e.target.value)}
                        />
                        <span>{`${prescriptionCode ? `(${prescriptionCode})` : ''}`}</span>
                      </div>
                    )}
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={3} rowSpan={3} align="center">
                    {mode === 'view' || insuranceFlag ? (
                      <span>{`${singleDose}`}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '30px',
                          }}
                          value={singleDose}
                          type="number"
                          min={1}
                          step={1}
                          onChange={(e) => setSingleDose(parseInt(e.target.value))}
                        />
                      </div>
                    )}
                  </td>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={3}
                    align="center"
                  ></td>
                  <td style={{ border: '1px solid black' }} colSpan={4} rowSpan={3} align="center">
                    {mode === 'view' || insuranceFlag ? (
                      <span>{`${totalDosageDays}`}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '50px',
                          }}
                          value={totalDosageDays}
                          type="number"
                          min={1}
                          step={1}
                          onChange={(e) => setTotalDosageDays(parseInt(e.target.value))}
                        />
                      </div>
                    )}
                  </td>
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                      textIndent: '5px',
                    }}
                    colSpan={6}
                    align="left"
                  >
                    {mode === 'view' ? (
                      <span>{`가.1일 복용 팩수 (${dailyDosageFrequency})`}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{`가.1일 복용 팩수: `}</span>
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '30px',
                          }}
                          value={dailyDosageFrequency}
                          type="number"
                          min={1}
                          step={1}
                          onChange={(e) => setDailyDosageFrequency(parseInt(e.target.value))}
                        />
                      </div>
                    )}
                  </td>
                  <td style={{ borderRight: '1px solid black' }} align="center">
                    {mode === 'view' ? (
                      <span>{`매 식${administrationMethod}`}</span>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textIndent: 0,
                          justifyContent: 'center',
                        }}
                      >
                        <span>{`식`}</span>
                        <select
                          style={{
                            ...commonInputStyle,
                            width: '40px',
                          }}
                          value={administrationMethod}
                          onChange={(e) => setAdministrationMethod(e.target.value)}
                        >
                          <option value={'전'}>전</option>
                          <option value={'간'}>간</option>
                          <option value={'후'}>후</option>
                        </select>
                      </div>
                    )}
                  </td>
                  <td style={{ border: '1px solid black' }} rowSpan={3} align="center">
                    {mode === 'view' ? (
                      <span>{copaymentRateCode}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                          }}
                          value={copaymentRateCode}
                          onChange={(e) => setCopaymentRateCode(e.target.value)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={3}
                    align="center"
                  >
                    {mode === 'view' || insuranceFlag ? (
                      <span>{dailyDose}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '30px',
                          }}
                          value={dailyDose}
                          type="number"
                          min={1}
                          step={1}
                          onChange={(e) => setDailyDose(parseInt(e.target.value))}
                        />
                      </div>
                    )}
                  </td>
                  <td
                    style={{
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                      textIndent: '5px',
                    }}
                    colSpan={6}
                    align="left"
                  >
                    {mode === 'view' ? (
                      <span>{`나.팩당 용량 (${dosagePerPack}cc)`}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', textIndent: 0 }}>
                        <span>{`나.팩당 용량: `}</span>
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '50px',
                          }}
                          value={dosagePerPack}
                          type="number"
                          min={1}
                          step={1}
                          onChange={(e) => setDosagePerPack(parseInt(e.target.value))}
                        />
                        <span>{`cc`}</span>
                      </div>
                    )}
                  </td>
                  <td style={{ borderRight: '1px solid black' }} align="center">
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                      {mode === 'view' ? (
                        <>
                          {administrationTimingHour > 0 && <span>{`${administrationTimingHour}시`}</span>}
                          {administrationTimingMinute > 0 && <span>{`${administrationTimingMinute}분`}</span>}
                        </>
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center', textIndent: 0 }}>
                          <input
                            style={{
                              ...commonInputStyle,
                              width: '40px',
                            }}
                            value={administrationTimingHour}
                            type="number"
                            min={0}
                            step={1}
                            onChange={(e) => setAdministrationTimingHour(parseInt(e.target.value))}
                          />
                          <span>{`시`}</span>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    colSpan={3}
                    align="left"
                  ></td>
                  <td
                    style={{
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                      textIndent: '5px',
                    }}
                    colSpan={6}
                    align="left"
                  >
                    <span style={{ letterSpacing: '-0.05em' }}>{`다.최종 산출량 (${
                      totalDosageDays * dosagePerPack * dailyDosageFrequency
                    }cc)`}</span>
                  </td>
                  <td
                    style={{
                      borderBottom: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    align="center"
                  >
                    {mode === 'view' ? (
                      <span>복용</span>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textIndent: 0,
                          justifyContent: 'center',
                        }}
                      >
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '40px',
                          }}
                          value={administrationTimingMinute}
                          type="number"
                          min={1}
                          max={59}
                          step={1}
                          onChange={(e) => setAdministrationTimingMinute(parseInt(e.target.value))}
                        />
                        <span>{`분`}</span>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black' }} colSpan={19} height={22} align="center">
                    <span>한약재 세부사항</span>
                  </td>
                  <td style={{ border: '1px solid black', wordBreak: 'keep-all' }} rowSpan={2} align="center">
                    <span>본인부담 구분기호</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black' }} colSpan={2} height={22} align="center">
                    <span>한약재 명칭 및 코드</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={3} align="center">
                    <span>1회 투약량</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={3} align="center">
                    <span>1일 투여횟수</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={4} align="center">
                    <span>총 투약일수</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={3} align="center">
                    <span>가감</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={4} align="center">
                    <span>원산지</span>
                  </td>
                </tr>
                {prescribedRecipe}
                <tr>
                  <td
                    style={{
                      borderBottom: '1px solid black',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    height={22}
                    align="center"
                  >
                    <span>사용기간</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={3} align="center">
                    {mode === 'view' ? (
                      <span
                        style={{ letterSpacing: '-0.03em' }}
                      >{`발급일부터 (${prescriptionValidityPeriod})일간`}</span>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', textIndent: 0 }}>
                        <span style={{ letterSpacing: '-0.05em' }}>{`발급일부터`}</span>
                        <input
                          style={{
                            ...commonInputStyle,
                            width: '45px',
                          }}
                          type="number"
                          min={1}
                          step={1}
                          value={prescriptionValidityPeriod}
                          onChange={(e) => setPrescriptionValidityPeriod(e.target.value)}
                        />
                        <span style={{ letterSpacing: '-0.03em' }}>{`일`}</span>
                      </div>
                    )}
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={3} align="center">
                    <span>공동이용탕전</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={3} align="center">
                    <span>기관명</span>
                  </td>
                  <td
                    style={{
                      borderBottom: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    align="left"
                  >
                    <span>
                      <br />
                    </span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={3} align="center">
                    <span>기관기호</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={2} align="left">
                    <span>
                      <br />
                    </span>
                  </td>
                  <td style={{ border: '1px solid black', whiteSpace: 'pre' }} colSpan={2} align="center">
                    <span>전화번호</span>
                  </td>
                  <td
                    style={{
                      borderBottom: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                    align="left"
                    colSpan={2}
                  >
                    <span>
                      <br />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black' }} colSpan={20} height={22} align="center">
                    <span>첩약 조제명세</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black' }} rowSpan={4} height={88} align="center">
                    <span>조제명세</span>
                  </td>
                  <td style={{ border: '1px solid black', textIndent: '5px' }} colSpan={3} align="left">
                    <span>조제기관의 명칭</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={7} align="left">
                    <span>
                      <br />
                    </span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={5} align="center">
                    <span>전화번호</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={4} align="left">
                    <span>
                      <br />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textIndent: '5px' }} colSpan={3} align="left">
                    <span>조제(한)약사</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={7} align="left">
                    <span>
                      <br />
                    </span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={9} rowSpan={3} align="center"></td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textIndent: '5px' }} colSpan={3} align="left">
                    <span>조제량(조제일수)</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={7} align="left">
                    <span>
                      <br />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textIndent: '5px' }} colSpan={3} align="left">
                    <span>조제연월일</span>
                  </td>
                  <td style={{ border: '1px solid black' }} colSpan={7} align="left">
                    <span>
                      <br />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(PrescriptionRecord);
